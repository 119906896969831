<template>
    <div>
        <button @click="triggerTryDemo">Live Demo Test</button>
    </div>
</template>

<script>
import {firstname,lastname,company,contactnumber} from './dataset'
    export default {
        data(){
            return{
                FIRSTNAME:firstname,
                LASTNAME:lastname,
                COMPANY:company,
                CONTACTNUMBER:contactnumber,
            }
        },
        methods:{
            triggerTryDemo(){
                this.createLocation()
            },
            generateRandomString(length) {
                var result = '';
                var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

                for (var i = 0; i < length; i++) {
                    var randomIndex = Math.floor(Math.random() * characters.length);
                    result += characters.charAt(randomIndex);
                }

                return result;
            },
            getRandomValue(type) {
                switch (type) {
                    case 'firstname':
                        return this.getRandomElement(this.FIRSTNAME);
                    case 'lastname':
                        return this.getRandomElement(this.LASTNAME);
                    case 'company':
                        return this.getRandomElement(this.COMPANY);
                    case 'contact':
                        return this.getRandomElement(this.CONTACTNUMBER);
                    default:
                    return 'Invalid type';
                }
            },
            getRandomElement(array) {
                console.log("yeys", array.length)
                const randomIndex = Math.floor(Math.random() * array.length);
                return array[randomIndex];
            },
            createLocation(){
                //creating location
               
                var random_key = this.generateRandomString(5)
                var firstname = random_key+'_'+this.getRandomValue('firstname')
                var form_datas = new FormData();

                var e_key_local = 'enterprise_enricke_test64932214f3e33644931146'
                var form = {
                    first_name:firstname,
                    last_name:random_key+'_'+this.getRandomValue('lastname'),
                    email:firstname+'@client1.schedeasy.local',
                    address_id:'',
                    location_name:random_key+'_'+this.getRandomValue('company'),
                    description:'This is a demo ',
                    contact_number:this.getRandomValue('contact'),
                    support_email:firstname+'@client1.schedeasy.local',
                    timezone:'',
                    street:'1110 Odlum Dr,',
                    appt_suite:'146',
                    city:'Vancouver',
                    province:'BC',
                    postal_code:'V5L 3L7',
                    country:'Canada',
                    industry:'',
                    user_type:'location_owner',
                    enterprise_key:e_key_local,
                    location_id:'',
                    created_by:'',
                    creator_name:'',
                    subdomain:'client1',
                }


                console.log("super random", form)
                form_datas.append("package_entry", "injectCreate");
                form_datas.append("package_api_key", "8F479DF8805C43C7723215B0400679F8F7C7417A");
                form_datas.append("package_data",JSON.stringify(form));
                

                var localApi = 'http://be-api.schedeasy.local/'

                axios.post(localApi+'api/locationInject',form_datas,
                ).then(({data:{success, content}}) => {
                    window.location.href =content.link;
                })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>