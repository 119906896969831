import { render, staticRenderFns } from "./featLeadManagement.vue?vue&type=template&id=2fd14fef&scoped=true&"
import script from "./featLeadManagement.vue?vue&type=script&lang=js&"
export * from "./featLeadManagement.vue?vue&type=script&lang=js&"
import style0 from "./featLeadManagement.vue?vue&type=style&index=0&id=2fd14fef&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fd14fef",
  null
  
)

export default component.exports