<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Mastering Job Handling</h1>
                    </div>
                    <div class="text">
                        <h2>Efficient Scheduling and Tracking</h2>
                        <p>Efficiently managing jobs and tasks is at the core of field service management, and our software's "Job Management System" feature streamlines this process, ensuring every job is well-organized and executed flawlessly.</p>
                    </div>
                    <div data-v-1e9dea97="" style="width: 100%;"><video data-v-1e9dea97="" autoplay :src="filteredFeatures[0].video_path" controls="controls" style="width: 100%;"></video></div>
                </div>
                <section class="recruitment-section elements sec-pad-two centred">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Centralized Job Repository</a></h3>
                                        <div class="text">The Job Management System serves as a centralized repository for all your job-related information. Store job details, client requirements, and scheduling information in one location for easy access.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Scheduling and Assignment</a></h3>
                                        <div class="text">Easily schedule jobs and assign them to your field team members. The feature considers factors like location, skills, and availability to ensure the right technician is assigned to each job.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Task Tracking</a></h3>
                                        <div class="text">Keep track of job progress and tasks in real time. Monitor which tasks are completed, in progress, or pending, allowing for proactive management and decision-making.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Job Notes and Communication</a></h3>
                                        <div class="text">Facilitate communication among your team with job-specific notes and messaging. Share critical information, updates, and instructions to ensure everyone is on the same page.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Performance Analytics</a></h3>
                                        <div class="text">Access performance data for each job, including completion times, customer satisfaction ratings, and resource utilization. Use these insights to refine your processes and enhance service quality.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <figure class="image-box wow fadeInRight" style="width: 455px;margin-top: 58px;" data-wow-delay="300ms" data-wow-duration="1500ms"><img src="/images/schedeasy/features/images/job.png" alt=""></figure>

                            </div>
                        </div>
                    </div>
                </section>
                <div class="bottom-content">
                    <div class="text">
                        <p>The Job Management System is your essential tool for efficient job handling. It streamlines scheduling, improves communication, and provides valuable performance data to optimize your field service operations.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.new-image{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
