<template>
    <div>
      <div class="service-details-content">
        <div class="inner-box">
          <div class="top-content">
            <div class="sec-title">
              <h1>Revolutionize Window Cleaning with Schedeasy</h1>
            </div>
            <div class="text">
              <h2>Effortless Window Cleaning, Amplify Customer Satisfaction</h2>
              <p>
                Crystal-clear windows make a difference. Revolutionize your window cleaning with Schedeasy, making window cleaning effortless and amplifying customer satisfaction.
              </p>
            </div>
            <figure class="image-box">
              <img :src="'/images/schedeasy/industry/details/' + filteredServices[0].wide_image" alt="">
            </figure>
            <div>
              <h1>Transforming Window Cleaning Services with Schedeasy</h1>
              <div class="text">
                <h2>Simplifying Operations and Elevating Transparency</h2>
                <p>
                  Running a window cleaning services business involves a range of challenges – scheduling appointments, coordinating cleaning crews, and ensuring sparkling, streak-free windows. These intricacies often lead to scheduling conflicts, communication gaps, and client concerns about the clarity of their windows. That's where Schedeasy steps in as the transformative solution for window cleaning service providers. With its intelligent appointment scheduling for window cleaning assignments, real-time communication tools for team coordination, and streamlined client management, Schedeasy is tailored to the window cleaning industry. It's more than just software; it's the foundation of efficient operations and exceptional window transparency. Amidst the complexities of window cleaning services, Schedeasy emerges as the ultimate answer, propelling businesses to new heights of success while ensuring pristine and crystal-clear windows for clients to enjoy.
                </p>
              </div>
            </div>
          </div>
          <div class="two-column">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                <div class="content-box">
                  <div>
                    <h3>Invoice Management</h3>
                    <ul>
                      <li>
                        Streamline your window cleaning service invoicing with the Invoice Management feature.
                      </li>
                      <li>
                        View jobs based on dates, categories, and custom details for efficient tracking.
                      </li>
                      <li>
                        Utilize pre-set date filters and filter jobs by specific categories to quickly access the information you need.
                      </li>
                      <li>
                        Perform powerful searches and utilize clickable pills for quick views and easy updates or deletions.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Batch Invoicing</h3>
                    <ul>
                      <li>
                        Simplify your invoicing process further with Batch Invoicing.
                      </li>
                      <li>
                        Consolidate multiple window cleaning jobs into one invoice for streamlined billing.
                      </li>
                      <li>
                        Access job details directly from invoices for comprehensive information.
                      </li>
                      <li>
                        Set default payment statuses to efficiently manage your accounts.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Activity Management System</h3>
                    <ul>
                      <li>
                        Stay organized and on top of window cleaning tasks and appointments with the Activity Management System.
                      </li>
                      <li>
                        Schedule tasks, events, and reminders effectively.
                      </li>
                      <li>
                        Assign activities to users for clear responsibilities and task tracking.
                      </li>
                      <li>
                        View pending activities to ensure nothing falls through the cracks.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms">
                  <img :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt="">
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {};
    },
    mounted() {}
  };
  </script>
  