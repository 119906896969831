<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Streamline Operations with Activity Management</h1>
                    </div>
                    <div class="text">
                        <h2>Optimize Task Handling and Workflow</h2>
                        <p>Efficiently managing the activities and tasks of your field service team is vital for achieving operational excellence, and our software's "Activity Management System" feature streamlines this process, ensuring that every task is tracked and executed seamlessly.</p>
                    </div>
                    <div data-v-1e9dea97="" style="width: 100%;"><video data-v-1e9dea97="" autoplay :src="filteredFeatures[0].video_path" controls="controls" style="width: 100%;"></video></div>

                </div>
                <section class="recruitment-section elements sec-pad-two centred">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Centralized Task Repository</a></h3>
                                        <div class="text">The Activity Management System acts as a centralized hub for all your tasks and activities. Store task details, priorities, deadlines, and assignments in one location for easy access.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Scheduling and Assignment</a></h3>
                                        <div class="text">Effortlessly schedule activities and assign them to your field team members. This feature considers factors like workload, skills, and location to ensure tasks are delegated to the right personnel.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Task Progress Tracking</a></h3>
                                        <div class="text">Keep tabs on the progress of activities and tasks in real time. Monitor which tasks are completed, in progress, or pending, facilitating proactive management and decision-making.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Task Notes and Communication</a></h3>
                                        <div class="text">Enhance communication within your team through task-specific notes and messaging. Share important information, updates, and instructions to ensure everyone is well-informed.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Performance Analytics</a></h3>
                                        <div class="text">Access performance data for each activity and task, including completion times, resource utilization, and task dependencies. Leverage these insights to optimize your workflow and boost productivity.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <figure class="image-box wow fadeInRight" style="width: 455px;margin-top: 58px;" data-wow-delay="300ms" data-wow-duration="1500ms"><img src="/images/schedeasy/features/images/activity.png" alt=""></figure>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="bottom-content">
                    <div class="text">
                        <p>The Activity Management System is your essential tool for efficient task handling. It simplifies scheduling, improves communication, and provides valuable performance data to streamline your field service operations.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.new-image{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
