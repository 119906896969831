<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Transform Your Garage Door Installation Services with Schedeasy</h1>
                    </div>
                    <div class="text">
                        <h2>Simplify Operations, Elevate Customer Satisfaction</h2>
                        <p>
                            Are you facing challenges managing garage door installation appointments, coordinating technicians, and addressing customer inquiries? Embrace Schedeasy for Garage Door Installations – the ultimate solution for seamless service management.
                        </p>
                    </div>
                    <figure class="image-box"><img :src="'/images/schedeasy/industry/details/'+filteredServices[0].wide_image" alt=""></figure>
                    <div>
                        <h1>Transforming Garage Door Services with Schedeasy</h1>
                        <div class="text">
                            <h2>Simplifying Operations and Elevating Customer Satisfaction</h2>
                            <p>
                                Running a garage door services business entails managing a range of challenges – scheduling repairs, coordinating technicians, and ensuring prompt service. These intricacies often lead to scheduling conflicts, communication gaps, and customer frustration. Enter Schedeasy, the transformative solution for garage door service providers. With its intuitive scheduling features, real-time communication tools, and streamlined client management, Schedeasy is tailored for the garage door industry. It's more than just software; it's the foundation of efficient operations and exceptional customer experiences. Amidst the complexities of garage door services, Schedeasy emerges as the ultimate solution, propelling businesses to new heights of success while ensuring secure and efficient garage door solutions.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="two-column">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div class="content-box">
                                <div>
                                    <h3>Customer Management</h3>
                                    <ul>
                                        <li>
                                            Efficiently manage your garage door service and installation customers with comprehensive customer profiles.
                                        </li>
                                        <li>
                                            Keep track of contact details, service history, and preferences to provide personalized service.
                                        </li>
                                        <li>
                                            Easily add new customers and update existing records to enhance customer satisfaction and loyalty.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Route Optimization</h3>
                                    <ul>
                                        <li>
                                            Ensure efficient route planning for your garage door service and installation technicians with the Route Optimization feature.
                                        </li>
                                        <li>
                                            Plan and optimize service routes based on factors like location, job priorities, and technician availability.
                                        </li>
                                        <li>
                                            Minimize travel time and maximize technician productivity by assigning tasks to the most suitable personnel.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Live Support</h3>
                                    <ul>
                                        <li>
                                            Provide dedicated customer support with the Live Support feature for garage door service and installation.
                                        </li>
                                        <li>
                                            Ensure that assistance is readily available for clients and technicians whenever they need it.
                                        </li>
                                        <li>
                                            Enhance communication, resolve issues promptly, and maintain a high level of customer satisfaction in the garage door service industry.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                            <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms"><img  :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt=""></figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{

            }
        },
        mounted(){

        }
    }
</script>
