<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Transform Commercial Cleaning with Schedeasy</h1>
                    </div>
                    <div class="text">
                        <h2>Streamline Operations, Enhance Customer Satisfaction</h2>
                        <p>
                            Struggling to manage commercial cleaning appointments, coordinate cleaning crews, and handle client questions? Embrace Schedeasy for Commercial Cleaning – your ultimate solution for seamless on-site service management.
                        </p>
                    </div>
                    <figure class="image-box"><img :src="'/images/schedeasy/industry/details/'+filteredServices[0].wide_image" alt=""></figure>
                    <div>
                        <h1>Revolutionizing Commercial Cleaning Operations with Schedeasy</h1>
                        <div class="text">
                            <h2>Enhancing Efficiency and Elevating Client Satisfaction</h2>
                            <p>
                                Running a commercial cleaning business comes with its unique set of challenges – managing complex schedules, coordinating cleaning crews, and delivering impeccable results. These intricacies often lead to scheduling conflicts, communication breakdowns, and client dissatisfaction. Enter Schedeasy, the transformative solution for commercial cleaners. With its intelligent scheduling algorithms, real-time communication tools, and streamlined client management, Schedeasy is tailor-made for the commercial cleaning industry. It's more than just software; it's the backbone of efficient operations and exceptional client experiences. In the intricate realm of commercial cleaning, Schedeasy stands as the definitive solution, propelling businesses to new heights of success.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="two-column">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div class="content-box">
                                <div>
                                    <h3>Multi-Location Management</h3>
                                    <ul>
                                        <li>
                                            Effortlessly manage multiple commercial cleaning locations, whether you have branch offices, franchises, or multiple business sites.
                                        </li>
                                        <li>
                                            Centralize operations from a single platform, allowing oversight of each location's performance and access to relevant data.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Staff Management</h3>
                                    <ul>
                                        <li>
                                            Simplify staff management by keeping comprehensive records of staff details, including contact information, work schedules, and performance records.
                                        </li>
                                        <li>
                                            Assign cleaning tasks efficiently based on staff availability, expertise, and location for streamlined operations.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Invoice Management</h3>
                                    <ul>
                                        <li>
                                            Efficiently manage commercial cleaning jobs and invoices with a user-friendly system.
                                        </li>
                                        <li>
                                            View jobs based on dates, categories, and custom details for quick access and organization.
                                        </li>
                                        <li>
                                            Utilize pre-set date filters, filter jobs by specific categories, perform powerful searches, and change job statuses for efficient management.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                            <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms"><img  :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt=""></figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{

            }
        },
        mounted(){

        }
    }
</script>
