<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Precision in Invoicing with Invoice Details</h1>
                    </div>
                    <div class="text">
                        <h2>Comprehensive Billing and Customization</h2>
                        <p>In the world of field service management, precision and transparency in invoicing are paramount, and our software's "Invoice Details" feature ensures you get it right every time.</p>
                    </div>
                    <div data-v-1e9dea97="" style="width: 100%;"><video data-v-1e9dea97="" autoplay :src="filteredFeatures[0].video_path" controls="controls" style="width: 100%;"></video></div>
                </div>
                <section class="recruitment-section elements sec-pad-two centred">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Comprehensive Billing Information</a></h3>
                                        <div class="text">Invoice Details centralizes all billing information, ensuring that every aspect of your invoices is accurate and accounted for. From service charges to materials used, you have complete control.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Customizable Invoice Templates</a></h3>
                                        <div class="text">Tailor your invoices to match your brand identity and convey professionalism. The feature offers customizable templates, allowing you to add your logo, brand colors, and personalized messages.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Itemized Billing</a></h3>
                                        <div class="text">Ensure transparency in your invoicing with itemized billing. List services performed, materials used, and any additional charges, providing your customers with a clear breakdown of the costs.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Integration with Accounting Software</a></h3>
                                        <div class="text">Streamline your financial processes by integrating Invoice Details with your accounting software. This feature allows for seamless transfer of invoicing data, reducing manual data entry.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Automated Invoicing</a></h3>
                                        <div class="text">Save time and reduce errors by automating your invoicing process. Set up recurring invoices, and the system will generate and send them at scheduled intervals.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <figure class="image-box wow fadeInRight" style="width: 455px;margin-top: 58px;" data-wow-delay="300ms" data-wow-duration="1500ms"><img src="/images/schedeasy/features/images/invoice_details.png" alt=""></figure>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="bottom-content">
                    <div class="text">
                        <p>The Invoice Details feature empowers you to create professional, accurate, and transparent invoices effortlessly. It enhances your financial processes, strengthens customer trust, and ensures timely payments for your services.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.new-image{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
