<template>
    <div>
      <div class="service-details-content">
        <div class="inner-box">
          <div class="top-content">
            <div class="sec-title">
              <h1>Revamp Your Painting Business with Schedeasy</h1>
            </div>
            <div class="text">
              <h2>Simplify Painting Projects, Elevate Customer Satisfaction</h2>
              <p>
                Tired of the mess and complexity of painting projects? Revamp your painting business with Schedeasy, simplifying painting projects and elevating customer satisfaction.
              </p>
            </div>
            <figure class="image-box">
              <img :src="'/images/schedeasy/industry/details/' + filteredServices[0].wide_image" alt="">
            </figure>
            <div>
              <h1>Transforming Painting Services with Schedeasy</h1>
              <div class="text">
                <h2>Simplifying Operations and Elevating Aesthetic Excellence</h2>
                <p>
                  Running a painting services business, whether for residential or commercial clients, presents a range of challenges – scheduling projects, coordinating painting teams, and ensuring top-tier paintwork. These intricacies often lead to scheduling conflicts, communication gaps, and client concerns about the visual appeal of their spaces. That's where Schedeasy steps in as the transformative solution for painting service providers. With its intelligent project scheduling for painting assignments, real-time communication tools for team coordination, and streamlined client management, Schedeasy is tailor-made for the painting industry. It's more than just software; it's the foundation of efficient operations and exceptional aesthetic outcomes. Amidst the complexities of painting services, Schedeasy emerges as the ultimate answer, propelling businesses to new heights of success while ensuring visually stunning and well-painted spaces for clients to enjoy, whether they're residential or commercial.
                </p>
              </div>
            </div>
          </div>
          <div class="two-column">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                <div class="content-box">
                  <div>
                    <h3>Route Optimization</h3>
                    <ul>
                      <li>
                        Optimize the routes for your painting service crews with the Route Optimization feature.
                      </li>
                      <li>
                        Efficiently plan and assign painting projects based on job locations, crew availability, and priority.
                      </li>
                      <li>
                        Minimize travel time, reduce fuel costs, and improve overall productivity by ensuring that your crews follow optimized routes.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Communication Funnel</h3>
                    <ul>
                      <li>
                        Centralize all communication related to painting projects in one location with the Communication Funnel feature.
                      </li>
                      <li>
                        Convert inquiries into leads or customers seamlessly by efficiently managing communication channels.
                      </li>
                      <li>
                        Assign emails for follow-up, track communication history, and improve customer relationship management.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Vouchers/Gift Cards</h3>
                    <ul>
                      <li>
                        Create redeemable vouchers with specific quantities and expiration dates using the Vouchers/Gift Cards feature.
                      </li>
                      <li>
                        Track redeemed vouchers and use them as promotional tools to attract more clients to your painting services.
                      </li>
                      <li>
                        Offer discounts or special deals through vouchers to boost customer engagement and loyalty.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms">
                  <img :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt="">
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {};
    },
    mounted() {}
  };
  </script>
  