<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Revolutionize Your Residential Cleaning Business with Schedeasy</h1>
                    </div>
                    <div class="text">
                        <h2>Simplify Operations, Elevate Customer Satisfaction</h2>
                        <p>Are you exhausted from the complexities of scheduling residential cleaning appointments, managing cleaning teams, and addressing client inquiries? Say hello to Schedeasy for Residential Cleaning – the ultimate solution for seamless service management.</p>
                    </div>
                    <figure class="image-box"><img :src="'/images/schedeasy/industry/details/'+filteredServices[0].wide_image" alt=""></figure>
                    <div>
                        <h1>Elevating Residential Cleaning Services with Schedeasy</h1>
                        <div class="text">
                            <h2>Streamlining Operations and Enhancing Service Quality</h2>
                            <p>In the realm of residential cleaning services, success hinges on efficient appointment management, seamless team coordination, and delivering impeccable cleaning results. Yet, these tasks often come with their own set of challenges – scheduling complexities, communication gaps, and the pursuit of spotless homes. This is where Schedeasy steps in to revolutionize the landscape for residential cleaning service providers. With its intelligent appointment scheduling for residential cleaning assignments, real-time communication tools for team collaboration, and streamlined client management, Schedeasy is meticulously crafted for the residential cleaning industry. It's more than just software; it's the cornerstone of streamlined operations and elevated service quality. Amidst the intricacies of residential cleaning services, Schedeasy emerges as the ultimate solution, propelling businesses to new heights of success while ensuring that clients return to a spotless and refreshed home every time.</p>
                        </div>
                    </div>
                </div>
                <div class="two-column">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div class="content-box">
                                <div>
                                    <h3>Invoice Details Customization</h3>
                                    <ul>
                                        <li>Tailor invoices to match your residential cleaning business's branding.</li>
                                        <li>Add promotional images to highlight your cleaning services.</li>
                                        <li>Personalize invoice footers and include special notes or discounts.</li>
                                        <li>Create standardized email templates for professional communication with clients.</li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Invoice Details Customization</h3>
                                    <ul>
                                        <li>Tailor invoices to match your residential cleaning business's branding.</li>
                                        <li>Add promotional images to highlight your cleaning services.</li>
                                        <li>Personalize invoice footers and include special notes or discounts.</li>
                                        <li>Create standardized email templates for professional communication with clients.</li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Invoice Details Customization</h3>
                                    <ul>
                                        <li>Tailor invoices to match your residential cleaning business's branding.</li>
                                        <li>Add promotional images to highlight your cleaning services.</li>
                                        <li>Personalize invoice footers and include special notes or discounts.</li>
                                        <li>Create standardized email templates for professional communication with clients.</li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Invoice Details Customization</h3>
                                    <ul>
                                        <li>Tailor invoices to match your residential cleaning business's branding.</li>
                                        <li>Add promotional images to highlight your cleaning services.</li>
                                        <li>Personalize invoice footers and include special notes or discounts.</li>
                                        <li>Create standardized email templates for professional communication with clients.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                            <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms"><img  :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt=""></figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{

            }
        },
        mounted(){

        }
    }
</script>
