<template>
        <section class="weworks-section elements" style="background-image: url(/images/icons/a1.png);">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-12 col-sm-12 content-column">
                    <div id="content_block_47">
                        <div class="content-box">
                            <div class="upper-box">
                                <div class="sec-title"><h2>What We Offer</h2></div>
                                <div class="text">Unlimited users, all features included for one low price, round-the-clock support, and exciting AI features coming soon. Elevate your Business Management System with Schedeasy.</div>
                                <div class="btn-box"><a href="/try-it-now" class="theme-btn">Start Your Trial Now<i class="fas fa-angle-right"></i></a></div>
                            </div>
                            <div class="counter-inner wow slideInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 counter-column">
                                        <div class="single-counter-box">
                                            <div class="count-outer count-box">
                                                <span class="count-text" data-speed="1500" data-stop="640">500</span><span>+</span>
                                            </div>
                                            <h3>Users</h3>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 counter-column">
                                        <div class="single-counter-box">
                                            <div class="count-outer count-box">
                                                <span class="count-text" data-speed="1500" data-stop="95580">{{ priceMonthSlNoZero }}</span><span>+</span>
                                            </div>
                                            <h3>Businesses</h3>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 counter-column">
                                        <div class="single-counter-box">
                                            <div class="count-outer count-box">
                                                <span class="count-text" data-speed="1500" data-stop="640">{{ priceMonthSlNoZero }}</span><span>+</span>
                                            </div>
                                            <h3>Industries</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-md-12 col-sm-12 inner-column">
                    <div id="content_block_48">
                        <div class="inner-box">
                            <div class="bg-layer wow slideInRight" data-wow-delay="00ms" data-wow-duration="1500ms" style="background-image: url(/images/icons/image-shap-6.png);"></div>
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-md-6 col-sm-12 single-column">
                                    <div class="single-item js-tilt">
                                        <figure class="icon-box"><img src="/images/icons/icon-16.png" alt=""></figure>
                                        <h3><a href="#">Unlimited Users, All Features Included</a></h3>
                                        <div class="text"> Embrace unlimited possibilities with Schedeasy. Enjoy access to our comprehensive suite of features, all bundled in one affordable price.</div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 single-column">
                                    <div class="single-item js-tilt">
                                        <figure class="icon-box"><img src="/images/icons/icon-17.png" alt=""></figure>
                                        <h3><a href="#">24/7 Support</a></h3>
                                        <div class="text">We've got your back around the clock. Our dedicated support team is here to assist you anytime, ensuring you never face a challenge alone.</div>
                                    </div>
                                    <div class="single-item js-tilt">
                                        <figure class="icon-box"><img src="/images/icons/icon-18.png" alt=""></figure>
                                        <h3><a href="#">Constant Innovation</a></h3>
                                        <div class="text">We're continuously enhancing our platform, with exciting AI features on the horizon. </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
#content_block_48 .inner-box .bg-layer {
    position: absolute;
    width: 835px;
    height: 793px;
    top: 40px;
    right: -40px;
    background-repeat: no-repeat;
    background-size: cover;
}

.weworks-section{
    background-position: bottom left;
    background-repeat: no-repeat;
}
</style>