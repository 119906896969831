<template>
  <div id="app" >
    <!-- preloader -->

    <!-- main header -->
    <header class="main-header home-1">
        <div class="outer-container">
            <div class="container">
                <div class="main-box clearfix">
                    <div class="logo-box pull-left">
                        <figure class="logo"><a href="/"><img src="/images/schedeasy/logo-white.png" alt=""></a></figure>
                    </div>
                    <div class="menu-area pull-right">
                        <!--Mobile Navigation Toggler-->
                        <div class="mobile-nav-toggler" @click="showSidebar">
                            <i class="icon-bar"></i>
                            <i class="icon-bar"></i>
                            <i class="icon-bar"></i>
                        </div>
                        <nav class="main-menu navbar-expand-md navbar-light">
                            <div class="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                                <ul class="navigation clearfix">                              
                                    <li><a href="/">Home</a></li>
                                    <li class="dropdown"><a href="/industry">Industry</a>
                                        <ul class="megamenu clearfix">
                                            <template v-for="(item, index) in servicesMod">
                                                <li v-if="item.route_name != 'Industry'" :key="index"  ><a href="javascript:void(0)" @click.prevent="routeChange(item.route_name)">{{ item.title }}</a></li>
                                                <li v-else :key="'view_all'+index"  ><a href="javascript:void(0)" style="text-decoration: underline;" @click.prevent="routeChange(item.route_name)"><b>{{ item.title }}</b> </a></li>
                                            </template>
                                        </ul>
                                    </li> 
                                    <li class="dropdown"><a href="/features">Features</a>
                                        <ul class="megamenu clearfix">
                                            <li v-for="(item, index) in features" :key="index"  ><a href="javascript:void(0)" @click.prevent="routeChange(item.route_name)">{{ item.name }}</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="/pricing">Pricing</a></li>
 
                                    <li> 
                                        <a href="https://app.schedeasy.net/">Login</a>
                                    </li>
                                    <!-- <li> 
                                        <a href="https://app.schedeasy.net/">Customer Logim</a>
                                    </li> -->

                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>

        <!--sticky Header-->
        <div class="sticky-header">
            <div class="container clearfix">
                <figure class="logo-box"><a href="/"><img src="/images/schedeasy/logo-white.png" alt=""></a></figure>
                <div class="menu-area">
                    <nav class="main-menu clearfix">
                        <div class="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                                <ul class="navigation clearfix">                              
                                    <li><a href="/">Home</a></li>
                                    <li class="dropdown">
                                        <a href="/industry">Industry</a>
                                        <ul class="megamenu clearfix">
                                            <template v-for="(item, index) in servicesMod">
                                                <li v-if="item.route_name != 'Industry'" :key="index"  ><a href="javascript:void(0)" @click.prevent="routeChange(item.route_name)">{{ item.title }}</a></li>
                                                <li v-else :key="'view_all'+index"  ><a href="javascript:void(0)" style="text-decoration: underline;" @click.prevent="routeChange(item.route_name)"><b>{{ item.title }}</b> </a></li>
                                            </template>
                                        </ul>
                                    </li> 

                                    <li class="dropdown">
                                        <a href="/features">Features</a>
                                        <ul class="megamenu clearfix">
                                            <li v-for="(item, index) in features" :key="index"  ><a href="javascript:void(0)" @click.prevent="routeChange(item.route_name)">{{ item.name }}</a></li>
                                        </ul>

                                    </li> 
                                    <li><a href="/pricing">Pricing</a></li>

                                    <li> 
                                       <a href="https://app.schedeasy.net/">Login</a>
                                    </li>

                                </ul>
                            </div>
                    </nav>
                </div>
            </div>
        </div>
    </header>
    <!-- main-header end -->

    <!-- Mobile Menu  -->
    <div class="mobile-menu">
        <div class="menu-backdrop"></div>
        <div class="close-btn"><i class="fas fa-times"></i></div>
        
        <nav class="menu-box">
            <div class="nav-logo"><a href="/"><img src="/images/schedeasy/footer-logo.png" alt="" title=""></a></div>
            <div class="menu-outer">
                <ul class="navigation clearfix">
                    <li><a href="/">Home</a></li>
                    <li class="dropdown">
                        <a href="/industry">Industry</a>
                        <ul class="megamenu clearfix">
                            <template v-for="(item, index) in servicesMod">
                                <li v-if="item.route_name != 'Industry'" :key="index"  ><a href="javascript:void(0)" @click.prevent="routeChange(item.route_name)">{{ item.title }}</a></li>
                                <li v-else :key="'view_all'+index"  ><a href="javascript:void(0)" style="text-decoration: underline;" @click.prevent="routeChange(item.route_name)"><b>{{ item.title }}</b> </a></li>
                            </template>
                        </ul>
                        <div class="dropdown-btn"><span class="fa fa-angle-right"></span></div>

                    </li> 

                    <li class="dropdown"><a href="/features">Features</a>
                        <ul class="megamenu clearfix">
                            <li v-for="(item, index) in features" :key="index"  ><a href="javascript:void(0)" @click.prevent="routeChange(item.route_name)">{{ item.name }}</a></li>
                        </ul>
                        <div class="dropdown-btn"><span class="fa fa-angle-right"></span></div>
                    </li> 
                    <li><a href="/pricing">Pricing</a></li>

                    <li> 
                        <a href="https://app.schedeasy.net/">Login</a>
                    </li>
                </ul>
              
            </div>
            <div class="contact-info">
                <!-- <h4>Contact Info</h4>
                <ul>
                    <li>Chicago 12, Melborne City, USA</li>
                    <li><a href="tel:+8801682648101">+88 01682648101</a></li>
                    <li><a href="mailto:info@example.com">info@example.com</a></li>
                </ul> -->
            </div>
            <div class="social-links">
                <!-- <ul class="clearfix">
                    <li><a href="#"><span class="fab fa-twitter"></span></a></li>
                    <li><a href="#"><span class="fab fa-facebook-square"></span></a></li>
                    <li><a href="#"><span class="fab fa-pinterest-p"></span></a></li>
                    <li><a href="#"><span class="fab fa-instagram"></span></a></li>
                    <li><a href="#"><span class="fab fa-youtube"></span></a></li>
                </ul> -->
            </div>
        </nav>
    </div><!-- End Mobile Menu -->
    <router-view/>
      <!-- userfree-section -->
      <section class="userfree-section centred">
            <div class="pattern-bg" style="background-image: url(/images/icons/pattern-20.png);"></div>
            <div class="container">
                <div class="content-box">
                    <div class="text">NO CREDIT CARD NEEDED</div>
                    <h2>TRY THE SCHEDEASY COMMITMENT FREE TRIAL TODAY</h2>
                    <div class="btn-box"><a href="/try-it-now">Start Your Free Trial</a></div>
                </div>
            </div>
        </section>
    <!-- userfree-section end -->
        <!-- subscribe-section -->
     <section class="subscribe-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                      <div id="image_block_14">
                            <div class="image-box">
                                <div class="bg-layer" style=" background-image: url(/images/icons/image-shap-6.png);background-position: center;background-size: contain;"></div>
                                <figure class="image image-1 clearfix wow slideInRight animated customerSeriveImg" data-wow-delay="00ms" data-wow-duration="1500ms"><img src="/images/schedeasy/customerService.png" alt=""></figure>
                            </div>
                        </div>
                  
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                    <div id="content_block_06">
                        <div class="content-box">
                            <div class="sec-title"><h2>Contact Us</h2></div>
                            <div class="text">We are always here to make everything easy for you. You can chat directly with our 24/7 support team. You can also schedule for a product demonstration at your most convenient time.</div>
                            <form action="#" method="post" class="subscribe-form">
                                <div class="form-group">
                                    <a href="https://calendly.com/schedeasy" target="blank" style="margin-right: 20px;"  class="theme-btn-two">Schedule a demo </a>
                                    <a href="javascript:void(0)" onclick="$(&quot;#zsiq_agtpic&quot;).click();" class="theme-btn-two">Online Chat</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- subscribe-section end -->

  

    <!-- main-footer -->
    <footer class="main-footer">
        <div class="image-layer" style="background-image: url(/images/icons/footer-bg.png);"></div>
        <div class="container">
            <div class="footer-top">
                <div class="widget-section">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-12 footer-column">
                            <div class="about-widget footer-widget">
                                <figure class="footer-logo"><a href="/"><img src="/images/schedeasy/footer-logo.png" alt=""></a></figure>
                                <div class="text">The brains behind SchedEasy are the people responsible for starting and running SANCAP a company offering back office support solutions that can be customised according to a client’s needs. They thought of coming up with their own system that will streamline their processes and make them rely less on Excel sheets, Google calendar, and the usual pen and paper.</div>
                                <ul class="social-links">
                                    <li><h6>Follow Us :</h6></li>
                                    <li><a href="https://www.facebook.com/schedeasy/" target="blank"><i class="fab fa-facebook-f"></i></a></li>
                                    <li><a href="https://www.instagram.com/schedeasy/" target="blank"><i class="fab fa-instagram"></i></a></li>
                                    <li><a href="https://ca.linkedin.com/company/schedeasy" target="blank"><i class="fab fa-linkedin-in"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        
                        <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                            <div class="links-widget footer-widget">
                                <!-- <h4 class="widget-title">Support</h4>
                                <div class="widget-content">
                                    <ul class="list clearfix">
                                        <li><a href="#">Contact Us</a></li>
                                        <li><a href="#">Submit a Ticket</a></li>
                                        <li><a href="#">Visit Knowledge Base</a></li>
                                        <li><a href="#">Support System</a></li>
                                        <li><a href="#">Refund Policy</a></li>
                                        <li><a href="#">Professional Services</a></li>
                                    </ul>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-12 footer-column">
                            <div class="links-widget footer-widget">
                                <h4 class="widget-title">Links</h4>
                                <div class="widget-content">
                                    <ul class="list clearfix">
                                        <li><a href="/">Home</a></li>
                                        <li><a href="/industry">Industry</a></li>
                                        <li><a href="/features">Features</a></li>
                                        <li> 
                                        <a href="/try-it-now">Start Your Free Trial</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                            <div class="contact-widget footer-widget">
                                <h4 class="widget-title">Contact Info</h4>
                                <div class="widget-content">
                                    <ul class="list clearfix">
                                        <li><i class="fas fa-map-marker-alt"></i><a href="https://www.google.com.ph/maps/place/1199+W+Hastings+St,+Vancouver,+BC+V6E+3T5,+Canada/@49.2889141,-123.1245378,17z/data=!3m1!4b1!4m5!3m4!1s0x548671840f687bcb:0xfe98d44aaf7f1ff5!8m2!3d49.2889106!4d-123.1223491">Vancouver, British Columbia, Canada</a> </li>
                                        <!-- <li>
                                            <i class="fas fa-phone-volume"></i>
                                            <a href="tel:+0665184575181">(+066) 518 - 457 - 5181</a><br />
                                            <a href="tel:+0665184575182">(+066) 518 - 457 - 5182</a>
                                        </li> -->
                                        <li>
                                            <i class="fas fa-envelope"></i>
                                            <a href="mailto:info@schedeasy.net">info@schedeasy.net</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <div class="copyright">&copy; 2020 <a href="/">Schedeasy</a>. All rights reserved</div>
            </div>
        </div>
    </footer>
    <button class="scroll-top scroll-to-target open" data-target="#app">
        <span class="fa fa-arrow-up"></span>
    </button>
    <!-- main-footer end -->
  </div>
</template>

<script>

export default {
    components: {},
    data(){
        return{
            servicesMod:{}
        }
    },
    mounted(){

        const replacementObject = {
            title: 'View All',
            route_name: 'Industry',
        };
        var max =18
        this.servicesMod = this.services.slice(0, max);
        console.log("wers", this.servicesMod);
        // If the original array exceeds 'maxItems', add the 'replacementObject'
        if (this.services.length > max) {
            this.servicesMod.push(replacementObject);
        }
        if($('.scroll-to-target').length){
            $(".scroll-to-target").on('click', function() {
                var target = $(this).attr('data-target');
            // animate
            $('html, body').animate({
                scrollTop: $(target).offset().top
                }, 1000);
        
            });
        }
        this.$nextTick(() => {
                if($('.main-header li.dropdown ul').length){
                    $('.main-header .navigation li.dropdown').append('<div class="dropdown-btn"><span class="fa fa-angle-right"></span></div>');
                }
                
                if($('.mobile-menu').length){
		
                    $('.mobile-menu .menu-box').mCustomScrollbar();
                    
                    // var mobileMenuContent = $('.main-header .menu-area .main-menu').html();
                    // $('.mobile-menu .menu-box .menu-outer').append(mobileMenuContent);
                    
                    //Dropdown Button
                    $('.mobile-menu li.dropdown .dropdown-btn').on('click', function() {
                        $(this).toggleClass('open');
                        $(this).prev('ul').slideToggle(500);
                    });
                    //Menu Toggle Btn
                    $('.mobile-nav-toggler').on('click', function() {
                        $('body').addClass('mobile-menu-visible');
                    });

                    //Menu Toggle Btn
                    $('.mobile-menu .menu-backdrop,.mobile-menu .close-btn').on('click', function() {
                        $('body').removeClass('mobile-menu-visible');
                    });
                }
            })

    },
    methods:{
        showSidebar(){
            // document.body.classList.add('mobile-menu-visible');

        },
        routeChange(route){
                this.$router.push({name:route})
            }
    }
};
</script>

<style lang="scss" scoped>
#image_block_14 .image-box .bg-layer {
    position: absolute;
    width: 750px;
    height: 735px;
    top: -85px;
    left: -178px;
    background-repeat: no-repeat;
}
.customerSeriveImg{
  width: 570px;
}
#image_block_14 .image-box .image-1 img {
    max-width: 100%;
    float: left;
}
.start-trial{
    background-color: #4527a4;
    padding: 0px 10px!important;
    border-radius: 25px;
}

.main-menu .navigation .megamenu:before{
    left: 49%;

}

.main-menu .navigation > li > ul{
    left: -130px!important;
}

.scroll-top.open {
    bottom: 88px;
    right: 20px;
}


@media only screen and (max-width: 1400px){
    #app{
        overflow: hidden;
    }
}

@media only screen and (max-width: 992px){
        .subscribe-section{
            .bg-layer{
                left: -40px!important;
            }
        }
}
</style>
