<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Transform Your Elevator Repair Business with Schedeasy</h1>
                    </div>
                    <div class="text">
                        <h2>Streamline Operations, Enhance Customer Satisfaction</h2>
                        <p>
                            Are you grappling with the complexities of scheduling elevator repair jobs, coordinating technicians, and managing client inquiries? Embrace Schedeasy for Elevator Repair – the ultimate solution for seamless service management.
                        </p>
                    </div>
                    <figure class="image-box"><img :src="'/images/schedeasy/industry/details/'+filteredServices[0].wide_image" alt=""></figure>
                    <div>
                        <h1>Revolutionizing Elevator Repair Operations with Schedeasy</h1>
                        <div class="text">
                            <h2>Enhancing Efficiency and Elevating Maintenance Excellence</h2>
                            <p>
                                Managing an elevator repair business poses unique challenges – overseeing intricate repairs, coordinating specialized teams, and ensuring optimal safety. These complexities often result in delayed repairs, communication gaps, and client dissatisfaction. That's where Schedeasy comes in as the transformative solution. With its intelligent repair scheduling, real-time communication tools, and streamlined client management, Schedeasy is tailored to the elevator industry. It's more than just software; it's the cornerstone of efficient operations and exceptional maintenance service. Amidst the intricacies of elevator repair, Schedeasy stands as the ultimate answer, propelling businesses to new heights of success while ensuring reliable and safe elevator systems.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="two-column">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div class="content-box">
                                <div>
                                    <h3>Advanced Calendar Features</h3>
                                    <ul>
                                        <li>
                                            Enhance your scheduling capabilities with advanced calendar tools.
                                        </li>
                                        <li>
                                            Utilize intuitive drag-and-drop functionality to schedule maintenance visits, inspections, and repair tasks.
                                        </li>
                                        <li>
                                            Visualize your calendar with ease and stay organized to optimize your workflow and time management for elevator service and repair appointments.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Route Optimization</h3>
                                    <ul>
                                        <li>
                                            Ensure efficient route planning for your elevator service technicians with the Route Optimization feature.
                                        </li>
                                        <li>
                                            Plan and optimize service routes based on factors like location, task priorities, and technician availability.
                                        </li>
                                        <li>
                                            Minimize travel time and maximize technician productivity by assigning tasks to the most suitable personnel.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Live Support</h3>
                                    <ul>
                                        <li>
                                            Provide dedicated customer support with the Live Support feature.
                                        </li>
                                        <li>
                                            Ensure that assistance is readily available for clients and technicians whenever they need it.
                                        </li>
                                        <li>
                                            Enhance communication, resolve issues promptly, and maintain a high level of customer satisfaction in the elevator service and repair industry.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                            <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms"><img  :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt=""></figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{

            }
        },
        mounted(){

        }
    }
</script>
