<template>
    <div>
      <div class="service-details-content">
        <div class="inner-box">
          <div class="top-content">
            <div class="sec-title">
              <h1>Revolutionize Your Irrigation Services with Schedeasy</h1>
            </div>
            <div class="text">
              <h2>Streamline Operations, Elevate Customer Satisfaction</h2>
              <p>
                Are you struggling to manage irrigation service appointments, coordinate technicians, and address customer inquiries? Say hello to Schedeasy for Irrigation Services – your ultimate solution for seamless service management.
              </p>
            </div>
            <figure class="image-box">
              <img :src="'/images/schedeasy/industry/details/' + filteredServices[0].wide_image" alt="">
            </figure>
            <div>
              <h1>Transforming Irrigation Services with Schedeasy</h1>
              <div class="text">
                <h2>Simplifying Operations and Elevating Landscape Care</h2>
                <p>
                  Managing an irrigation services business involves a range of challenges – scheduling installations, coordinating maintenance, and ensuring optimal landscape health. These intricacies often lead to scheduling conflicts, communication gaps, and client concerns. That's where Schedeasy steps in as the transformative solution for irrigation service providers. With its intelligent scheduling for installations and maintenance, real-time communication tools, and streamlined client management, Schedeasy is designed specifically for the irrigation industry. It's more than just software; it's the foundation of efficient operations and exceptional landscape care. Amidst the complexities of irrigation services, Schedeasy emerges as the ultimate answer, propelling businesses to new heights of success while ensuring vibrant and healthy landscapes for clients.
                </p>
              </div>
            </div>
          </div>
          <div class="two-column">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                <div class="content-box">
                  <div>
                    <h3>Multi-Location Management</h3>
                    <ul>
                      <li>
                        Streamline the management of multiple irrigation service locations with the Multi-Location Management feature.
                      </li>
                      <li>
                        Whether you operate branches, serve different regions, or manage irrigation projects across various sites, centralize project data.
                      </li>
                      <li>
                        Monitor the performance of each location, access relevant data, and maintain consistency in operations to ensure efficient irrigation services.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Communication Funnel</h3>
                    <ul>
                      <li>
                        Centralize all communication related to irrigation services in one location with the Communication Funnel feature.
                      </li>
                      <li>
                        Convert inquiries into leads or customers seamlessly by efficiently managing communication channels.
                      </li>
                      <li>
                        Assign emails for follow-up, track communication history, and improve customer relationship management.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Dashboard</h3>
                    <ul>
                      <li>
                        Gain a quick and insightful overview of your irrigation services business with the Dashboard feature.
                      </li>
                      <li>
                        Access performance metrics, project statuses, and essential information all in one place.
                      </li>
                      <li>
                        Use the admin section for sending messages, adding notes for desktop users, and keeping your team updated and informed.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms">
                  <img :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt="">
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {};
    },
    mounted() {}
  };
  </script>
  