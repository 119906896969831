<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Effortless Route Planning with Route Optimization</h1>
                    </div>
                    <div class="text">
                        <h2>Streamlining Field Operations for Maximum Efficiency</h2>
                        <p>Efficient route planning is a cornerstone of successful field service management, and our software's "Route Optimization" feature revolutionizes this process, ensuring that your team's routes are optimized for maximum efficiency.</p>
                    </div>
                    <div data-v-1e9dea97="" style="width: 100%;"><video data-v-1e9dea97="" autoplay :src="filteredFeatures[0].video_path" controls="controls" style="width: 100%;"></video></div>
                </div>
                <section class="recruitment-section elements sec-pad-two centred">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Centralized Routing Hub</a></h3>
                                        <div class="text">The Route Optimization feature serves as a centralized hub for route planning. Store and manage all route-related information, including job locations, technician availability, and real-time traffic data in one location for easy access.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Intelligent Scheduling</a></h3>
                                        <div class="text">Effortlessly plan routes for your field team members. This feature uses advanced algorithms to factor in variables like job priorities, technician skills, and location, ensuring that tasks are assigned to the most suitable personnel.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Real-Time Route Tracking</a></h3>
                                        <div class="text">Keep a watchful eye on route progress in real time. Monitor routes as they are completed, in progress, or pending, enabling proactive management and decision-making.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Communication and Updates</a></h3>
                                        <div class="text">Enhance communication within your team through route-specific notes and messaging. Share critical information, updates, and instructions to ensure everyone is well-informed about route changes and challenges.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Performance Analytics</a></h3>
                                        <div class="text">Access performance metrics for each route and task, including completion times, fuel consumption, and job dependencies. These insights empower you to optimize your team's workflow, minimize travel time, and increase overall productivity.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="bottom-content">
                    <div class="text">
                        <p>The Route Optimization feature is your go-to solution for streamlined route planning. It simplifies scheduling, improves communication, and provides valuable performance data to optimize your field service operations, reducing costs and enhancing customer satisfaction.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.new-image{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
