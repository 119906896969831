<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Revolutionize Your Construction Company with Schedeasy</h1>
                    </div>
                    <div class="text">
                        <h2>Streamline Operations, Enhance Customer Satisfaction</h2>
                        <p>
                            Feeling overwhelmed by the demands of construction project scheduling, team coordination, and client communications? Welcome Schedeasy for Construction Companies – the ultimate solution for seamless on-site service management.
                        </p>
                    </div>
                    <figure class="image-box"><img :src="'/images/schedeasy/industry/details/'+filteredServices[0].wide_image" alt=""></figure>
                    <div>
                        <h1>Revolutionizing Construction Operations with Schedeasy</h1>
                        <div class="text">
                            <h2>Enhancing Efficiency and Elevating Project Success</h2>
                            <p>
                                Running a construction company comes with a unique set of challenges – managing complex projects, coordinating diverse teams, and ensuring timely completion. These intricacies often lead to project delays, communication gaps, and client dissatisfaction. That's where Schedeasy steps in as the transformative solution. With its intelligent project scheduling, real-time communication tools, and streamlined client management, Schedeasy is tailored to the construction industry. It's more than just software; it's the foundation of efficient operations and exceptional project outcomes. Amid the intricacies of construction, Schedeasy emerges as the ultimate answer, propelling businesses to new heights of success.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="two-column">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div class="content-box">
                                <div>
                                    <h3>Advanced Calendar Features</h3>
                                    <ul>
                                        <li>
                                            Enhance project scheduling with our advanced calendar tools.
                                        </li>
                                        <li>
                                            Utilize intuitive drag-and-drop functionality to schedule construction tasks, equipment deliveries, and project milestones.
                                        </li>
                                        <li>
                                            Stay organized and efficiently assign construction activities to specific dates and times, optimizing your project workflow and time management.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Invoice Details Customization</h3>
                                    <ul>
                                        <li>
                                            Customize invoices to reflect your construction business's branding and professionalism.
                                        </li>
                                        <li>
                                            Include promotional images highlighting your construction services, such as completed projects or specialized equipment.
                                        </li>
                                        <li>
                                            Personalize invoice footers with important information, notes, or discounts, enhancing your client communication and satisfaction.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Multi-Location Management</h3>
                                    <ul>
                                        <li>
                                            Streamline the management of construction projects across multiple locations, whether you're handling various building sites or regional offices.
                                        </li>
                                        <li>
                                            Centralize your construction project data and monitor performance at each location, ensuring consistency and efficiency in your operations.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                            <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms"><img  :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt=""></figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{

            }
        },
        mounted(){

        }
    }
</script>
