<template>
    <div>
        <section class="page-title" style="background-image: url(/images/background/pagetitle-bg.png);">
            <div class="anim-icons">
                <div class="icon icon-1"><img src="/images/icons/anim-icon-17.png" alt=""></div>
                <div class="icon icon-2 rotate-me"><img src="/images/icons/anim-icon-18.png" alt=""></div>
                <div class="icon icon-3 rotate-me"><img src="/images/icons/anim-icon-19.png" alt=""></div>
                <div class="icon icon-4"></div>
            </div>
            <div class="container">
                <div class="content-box clearfix">
                    <div class="title-box pull-left">
                        <h1>Pricing</h1>
                        <p> Affordable and All-Inclusive Pricing</p>
                    </div>
                    <ul class="bread-crumb pull-right">
                        <li><a href="/">Home</a></li>
                        <li>Pricing</li>
                    </ul>
                </div>
            </div>
        </section>
    <!-- pricing-section -->
    <section class="pricing-section pricing-page centred">
        <div class="container">
            <div class="sec-title center">
                <h2>Unlimited Users, All Features, One Irresistible Price!</h2>
                <p>At Schedeasy, we're on a mission to simplify and spice up field service management. Forget about hidden fees and feature add-ons. Our sizzling deal? A single, irresistible price tag that unlocks endless features and invites unlimited users. Experience the hottest way to manage your business.</p>
            </div>
            <div class="tabs-box">
                <div class="tabs-content">
                    <div class="tab active-tab" id="tab-1">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12 pricing-column">
                                <div class="pricing-block-one">
                                    <div class="pricing-table">
                                        <figure class="image"><img src="images/schedeasy/pricing.png" alt=""></figure>
                                        <div class="table-header">
                                            <h3 class="title">TRIAL</h3>
                                            <h2 class="free-no-dollar">FREE</h2>
                                        </div>
                                        <div class="table-content">
                                            <ul> 
                                                <li>Commitment Free Trial</li>
                                                <li>All features included</li>
                                                <li>Unlimited Users </li>
                                                <li>Schedule and manage jobs</li>
                                                <li>Send quotes and invoices</li>
                                                <li>24/7 client self-serve hub</li>
                                                <li>Automated reminders</li>
                                                <li>Online booking</li>
                                            </ul>
                                        </div>
                                        <div class="table-footer">
                                            <a href="/try-it-now" class="theme-btn-two">Start Trial</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 pricing-column">
                                <div class="pricing-block-one">
                                    <div class="pricing-table">
                                        <figure class="image"><img src="images/schedeasy/pricing2.png" alt=""></figure>
                                        <div class="table-header">
                                            <h3 class="title">Single Location</h3>
                                            <h2 class="price">{{priceMonthSl}}<span>/Mo</span></h2>
                                        </div>
                                        <div class="table-content">
                                            <ul> 
                                                <li>Commitment Free Trial</li>
                                                <li>All features included</li>
                                                <li>Unlimited Users </li>
                                                <li>Schedule and manage jobs</li>
                                                <li>Send quotes and invoices</li>
                                                <li>24/7 client self-serve hub</li>
                                                <li>Automated reminders</li>
                                                <li>Online booking</li>
                                            </ul>
                                        </div>
                                        <div class="table-footer">
                                            <a href="/try-it-now" class="theme-btn-two">Start Trial</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 pricing-column">
                                <div class="pricing-block-one">
                                    <div class="pricing-table">
                                        <figure class="image"><img src="images/schedeasy/pricing3.png" alt=""></figure>
                                        <div class="table-header">
                                            <h3 class="title">Multi Location</h3>
                                            <h2 class="price mid-price">{{priceMonthMl}}<span>/Location</span></h2>
                                        </div>
                                        <div class="table-content">
                                            <ul> 
                                                <li>All features included</li>
                                                    <li>Unlimited Users </li>
                                                    <li>Schedule and manage jobs</li>
                                                    <li>Send quotes and invoices</li>
                                                    <li>24/7 client self-serve hub</li>
                                                    <li>Automated reminders</li>
                                                    <li>Multi Location Management</li>
                                                    <li>Franchise system </li>
                                            </ul>
                                        </div>
                                        <div class="table-footer">
                                            <a href="https://calendly.com/schedeasy" class="theme-btn-two">Talk To Us</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  
                </div>
               
            </div>
        </div>
    </section>
    <!-- pricing-section end -->
    <PricingAddDetails/>
   
    <!-- pricing-style-four -->
    <section class="pricing-style-four paricing-page-3">
        <div class="container">
            <div class="sec-title center"><h2>Unleash the Power of Schedeasy with our All-Inclusive Pricing</h2></div>
            <div class="row">
                <div class="col-lg-4 col-md-12 col-sm-12 feature-column">
                    <div class="feature-inner">
                        <h2 style="visibility: hidden;">Unlimeted</h2>
                        <ul class="list clearfix">
                            <li v-for="(feat, index) in feats" :key="index">{{ feat.name }}</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-8 col-md-12 col-sm-12 pricing-column">
                    <div class="pricing-inner clearfix">
                        <div class="pricing-table">
                            <div class="table-header" style="
                            padding: 55px 15px 36px 15px;">
                                <h3 class="title">TRIAL</h3>
                                <h1 class="price">FREE</h1>
                                <div class="text">7 Days Period</div>
                            </div>
                            <div class="table-content">
                                <ul class="clearfix">
                                    <template v-for="(feat, index) in feats" >
                                        <li :key="'right'+index" v-if="feat.name =='Multi-Location Management' || feat.name=='Franchise System'"><i style="color:#dc3545" class="fas fa-times"></i></li>
                                        <li :key="'wrong'+index" v-else><i class="fas fa-check"></i></li>
                                    </template>
                                </ul>
                            </div>
                            <div class="table-footer"><a href="/try-it-now">Start Trial</a></div>
                        </div>
                        <div class="pricing-table">
                            <div class="table-header">
                                <h3 class="title">SINGLE LOCATION</h3>
                                <h1 class="price"><span>$</span>{{ priceMonthNoZeroSl }}</h1>
                                <div class="text">Per Month</div>
                            </div>
                            <div class="table-content">
                                <ul class="clearfix">

                                    <template v-for="(feat, index) in feats" >
                                        <li :key="'right'+index" v-if="feat.name =='Multi-Location Management' || feat.name=='Franchise System'"><i style="color:#dc3545" class="fas fa-times"></i></li>
                                        <li :key="'wrong'+index" v-else><i  class="fas fa-check"></i></li>
                                    </template>
                                </ul>
                            </div>
                            <div class="table-footer"><a href="/try-it-now">Start Trial</a></div>
                        </div>
                        <div class="pricing-table">
                            <div class="table-header">
                                <h3 class="title">MULTI LOCATION</h3>
                                <h1 class="price"><span>$</span>{{ priceMonthNoZeroMl }}</h1>
                                <div class="text">Per Location</div>
                            </div>
                            <div class="table-content">
                                <ul class="clearfix">

                                    <template v-for="(feat, index) in feats" >
                                        <li :key="'wrong'+index"><i class="fas fa-check"></i></li>
                                    </template>
                                </ul>
                            </div>
                            <div class="table-footer"><a href="https://calendly.com/schedeasy">Talk To Us</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- pricing-style-four end -->
    <!-- faq-section -->
    <section class="faq-section pricing-page-4">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 content-column">
                    <div class="faq-content">
                        <div class="sec-title"><h2>FAQ</h2></div>
                        <ul class="accordion-box">
                            <li v-for="(item, index) in faq" class="accordion block">
                                <div class="acc-btn">
                                    <div class="icon-outer"><i class="fas fa-plus"></i></div>
                                    <h4>{{ item.q }}</h4>
                                </div>
                                <div class="acc-content">
                                    <div class="content">
                                        <div class="text">{{ item.a }}</div>
                                    </div>
                                </div>
                            </li>

                        </ul>
                    </div> 
                </div>
            </div>
            <p style="margin-top: 20px;">Choose Schedeasy for affordable excellence in field service management, including all features and the flexibility to add unlimited users, admin staff, and field staff. Start your commitment free trial today to unleash the full potential of your business!
</p>
        </div>
    </section>
    <!-- faq-section end -->
    </div>
</template>

<script>
import PricingAddDetails from '@/components/pricing_add_detail.vue'
    export default {
        components:{PricingAddDetails},
        data(){
            return{
                feats:[]
            }
        },
        mounted(){

            this.feats = [...this.features];
            const newObject = { name: "Unlimited User"};
            this.feats.splice(2, 0, newObject);
            if($('.tabs-box').length){
                $('.tabs-box .tab-buttons .tab-btn').on('click', function(e) {
                    e.preventDefault();
                    var target = $($(this).attr('data-tab'));
                    
                    if ($(target).is(':visible')){
                        return false;
                    }else{
                        target.parents('.tabs-box').find('.tab-buttons').find('.tab-btn').removeClass('active-btn');
                        $(this).addClass('active-btn');
                        target.parents('.tabs-box').find('.tabs-content').find('.tab').fadeOut(0);
                        target.parents('.tabs-box').find('.tabs-content').find('.tab').removeClass('active-tab');
                        $(target).fadeIn(300);
                        $(target).addClass('active-tab');
                    }
                });
            }
            	//Accordion Box
                if($('.accordion-box').length){
                    $(".accordion-box").on('click', '.acc-btn', function() {
                        
                        var outerBox = $(this).parents('.accordion-box');
                        var target = $(this).parents('.accordion');
                        
                        if($(this).hasClass('active')!==true){
                            $(outerBox).find('.accordion .acc-btn').removeClass('active');
                        }
                        
                        if ($(this).next('.acc-content').is(':visible')){
                            return false;
                        }else{
                            $(this).addClass('active');
                            $(outerBox).children('.accordion').removeClass('active-block');
                            $(outerBox).find('.accordion').children('.acc-content').slideUp(300);
                            target.addClass('active-block');
                            $(this).next('.acc-content').slideDown(300);	
                        }
                    });	
                }

        }
    }
</script>

<style lang="scss" scoped>

.pricing-style-four.paricing-page-3 {
    padding: 150px 0px 90px 0px;
}

.page-title {
    padding: 361px 0px 314px 0px;
   
}

.mid-price:before{
    left: 50px!important;

}
.pricing-style-four .pricing-table .table-content li{
    color: #4527a4!important;

}

.pricing-block-one .pricing-table .table-content {
    position: relative;
    padding: 37px 15px 20px 50px;
    text-align: left;
    min-height: 231px;
}

.free-no-dollar{
    position: relative;
    display: block;
    font-size: 48px;
    line-height: 55px;
    font-weight: 700;
    color: #4527a4;
    background: #f1edff;
    padding: 22px 15px 6px 15px;
    transition: all 500ms ease;
}
</style>