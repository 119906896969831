<template>
    <div>
       
        <section class="page-title" style="background-image: url(/images/background/pagetitle-bg.png);">
            <div class="anim-icons">
                <div class="icon icon-1"><img src="/images/icons/anim-icon-17.png" alt=""></div>
                <div class="icon icon-2 rotate-me"><img src="/images/icons/anim-icon-18.png" alt=""></div>
                <div class="icon icon-3 rotate-me"><img src="/images/icons/anim-icon-19.png" alt=""></div>
                <div class="icon icon-4"></div>
            </div>
            <div class="container">
                <div class="content-box clearfix">
                    <div class="title-box pull-left">
                        <h1>REGISTER NOW</h1>
                        <p>Join Our Community – Register Today! </p>
                    </div>
                    <ul class="bread-crumb pull-right">
                        <li><a href="/">Home</a></li>
                        <li>Commitment Free Trial </li>
                    </ul>
                </div>
            </div>
        </section>
        <!-- subscribe-section -->
        <section class="subscribe-section" style="background-image: url(/images/icons/a1.png);">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 image-column">
                        <div id="image_block_14">
                                <div class="image-box">
                                    <div class="bg-layer" style=" background-image: url(/images/icons/image-shap-6.png);background-position: center;background-size: contain;">
                                        <div v-if="!loading" class="form-container">
                                            <div class="form-header">
                                                <h2> <b>Commitment Free Trial </b> </h2>
                                                <!-- <Creator/> -->
                                                <p>Step {{ currentStep }} of 3</p>
                                            </div>
                                            <form class="form-content" >
                                                <div v-show="currentStep === 1" class="pi-information" >
                                                    <!-- Personal Information Section -->
                                                    <h4>Personal Information</h4>
                                                    <div class="form-item">
                                                        <label>First Name <small style="color:red">*</small></label>
                                                        <input name="firstname" v-model="form.first_name" type="text" placeholder="What is your First Name?">
                                                        <span v-if="errors.first_name" id="err_firstname" class="required_data"><b>{{ errors.first_name}}</b></span><br>
                                                    </div>

                                                    <div class="form-item">
                                                        <label>Last Name <small style="color:red">*</small></label>
                                                        <input name="lastname" v-model="form.last_name" type="text" placeholder="What is your Last Name?">
                                                        <span v-if="errors.last_name" id="err_lastname" class="required_data"><b>{{errors.last_name }}</b></span><br>
                                                    </div>
                                                    <div class="form-item">
                                                        <label>Contact Number <small style="color:red">*</small></label>
                                                        <input name="contact_number_p" v-mask="'(###) ###-####'" placeholder="(###) ###-####" v-model="form.contact_number" >
                                                        <span v-if="errors.contact_number" id="err_contact_number" class="required_data"><b>{{ errors.contact_number }}</b></span>
                                                    </div>

                                                    <div class="form-item">
                                                        <label>Email <small style="color:red">*</small></label>
                                                        <input v-model="form.email" type="text" placeholder="We need your email address">
                                                        <span v-if="errors.email" id="err_email" class="required_data"><b>{{ errors.email }}</b></span><br>
                                                    </div>

                                                  

                                                    <div   class="form-item">
                                                        <label>Password <small style="color:red">*</small></label>
                                                        <input name="password" v-model="form.password" type="password" placeholder="Make your password strong">
                                                        <span id="err_password" class="required_data"><b>{{ errors.password }}</b></span>
                                                    </div>

                                                    <div   class="form-item">
                                                        <label>Confirm Password <small style="color:red">*</small></label>
                                                        <input name="conf_password" v-model="form.confirmPassword" type="password" placeholder="Re enter your password">
                                                        <span v-if="errors.confirmPassword" id="err_conf_password" class="required_data"><b>{{ errors.confirmPassword }}</b></span>
                                                    </div>
                                                    <!-- Your personal information form fields here -->
                                                    <button type="button"  @click="nextStep">Next</button>
                                                </div>

                                                <div v-show="currentStep === 2" class="pi-information">
                                                    <!-- Address Section -->
                                                    <h3>Address</h3>
                                                    <div class="form-item">
                                                        <label>Street <small style="color:red">*</small></label>
                                                        <input v-model="form.street" type="text" placeholder="What street?">
                                                        <span v-if="errors.street" id="err_street" class="required_data">{{ errors.street }}</span>
                                                    </div>
                                                    <div class="form-item">
                                                        <label>Appt/Suite</label>
                                                        <input v-model="form.appt_suite" type="text" placeholder="What Appt/Suite?">
                                                    </div>
                                                    <div class="form-item">
                                                        <label>City <small style="color:red">*</small></label>
                                                        <input v-model="form.city" type="text" placeholder="What City?">
                                                        <span v-if="errors.city" id="err_city" class="required_data">{{ errors.city }}</span>
                                                    </div>
                                                    <div class="form-item" >
                                                        <label id="postal_label">{{ is_postal ? 'What Province?':'What State?' }} <small style="color:red">*</small></label>
                                                        <input v-model="form.province" type="text" :placeholder="is_postal ? 'What Province?':'What State?'">
                                                        <span v-if="errors.province" id="err_province" class="required_data">{{ errors.province }}</span>
                                                    </div>

                                                    <div class="form-item">
                                                        <label id="province_label">{{ is_postal ? 'Postal Code':'Zip Code' }} <small style="color:red">*</small></label>
                                                        <input v-model="form.postal_code" type="text" :placeholder="is_postal ? 'Postal Code':'Zip Code'">
                                                        <span v-if="errors.postal_code" id="err_postal" class="required_data">{{ errors.postal_code }}</span>
                                                    </div>

                                                    <div class="form-item">
                                                        <label id="country">Country <small style="color:red">*</small></label>
                                                        <select v-model="form.country" @change="changeCountry"  placeholder="Timezone">
                                                            <option selected hidden>Select Country</option>
                                                            <option v-for="(zone, index) in country" :key="index">{{ zone }}</option>
                                                        </select>
                                                    </div>
                                                    <!-- Your address form fields here -->
                                                    <button type="button" @click="prevStep">Previous</button>
                                                    <button type="button" @click="nextStep">Next</button>
                                                </div>
                                                <div v-show="currentStep === 3" class="pi-information">
                                                    <!-- Company Information Section -->
                                                    <h3>Company Information</h3>
                                                    <!-- Your company information form fields here -->
                                                    <div class="form-item">
                                                        <label id="company_name">Company Name <small style="color:red">*</small></label>
                                                        <input v-model="form.location_name"  type="text" placeholder="What's the name of your Company">
                                                        <span v-if="errors.company" id="err_timezone" class="required_data">{{ errors.company }}</span>
                                                    </div>

                                                    <div class="form-item">
                                                        <label id="company_name">Description </label>
                                                        <input  v-model="form.description" type="text" placeholder="Give us a little details about your company.">
                                                    </div>

                                                    <div class="form-item">
                                                        <label id="timezone">Timezone <small style="color:red">*</small></label>

                                                        <select v-model="form.timezone"   >
                                                            <option value="" selected hidden>Select Timezone</option>
                                                            <option v-for="(zone, index) in timezone" :key="index">{{ zone }}</option>
                                                        </select>
                                                        <span v-if="errors.timezone" id="err_timezone" class="required_data">{{ errors.timezone }}</span>
                                                    </div>
                                                    <div class="form-item">
                                                        <input  v-model="form.support_email" type="text" placeholder="Support Email">
                                                        {{form.support_email}}@crm.schedeasy.net
                                                        <span v-if="errors.support_email" id="err_support_email" class="required_data">{{ errors.support_email }}</span>

                                                    </div>
                                                    <button @click="prevStep" type="button">Previous</button>
                                                    <button @click.prevent="nextStep" type="button">Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                        <div v-else class="me-loading">
                                            <h4>Please wait</h4>
                                            <Loading />
                                        </div>
                                    </div>
                                </div>
                            </div>
                    
                    </div>
                   
                </div>
            </div>
        </section>


    </div>
</template>


<script>
import Loading from '@/components/loading.vue'
import moment from 'moment-timezone';
import 'moment-timezone/data/packed/latest.json'; // Import the timezone data
import {mask} from 'vue-the-mask'
import Creator from './liveDemo/livedemo.vue'

    export default {
        components:{Loading, Creator},
        directives: {mask},
        data(){
            return{
                loading:false,
                is_postal:true,
                currentStep: 1,
                confirmPassword:'',
                form:{
                    first_name:"",
                    last_name:"",
                    email:"",
                    contact_number:"",
                    password:"",
                    confirmPassword:"",
                    street:"",
                    appt_suite:"",
                    city:"",
                    province:"",
                    postal_code:"",
                    country:"Canada",
                    location_name:"",
                    description:"",
                    timezone:"",
                    support_email:"",
                },
                errors:{
                    first_name:"",
                    last_name:"",
                    email:"",
                    contact_number:"",
                    password:"",
                    confirmPassword:"",
                    street:"",
                    appt_suite:"",
                    city:"",
                    province:"",
                    postal_code:"",
                    country:"Canada",
                    location_name:"",
                    description:"",
                    timezone:"",
                    support_email:"",
                    con_pass:"",
                },
                country:['United States','Canada'],
                timezone:[
    'America/Adak',
  'America/Anchorage',
  'America/Anguilla',
  'America/Antigua',
  'America/Araguaina',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Catamarca',
  'America/Argentina/ComodRivadavia',
  'America/Argentina/Cordoba',
  'America/Argentina/Jujuy',
  'America/Argentina/La_Rioja',
  'America/Argentina/Mendoza',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Salta',
  'America/Argentina/San_Juan',
  'America/Argentina/San_Luis',
  'America/Argentina/Tucuman',
  'America/Argentina/Ushuaia',
  'America/Aruba',
  'America/Asuncion',
  'America/Atikokan',
  'America/Atka',
  'America/Bahia',
  'America/Bahia_Banderas',
  'America/Barbados',
  'America/Belem',
  'America/Belize',
  'America/Blanc-Sablon',
  'America/Boa_Vista',
  'America/Bogota',
  'America/Boise',
  'America/Buenos_Aires',
  'America/Cambridge_Bay',
  'America/Campo_Grande',
  'America/Cancun',
  'America/Caracas',
  'America/Catamarca',
  'America/Cayenne',
  'America/Cayman',
  'America/Chicago',
  'America/Chihuahua',
  'America/Coral_Harbour',
  'America/Cordoba',
  'America/Costa_Rica',
  'America/Creston',
  'America/Cuiaba',
  'America/Curacao',
  'America/Danmarkshavn',
  'America/Dawson',
  'America/Dawson_Creek',
  'America/Denver',
  'America/Detroit',
  'America/Dominica',
  'America/Edmonton',
  'America/Eirunepe',
  'America/El_Salvador',
  'America/Ensenada',
  'America/Fort_Nelson',
  'America/Fort_Wayne',
  'America/Fortaleza',
  'America/Glace_Bay',
  'America/Godthab',
  'America/Goose_Bay',
  'America/Grand_Turk',
  'America/Grenada',
  'America/Guadeloupe',
  'America/Guatemala',
  'America/Guayaquil',
  'America/Guyana',
  'America/Halifax',
  'America/Havana',
  'America/Hermosillo',
  'America/Indiana/Indianapolis',
  'America/Indiana/Knox',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Tell_City',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indianapolis',
  'America/Inuvik',
  'America/Iqaluit',
  'America/Jamaica',
  'America/Jujuy',
  'America/Juneau',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Knox_IN',
  'America/Kralendijk',
  'America/La_Paz',
  'America/Lima',
  'America/Los_Angeles',
  'America/Louisville',
  'America/Lower_Princes',
  'America/Maceio',
  'America/Managua',
  'America/Manaus',
  'America/Marigot',
  'America/Martinique',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Mendoza',
  'America/Menominee',
  'America/Merida',
  'America/Metlakatla',
  'America/Mexico_City',
  'America/Miquelon',
  'America/Moncton',
  'America/Monterrey',
  'America/Montevideo',
  'America/Montreal',
  'America/Montserrat',
  'America/Nassau',
  'America/New_York',
  'America/Nipigon',
  'America/Nome',
  'America/Noronha',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Ojinaga',
  'America/Panama',
  'America/Pangnirtung',
  'America/Paramaribo',
  'America/Phoenix',
  'America/Port-au-Prince',
  'America/Port_of_Spain',
  'America/Porto_Acre',
  'America/Porto_Velho',
  'America/Puerto_Rico',
  'America/Punta_Arenas',
  'America/Rainy_River',
  'America/Rankin_Inlet',
  'America/Recife',
  'America/Regina',
  'America/Resolute',
  'America/Rio_Branco',
  'America/Rosario',
  'America/Santa_Isabel',
  'America/Santarem',
  'America/Santiago',
  'America/Santo_Domingo',
  'America/Sao_Paulo',
  'America/Scoresbysund',
  'America/Shiprock',
  'America/Sitka',
  'America/St_Barthelemy',
  'America/St_Johns',
  'America/St_Kitts',
  'America/St_Lucia',
  'America/St_Thomas',
  'America/St_Vincent',
  'America/Swift_Current',
  'America/Tegucigalpa',
  'America/Thule',
  'America/Thunder_Bay',
  'America/Tijuana',
  'America/Toronto',
  'America/Tortola',
  'America/Vancouver',
  'America/Virgin',
  'America/Whitehorse',
  'America/Winnipeg',
  'America/Yakutat',
  'America/Yellowknife',
]
            }
        },

        mounted(){
           
        },
      
        methods: {
            nextStep() {
                console.log("steps takens",this.currentStep)

                if (this.validateFormStep(this.currentStep)) {
                        if (this.currentStep == 3) {
                            console.log("steps takens inside",this.currentStep)
                            this.sendForm()
                        }else{
                            this.currentStep++
                        }

                }else {
                    this.showToast("Something went wrong");
                }
              
                
            },
            changeCountry(){

                var flag = false
                if(this.form.country == 'United States'){
                    flag = false
                }else{
                    flag = true
                }
                this.is_postal = flag
            },
            prevStep() {
                if (this.currentStep > 1) {
                    this.currentStep--;
                }
            },
            validateFormStep(step) {
                console.log("wea",step)
                switch (step) {
                    case 1:
                    var flag = 0;
                    var formProperties = Object.keys(this.form).slice(0, 6);
                    var newForm = {};

                    for (const property of formProperties) {
                        newForm[property] = this.form[property];
                    }
                    console.log("sss", newForm);
                    for (var data in newForm) {
                        var message = ''

                        // console.log("lool",data);
                        if (!this.form[data]) {
                            flag++;

                            if (data == "first_name") {
                                message = "Oops we really need to know your first name"
                            }else if (data == "last_name") {
                                message = "You forgot your last name"
                            }else if (data == "email") {
                                message = "Forgetting something?"
                            }else if (data == "contact_number") {
                                message = "Well, How can we contact you then?"
                            }else if(data == "password"){
                                message = "You can't leave this blank. Its like leaving your house open"
                            }else if(data == "confirmPassword"){
                                message = "Sometimes you gotta check twice. Re enter your password"
                            }else{
                                message = ''
                            }
                            this.errors[data] = message
                            console.log("ssss",this.errors);
                        }else{
                            if (data == "email") {
                                if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.form[data])) {
                                    message = "Oops it seems the email is invalid. Kindly double check."
                                    flag++;

                                }else{
                                    message = ''
                                }
                                this.errors[data] = message

                            }else if (data == "password") {
                                if (this.form.password.length < 10) {
                                    message = "Size matters! Enter a password with at least 10 characters";
                                    flag++;
                                } else if (!/^(?=.*[A-Za-z])(?=.*\d)(?!.*\s)/.test(this.form[data])) {
                                    message = "We need you to mix some numbers and letters, and no spaces are allowed";
                                    flag++;
                                } else if (this.form.password !== this.form.confirmPassword) {
                                    message = "Oops, passwords do not match";
                                    flag++;
                                } else {
                                    message = '';
                                }
                                this.errors[data] = message

                            }else{
                                this.errors[data] = message

                            }
                        }
                    }                   

                    if (flag != 0) {
                        return false
                    }else{
                        return true
                    }
                    break;

                    case 2:
                        var flag = 0;
                        var formProperties = Object.keys(this.form).slice(6, 12);
                        var newForm = {};
                        for (const property of formProperties) {
                            newForm[property] = this.form[property];
                        }

                        for (var data in newForm) {
                            var message = ''
                            if (data != 'appt_suite') {
                                if (!this.form[data]) {
                                    flag++;
                                    if (data == "street") {
                                        message = "Oops we really need to know your first name"
                                    }else if (data == "city") {
                                        message = "You forgot your last name"
                                    }else if (data == "province") {
                                        if (this.form.country == "United States") {
                                            message = "What is your state?"
                                        }else{
                                            message = "What is your province?"
                                        }
                                    }else if (data == "postal_code") {
                                        if (this.form.country == "United States") {
                                            message = "What is your zip code?"
                                        }else{
                                            message = "What is your postal code?"
                                        }
                                    }
                                    this.errors[data] = message
                                }else{
                                    this.errors[data] = message
                                    
                                }
                            }
                        }
                        console.log("recorn", flag)
                        if (flag != 0) {
                            return false
                        }else{
                            return true
                        }                        
                        break;
                    case 3:
                        var flag = 0;
                        var formProperties = Object.keys(this.form).slice(12, 17);
                        var newForm = {};
                        for (const property of formProperties) {
                            newForm[property] = this.form[property];
                        }
                       
                        for (var data in newForm) {
                            var message = ''
                            if (data != 'description') {
                                if (!this.form[data]) {
                                    flag++;
                                    if (data == "location_name") {
                                        message = "What is the name of this business"
                                    }else if (data == "support_email") {
                                        message = "Create a email with our email extension"
                                    }else if (data == "timezone") {
                                        message = "We need the timezone so that we can set things up for you"
                                    }
                                    this.errors[data] = message
                                }else{
                                    if (data == "support_email") {
                                        if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.form[data]+'@crm.schedeasy.net')) {
                                        message = "Oops it seems the email is invalid. Kindly double check."
                                        flag++;

                                    }else{
                                        message = ''
                                    }
                                    }
                                   
                                    this.errors[data] = message
                                    
                                }
                            }
                        }
                        if (flag != 0) {
                            return false
                        }else{
                            return true
                        }                        
                        break;
                   
                    default:
                        return false;
                }
            },
            sendForm(){
                console.log("rakooo", this.form, this.form.timezone,this.form.postal_code);
                this.loading = true

                var start_date = moment().tz(this.form.timezone).format('MM-DD-YYYY h:mmA')

                var apit_sandbox="https://be-api-sandbox.schedeasy.net/"
                var apit_prod="https://se-api-production.schedeasy.net/"

                var subdomain_sand = "crmsbx"
                var subdomain_prod = "crm"

                var key_sand = "ENTERPRISE_Michal_Lewandowski6452b0c9584116.90316953"
                var key_prod = "ENTERPRISE_Schedeasy_Developers6477bff35d43c3.48780293"

                this.form.user_type=  'location_owner'
                this.form.enterprise_key=  key_prod
                this.form.location_id=  ""
                this.form.created_by=  ""
                this.form.creator_name=  "System"
                this.form.subdomain= subdomain_prod  
                this.form.from_page= true
                this.form.start_date= start_date
                this.form.support_email = this.form.support_email + "@crm.schedeasy.net"
                this.form.end_date= moment().add(7, 'days').format('MM-DD-YYYY h:mmA')
                this.form['copyInfo']=null
                this.form['id']=''
                var form_datas = new FormData();
                // axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';


                form_datas.append("package_entry", "create");
                form_datas.append("package_api_key", "8F479DF8805C43C7723215B0400679F8F7C7417A");
                form_datas.append("package_data",JSON.stringify(this.form));
                
                axios.post(apit_prod+'api/package_location',form_datas,
                ).then(({data:{success, content}}) => {
                    if (success) {
                        var extra = {
                            email:this.form.email,
                            password:this.form.password,
                            logged_in_time:"03/02/2023", // static
                            enterprise_key: key_prod,
                            location_id: "",
                            created_by: "",
                            creator_name: "System",
                            subdomain:subdomain_prod,    
                        }
                        var form_datas1 = new FormData();

                        form_datas1.append("package_entry", "login");
                        form_datas1.append("package_api_key", "8F479DF8805C43C7723215B0400679F8F7C7417A");
                        form_datas1.append("package_data",JSON.stringify(extra));
                        axios.post(apit_prod+"api/package_auth",form_datas1).then(({data:{success, content}}) => {
                            console.log("mosbik", content);
                            window.location.href = `https://${content[0].enterprise_url}/redirect/${content[0].user_key}`;
                        })
                    }else{
                        this.loading = false

                    }
                    this.loading = false
                })
                console.log("rakooo", this.form);
            },
          
            showToast(message) {
                this.$toasted.show(message, {
                    duration: 3000, // Duration in milliseconds
                    position: 'top-center', // Position on the screen
                    type: 'error' // Type of toast (info, success, error, etc.)
                })
                }
        }, 
    }
</script>

<style lang="scss" scoped>
  .subscribe-section {
    position: relative;
    padding-bottom: 50px;
    padding-top: 70px; 
    background-position: bottom left;
    background-repeat: no-repeat;
}
  /* Form Container Styles */
  #image_block_14 .image-box .bg-layer {
    position: relative;
    width: 100%;
    height: 902px;
    top: unset;
    left: unset;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}
  .form-container {
    margin: 0 auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    background: #fff;

  }
  
  .form-header {
    text-align: center;
  }
  
  .form-content {
    display: flex;
    flex-direction: column;
    .pi-information{
        padding: 20px;
    }
  }
  
  h2,
  h3 {
    margin: 10px 0;
  }
  
  button {
    margin: 10px 10px 10px;
    font-size: 18px;
    padding: 5px 10px;
    background-color: #4527a4;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  

  button:hover {
    background-color: #0056b3;
  }

  .trial-content{
    padding: 6% 0% 5%;;
  }

  .me-loading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: #fff;
    width: 220px;
    border-radius: 20px;
    h3{
        color:#4527a4
    }
  }
  
  .form-item{
    margin: 0 0 10px;
    input{
        width: 100%;
        padding: 0px 0px 5px;
        border-bottom: 2px solid #4527a4;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    input{
        font-size: 18px;
    }
  }

  .required_data{
    color: red;
  }

  @media only screen and (max-width: 991px){
    #app{
        .form-container{
            min-width: none;
        }
    }
}
</style>