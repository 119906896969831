<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Unify Your Franchise Network</h1>
                    </div>
                    <div class="text">
                        <h2>Consistency, Compliance, and Growth</h2>
                        <p>For businesses operating under a franchise model, our software's "Franchise System" feature is the ultimate tool for ensuring consistency, compliance, and efficient management across all franchise units.</p>
                    </div>
                    <div data-v-1e9dea97="" style="width: 100%;"><video data-v-1e9dea97="" autoplay :src="filteredFeatures[0].video_path" controls="controls" style="width: 100%;"></video></div>

                </div>
                <section class="recruitment-section elements sec-pad-two centred">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Unified Branding</a></h3>
                                        <div class="text">Maintain a unified brand identity across all franchise units. With this feature, you can enforce consistent branding guidelines, ensuring that every unit adheres to your brand's image and message.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Centralized Control</a></h3>
                                        <div class="text">Enjoy centralized control and oversight of all franchise units. Monitor individual unit performance, access financial data, and enforce operational standards from a single dashboard.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Compliance Tracking</a></h3>
                                        <div class="text">Ensure that all franchise units adhere to regulatory requirements and brand standards. Use this feature to track compliance with established protocols, policies, and procedures.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Franchisee Support</a></h3>
                                        <div class="text">Provide valuable support to your franchisees. The Franchise System feature facilitates communication, training, and resource sharing, fostering a sense of community and collaboration among franchise owners.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Performance Analytics</a></h3>
                                        <div class="text">Access performance analytics for each franchise unit and the franchise as a whole. Utilize data-driven insights to identify top-performing units and areas that require improvement.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <figure class="image-box wow fadeInRight" style="width: 455px;margin-top: 58px;" data-wow-delay="300ms" data-wow-duration="1500ms"><img src="/images/schedeasy/features/images/location.png" alt=""></figure>

                            </div>
                        </div>
                    </div>
                </section>
                <div class="bottom-content">
                    <div class="text">
                        <p>The Franchise System feature is your key to a successful and cohesive franchise operation. It empowers you to maintain brand consistency, support franchisees effectively, and drive growth across your franchise network.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.new-image{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
