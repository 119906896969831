<template>
    <div>
      <div class="service-details-content">
        <div class="inner-box">
          <div class="top-content">
            <div class="sec-title">
              <h1>Revolutionize Restoration Services with Schedeasy</h1>
            </div>
            <div class="text">
              <h2>Effortlessly Restore, Elevate Customer Satisfaction</h2>
              <p>
                Restoration should be seamless. Revolutionize your restoration services with Schedeasy, effortlessly restoring properties and elevating customer satisfaction.
              </p>
            </div>
            <figure class="image-box">
              <img :src="'/images/schedeasy/industry/details/' + filteredServices[0].wide_image" alt="">
            </figure>
            <div>
              <h1>Revolutionizing Restoration Services with Schedeasy</h1>
              <div class="text">
                <h2>Enhancing Efficiency and Elevating Restoration Excellence</h2>
                <p>
                  Operating a restoration services business presents a range of challenges – scheduling emergency calls, coordinating restoration teams, and ensuring swift and meticulous restoration processes. These intricacies often lead to scheduling conflicts, communication gaps, and client concerns about the restoration outcome. That's where Schedeasy steps in as the transformative solution for restoration service providers. With its intelligent scheduling for restoration assignments, real-time communication tools for team coordination, and streamlined client management, Schedeasy is custom-tailored to the restoration industry. It's more than just software; it's the cornerstone of efficient operations and exceptional restoration excellence. Amidst the complexities of restoration services, Schedeasy emerges as the ultimate answer, propelling businesses to new heights of success while ensuring rapid and comprehensive restoration for clients in their times of need.
                </p>
              </div>
            </div>
          </div>
          <div class="two-column">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                <div class="content-box">
                  <div>
                    <h3>Route Optimization</h3>
                    <ul>
                      <li>
                        Optimize the routes for your restoration service crews with the Route Optimization feature.
                      </li>
                      <li>
                        Efficiently plan and assign restoration projects based on job locations, crew availability, and priority.
                      </li>
                      <li>
                        Minimize travel time, reduce fuel costs, and improve overall productivity by ensuring that your crews follow optimized routes.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Communication Funnel</h3>
                    <ul>
                      <li>
                        Centralize all communication related to restoration projects in one location with the Communication Funnel feature.
                      </li>
                      <li>
                        Convert inquiries into leads or customers seamlessly by efficiently managing communication channels.
                      </li>
                      <li>
                        Assign emails for follow-up, track communication history, and improve customer relationship management.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Dashboard</h3>
                    <ul>
                      <li>
                        Get a quick overview of your restoration service business's performance with the Dashboard feature.
                      </li>
                      <li>
                        Access an admin section for sending messages to your team and clients.
                      </li>
                      <li>
                        Add notes for all desktop users to stay updated on important information and project details.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms">
                  <img :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt="">
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {};
    },
    mounted() {}
  };
  </script>
  