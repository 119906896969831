<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Streamline Bulk Invoicing</h1>
                    </div>
                    <div class="text">
                        <h2>Efficient Handling of Multiple Invoices</h2>
                        <p>For businesses that deal with multiple clients and projects simultaneously, our software's "Batch Invoicing" feature is a game-changer, allowing you to streamline invoicing for multiple clients effortlessly.</p>
                    </div>
                    <div data-v-1e9dea97="" style="width: 100%;"><video data-v-1e9dea97="" autoplay :src="filteredFeatures[0].video_path" controls="controls" style="width: 100%;"></video></div>

                </div>
                <section class="recruitment-section elements sec-pad-two centred">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Efficient Grouping</a></h3>
                                        <div class="text">Batch Invoicing simplifies the process of invoicing multiple clients for similar services or projects. You can group clients or projects together, saving time and effort.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Bulk Invoice Generation</a></h3>
                                        <div class="text">Generate multiple invoices with a single click. This feature automatically populates invoice details, including service descriptions, costs, and client information, based on the grouping you've defined.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Customization Flexibility</a></h3>
                                        <div class="text">While automating the process, Batch Invoicing still allows for customization. You can modify specific details on each invoice if necessary, ensuring each client receives an accurate and personalized invoice.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Time and Cost Savings</a></h3>
                                        <div class="text">By eliminating the need to create invoices one by one, Batch Invoicing saves you significant time and reduces the risk of errors, resulting in more efficient operations and reduced administrative overhead.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Improved Client Experience</a></h3>
                                        <div class="text">Deliver invoices promptly and accurately to multiple clients, enhancing their experience with your business and increasing the likelihood of on-time payments.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <figure class="image-box wow fadeInRight" style="width: 455px;margin-top: 58px;" data-wow-delay="300ms" data-wow-duration="1500ms"><img src="/images/schedeasy/features/images/batch_invoice.png" alt=""></figure>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="bottom-content">
                    <div class="text">
                        <p>Batch Invoicing is a powerful tool for businesses that manage numerous clients and projects simultaneously. It streamlines your invoicing workflow, saves time, reduces errors, and ultimately contributes to improved financial management.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.new-image{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
