<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Revamp Your Carpet Cleaning Business with Schedeasy</h1>
                    </div>
                    <div class="text">
                        <h2>Simplify Operations, Enhance Customer Satisfaction</h2>
                        <p>
                            Are you weary of juggling carpet cleaning appointments, scheduling technicians, and addressing customer inquiries? Say hello to Schedeasy for Carpet Cleaning – your ultimate solution for seamless field service management.
                        </p>
                    </div>
                    <figure class="image-box"><img :src="'/images/schedeasy/industry/details/'+filteredServices[0].wide_image" alt=""></figure>
                    <div>
                        <h1>Transforming Carpet Cleaning Operations with Schedeasy</h1>
                        <div class="text">
                            <h2>Simplifying Processes and Enhancing Client Experiences</h2>
                            <p>
                                Running a carpet cleaning business comes with its share of challenges – managing appointments, coordinating teams, and delivering exceptional service. These complexities often result in scheduling conflicts, miscommunications, and customer dissatisfaction. That's where Schedeasy steps in as the game-changing solution. With its intuitive scheduling algorithms, real-time communication tools, and streamlined customer management, Schedeasy is tailor-made for the carpet cleaning industry. It's not just software; it's the cornerstone of efficient operations and superior client experiences. Amidst the intricacies of the industry, Schedeasy stands as the definitive answer, propelling carpet cleaning businesses to new heights of success.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="two-column">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div class="content-box">
                                <div>
                                    <h3>Invoice Details Customization</h3>
                                    <ul>
                                        <li>
                                            Tailor invoices to match your carpet cleaning business's branding.
                                        </li>
                                        <li>
                                            Add promotional images to highlight your cleaning services.
                                        </li>
                                        <li>
                                            Personalize invoice footers and include special notes or discounts.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Multi-Location Management</h3>
                                    <ul>
                                        <li>
                                            Effortlessly manage multiple carpet cleaning locations, whether you have branch offices, franchises, or multiple business sites.
                                        </li>
                                        <li>
                                            Centralize and oversee operations from a single platform, allowing you to monitor each location's performance and access relevant data.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Staff Management</h3>
                                    <ul>
                                        <li>
                                            Simplify staff management by keeping track of staff details, including contact information and schedules.
                                        </li>
                                        <li>
                                            Assign carpet cleaning jobs to staff members and track their availability.
                                        </li>
                                        <li>
                                            Easily manage staff permissions and access levels, streamlining communication within your team.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                            <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms"><img  :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt=""></figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{

            }
        },
        mounted(){

        }
    }
</script>
