<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Organized Communication Hub</h1>
                    </div>
                    <div class="text">
                        <h2>Enhanced Internal and External Communication</h2>
                        <p>Effective communication is the lifeblood of field service management, and our software's "Communication Funnel" feature ensures that all communication, both internal and external, is organized and optimized.</p>
                    </div>
                    <div data-v-1e9dea97="" style="width: 100%;"><video data-v-1e9dea97="" autoplay :src="filteredFeatures[0].video_path" controls="controls" style="width: 100%;"></video></div>

                </div>
                <section class="recruitment-section elements sec-pad-two centred">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Centralized Communication Hub</a></h3>
                                        <div class="text">Communication Funnel provides a centralized platform for all communication channels, including emails, messages, and notifications. It ensures that all communication is logged and easily accessible.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Automated Notifications</a></h3>
                                        <div class="text">Streamline communication with automated notifications. Set up notifications for job updates, appointment reminders, and service confirmations, improving customer engagement and team coordination.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Message Tracking</a></h3>
                                        <div class="text">Keep track of all messages and interactions with clients and team members. The feature allows you to view message histories, ensuring that no communication is overlooked.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Customizable Communication Templates</a></h3>
                                        <div class="text">Create customizable templates for common messages. This feature simplifies routine communication, such as appointment confirmations, by allowing you to use pre-defined templates that can be easily personalized.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Real-Time Alerts</a></h3>
                                        <div class="text">Stay informed with real-time alerts for critical messages. Communication Funnel can send alerts for urgent messages, ensuring that you never miss an important update.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <figure class="image-box wow fadeInRight" style="width: 455px;margin-top: 58px;" data-wow-delay="300ms" data-wow-duration="1500ms"><img src="/images/schedeasy/features/images/coms.png" alt=""></figure>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="bottom-content">
                    <div class="text">
                        <p>The Communication Funnel feature is the key to efficient, organized, and responsive communication in the field service industry. It enhances customer satisfaction, streamlines team collaboration, and helps you stay on top of your communication efforts.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.new-image{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
