<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Elevate Precision with Job Details</h1>
                    </div>
                    <div class="text">
                        <h2>Comprehensive Job Information and Scheduling</h2>
                        <p>In the world of field service management, precision is paramount. That's where the "Job Details" feature of our software comes into play, ensuring that every facet of your job is meticulously managed.</p>
                    </div>
                    <div data-v-1e9dea97="" style="width: 100%;"><video data-v-1e9dea97="" autoplay :src="filteredFeatures[0].video_path" controls="controls" style="width: 100%;"></video></div>
                </div>
                <section class="recruitment-section elements sec-pad-two centred">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Comprehensive Job Information</a></h3>
                                        <div class="text">This feature allows you to input and store all vital job details in one central location. From customer contact information to specific job requirements, you can keep track of everything essential for a successful job.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Streamlined Scheduling</a></h3>
                                        <div class="text">Job Details makes scheduling a breeze. Easily assign tasks to your field teams, set deadlines, and allocate resources efficiently. Plus, it provides real-time updates, so you know when a job is scheduled, in progress, or completed.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Enhanced Communication</a></h3>
                                        <div class="text">Keep everyone on the same page with the Job Details feature. Share important instructions, job-specific notes, and attachments, ensuring that your team is well-informed and equipped to deliver exceptional service.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Accurate Billing</a></h3>
                                        <div class="text">Precision matters in invoicing. With this feature, you can ensure that every minute spent and resource used on a job is accounted for, helping you generate accurate invoices and improve your bottom line.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <figure class="image-box wow fadeInRight" style="width: 455px;margin-top: 58px;" data-wow-delay="300ms" data-wow-duration="1500ms"><img src="/images/schedeasy/features/images/job_details.png" alt=""></figure>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="bottom-content">
                    <div class="text">
                        <p>In the fast-paced world of field service management, managing job details seamlessly can make all the difference. Our software's Job Details feature empowers you to stay organized, communicate effectively, and deliver outstanding service, ensuring your business thrives.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.new-image{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
