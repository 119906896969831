<template>
    <div>
        <!-- page-title -->
        <!-- <section class="page-title style-two" style="background: url(/images/schedeasy/banner_industry.png);background-repeat: no-repeat;
    background-size: cover!important;
    background-position: center -62px;
">
            <div class="container">
                <div class="content-box clearfix">
                    <div class="title-box pull-left">
                        <h1>Industries We Support</h1>
                        <p>Reach out to the world’s most reliable IT services.</p>
                    </div>
                    <ul class="bread-crumb pull-right">
                        <li><a href="/">Home</a></li>
                        <li>All Industries</li>
                    </ul>
                </div>
            </div>
        </section> -->
        <!-- page-title end -->

        <section class="page-title" style="background-image: url(/images/background/pagetitle-bg.png);">
            <div class="anim-icons">
                <div class="icon icon-1"><img src="/images/icons/anim-icon-17.png" alt=""></div>
                <div class="icon icon-2 rotate-me"><img src="/images/icons/anim-icon-18.png" alt=""></div>
                <div class="icon icon-3 rotate-me"><img src="/images/icons/anim-icon-19.png" alt=""></div>
                <div class="icon icon-4"></div>
            </div>
            <div class="container">
                <div class="content-box clearfix">
                    <div class="title-box pull-left">
                        <h1>Features</h1>
                        <p>Unlocking Efficiency and Productivity with Our Scheduling Solutions</p>
                    </div>
                   
                </div>
            </div>
        </section>


            <!-- designe-process -->
    <section class="designe-process">
        <div class="container"> 
            <div class="sec-title center"><h2>Elevate Your Experience <br />With Cutting-Edge Solutions</h2></div>
            <div class="row">
                <div v-for="(item, index) in features" :key="index" class="col-lg-4 col-md-6 col-sm-12 single-column clicker" @click="routeChange(item.route_name)">
                    <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                        <div class="inner-box">
                            <div class="left-layer"></div>
                            <div class="right-layer"></div>
                            <h4><a href="#">{{ item.name }}</a></h4>
                            <div class="text">{{ item.description }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- designe-precess end -->
    </div>
</template>

<script>
    export default {
        data(){
            return{

            }
        },
        methods:{
            routeChange(route){
                this.$router.push({name:route})
            }
        }
    }
</script>

<style lang="scss" scoped>
.clicker{
    cursor:pointer,
}
.page-title.style-two {
    position: relative;
    padding: 260px 0px 150px 0px;
}

.industry_image{
    margin-bottom: 15px;
    width: 200px;
    position: relative;
}
.page-title{
    padding: 409px 0px 260px 0px;
}
</style>