<template>
    <div>
      <div class="service-details-content">
        <div class="inner-box">
          <div class="top-content">
            <div class="sec-title">
              <h1>Transform Your Mechanical Contracting with Schedeasy</h1>
            </div>
            <div class="text">
              <h2>Streamline Operations, Amplify Customer Satisfaction</h2>
              <p>
                Navigating complex mechanical projects? Transform your mechanical contracting business with Schedeasy, streamlining operations, and amplifying customer satisfaction.
              </p>
            </div>
            <figure class="image-box">
              <img :src="'/images/schedeasy/industry/details/' + filteredServices[0].wide_image" alt="">
            </figure>
            <div>
              <h1>Revolutionizing Mechanical Contracting with Schedeasy</h1>
              <div class="text">
                <h2>Enhancing Efficiency and Elevating Mechanical Excellence</h2>
                <p>
                  Managing a mechanical contracting services business entails a multitude of challenges – scheduling complex projects, coordinating specialized teams, and ensuring precise mechanical systems. These intricacies often lead to project delays, communication gaps, and client concerns about system reliability. That's where Schedeasy steps in as the transformative solution for mechanical contracting service providers. With its intelligent project scheduling for mechanical installations, real-time communication tools for team coordination, and streamlined client management, Schedeasy is custom-tailored to the mechanical contracting industry. It's more than just software; it's the foundation of efficient operations and exceptional mechanical systems. Amidst the complexities of mechanical contracting services, Schedeasy emerges as the ultimate answer, propelling businesses to new heights of success while ensuring reliable and efficient mechanical solutions for clients.
                </p>
              </div>
            </div>
          </div>
          <div class="two-column">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                <div class="content-box">
                  <div>
                    <h3>Job Details</h3>
                    <ul>
                      <li>
                        Manage and track mechanical service projects efficiently with the Job Details feature.
                      </li>
                      <li>
                        Customize job statuses to categorize tasks effectively, such as "Scheduled," "In Progress," or "Completed."
                      </li>
                      <li>
                        Send timely reminders to clients and staff with timestamps to ensure smooth project execution.
                      </li>
                      <li>
                        Capture client signatures within the system, eliminating paperwork and enhancing document management.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Invoice Management</h3>
                    <ul>
                      <li>
                        Efficiently manage invoices for your mechanical services business with the Invoice Management feature.
                      </li>
                      <li>
                        View and organize jobs based on dates, categories, and custom details to streamline billing.
                      </li>
                      <li>
                        Utilize pre-set date filters and category filters for quick access to job information.
                      </li>
                      <li>
                        Perform powerful searches, change job statuses, and perform mass updates or deletions to simplify invoice tracking.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Batch Invoicing</h3>
                    <ul>
                      <li>
                        Streamline your invoicing process with the Batch Invoicing feature for mechanical services.
                      </li>
                      <li>
                        Consolidate multiple mechanical service jobs into a single, comprehensive invoice for clients' convenience.
                      </li>
                      <li>
                        Access detailed project information directly from invoices, making it easy to review and manage billing for multiple projects simultaneously.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms">
                  <img :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt="">
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {};
    },
    mounted() {}
  };
  </script>
  