<template>
    <div>
      <div class="service-details-content">
        <div class="inner-box">
          <div class="top-content">
            <div class="sec-title">
              <h1>Revolutionize Plumbing Services with Schedeasy</h1>
            </div>
            <div class="text">
              <h2>Simplify Plumbing Solutions, Elevate Customer Satisfaction</h2>
              <p>
                Plumbing issues causing headaches? Revolutionize your plumbing services with Schedeasy, simplifying plumbing solutions and elevating customer satisfaction.
              </p>
            </div>
            <figure class="image-box">
              <img :src="'/images/schedeasy/industry/details/' + filteredServices[0].wide_image" alt="">
            </figure>
            <div>
              <h1>Revolutionizing Plumbing Services with Schedeasy</h1>
              <div class="text">
                <h2>Enhancing Efficiency and Elevating Plumbing Excellence</h2>
                <p>
                  Operating a plumbing services business entails a multitude of challenges – scheduling emergency calls, coordinating plumbing teams, and ensuring flawless plumbing solutions. These intricacies often lead to scheduling conflicts, communication gaps, and client concerns about the integrity of their plumbing systems. That's where Schedeasy steps in as the transformative solution for plumbing service providers. With its intelligent scheduling for plumbing assignments, real-time communication tools for team coordination, and streamlined client management, Schedeasy is custom-tailored to the plumbing industry. It's more than just software; it's the cornerstone of efficient operations and exceptional plumbing excellence. Amidst the complexities of plumbing services, Schedeasy emerges as the ultimate answer, propelling businesses to new heights of success while ensuring reliable and efficient plumbing solutions for clients.
                </p>
              </div>
            </div>
          </div>
          <div class="two-column">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                <div class="content-box">
                  <div>
                    <h3>Customer Management</h3>
                    <ul>
                      <li>
                        Efficiently manage your plumbing service customers with the Customer Management feature.
                      </li>
                      <li>
                        Keep detailed records of customer information, including contact details, service history, and preferences.
                      </li>
                      <li>
                        Easily add new customers and update existing records to ensure accurate and personalized service.
                      </li>
                      <li>
                        Access customer profiles for insights that help you provide exceptional service and build lasting relationships.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Advanced Calendar Features</h3>
                    <ul>
                      <li>
                        Enhance your scheduling capabilities for plumbing service appointments with advanced calendar features.
                      </li>
                      <li>
                        Utilize intuitive drag-and-drop functionality to schedule and reschedule appointments effortlessly.
                      </li>
                      <li>
                        Assign jobs to specific dates and times, ensuring optimal resource allocation and efficient service delivery.
                      </li>
                      <li>
                        Visualize your calendar for better organization and time management.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Reporting</h3>
                    <ul>
                      <li>
                        Gain valuable insights into your plumbing service business with the Reporting feature.
                      </li>
                      <li>
                        Choose from pre-set or custom reports to access data on job performance, revenue, customer satisfaction, and more.
                      </li>
                      <li>
                        Use these insights to make informed decisions, identify areas for improvement, and optimize your plumbing services for greater efficiency and profitability.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms">
                  <img :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt="">
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {};
    },
    mounted() {}
  };
  </script>
  