<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Elevate Your Chimney Sweeping Business with Schedeasy</h1>
                    </div>
                    <div class="text">
                        <h2>Simplify Operations, Enhance Customer Satisfaction</h2>
                        <p>
                            Tired of the constant juggling of chimney sweeping appointments, technician scheduling, and customer queries? Say hello to Schedeasy for Chimney Sweeping – your ultimate solution for seamless field service management.
                        </p>
                    </div>
                    <figure class="image-box"><img :src="'/images/schedeasy/industry/details/'+filteredServices[0].wide_image" alt=""></figure>
                    <div>
                        <h1>Revolutionizing Chimney Sweeping Operations with Schedeasy</h1>
                        <div class="text">
                            <h2>Enhancing Efficiency and Elevating Customer Contentment</h2>
                            <p>
                                Operating a chimney sweeping business comes with its distinct set of challenges – managing appointments, coordinating sweeps, and ensuring client safety. These intricacies often lead to scheduling conflicts, communication gaps, and customer dissatisfaction. That's where Schedeasy steps in as the transformative solution. With its intuitive appointment scheduling, real-time communication tools, and comprehensive client management, Schedeasy is tailored to the chimney sweeping industry. It's more than just software; it's the cornerstone of efficient operations and exceptional client experiences. In the intricate world of chimney sweeping, Schedeasy emerges as the ultimate solution, propelling businesses to new heights of success.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="two-column">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div class="content-box">
                                <div>
                                    <h3>Real-Time Job Details</h3>
                                    <ul>
                                        <li>
                                            Effortlessly manage and track chimney sweeping appointments in real time.
                                        </li>
                                        <li>
                                            Customize job statuses (e.g., "Scheduled," "In Progress," "Completed") to effectively categorize tasks.
                                        </li>
                                        <li>
                                            Send automated reminders to clients and staff with timestamps for punctuality.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Advanced Calendar Features</h3>
                                    <ul>
                                        <li>
                                            Enhance scheduling capabilities with intuitive drag-and-drop functionality.
                                        </li>
                                        <li>
                                            Schedule and reschedule chimney sweeping appointments and tasks effortlessly.
                                        </li>
                                        <li>
                                            Assign jobs to specific dates and times, optimizing workflow and time management.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Customer Management</h3>
                                    <ul>
                                        <li>
                                            Efficiently manage customer information, including contact details, service history, and appointment preferences.
                                        </li>
                                        <li>
                                            Easily add new customers and update existing records for a seamless customer experience.
                                        </li>
                                        <li>
                                            Access customer profiles to provide personalized chimney sweeping services and build lasting client relationships.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                            <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms"><img  :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt=""></figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{

            }
        },
        mounted(){

        }
    }
</script>
