import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/main.vue'
import Pricing from '../views/pricing_page.vue'

import Industry from '../views/industry.vue'
import IndustryLayout from '../views/industry_details_layout.vue'
import IndustryAppliance from '../views/industry/appliance.vue'
import IndustryChimney from '../views/industry/chimney.vue'
import IndustryCleaner from '../views/industry/cleaner.vue'
import IndustryCommercial from '../views/industry/commercial.vue'
import IndustryDrywall from '../views/industry/drywall.vue'
import IndustryGarage from '../views/industry/garage.vue'
import IndustryLawnCare from '../views/industry/lawncare.vue'
import IndustryResidential from '../views/industry/residential.vue'
import IndustryRoofer from '../views/industry/roofer.vue'
import IndustryLocksmith from '../views/industry/locksmith.vue'
import IndustryLandScaping from '../views/industry/landscape.vue'
import IndustryCarpenter from '../views/industry/carpenter.vue'
import IndustryCarpetCleaning from '../views/industry/carpet_cleaning.vue'
import IndustryConstruction from '../views/industry/construction.vue'
import IndustryDogwalker  from '../views/industry/dog_walker.vue'

import IndustryFence from '../views/industry/fence.vue';
import IndustryGeneralContractor from '../views/industry/general_contractor.vue';
import IndustryHandyman from '../views/industry/handyman.vue';
import IndustryHavac from '../views/industry/havac.vue';
import IndustryIrragation from '../views/industry/irragation.vue';
import IndustryJunkRemoval from '../views/industry/junk_removal.vue';
import IndustryMechContractor from '../views/industry/mechanical_contractor.vue';
import IndustryPlumber from '../views/industry/plumber.vue';
import IndustryPressureWashing from '../views/industry/pressure_washing.vue';
import IndustrySnowRemoval from '../views/industry/snow_removal.vue';
import IndustryTreeCare from '../views/industry/tree_care.vue';
import IndustryWindow from '../views/industry/window.vue';

import IndustryElectrical from '../views/industry/electrical.vue';
import IndustryFlooring from '../views/industry/flooring.vue';

import IndustryInstallationService from '../views/industry/installationService.vue';
import IndustryPaintingService from '../views/industry/painting_service.vue';
import IndustryPropertyMaintenance from '../views/industry/property_maintenance.vue';
import IndustryRemodeling from '../views/industry/remodeling.vue';
import IndustryRestoration from '../views/industry/restoration.vue';
import IndustryJanitorial from '../views/industry/janitorial.vue';
import IndustryElevator from '../views/industry/elevator.vue';


import OnBoardingForm from '../views/onBoardingForm.vue'

import FeatLayout from '../views/feature_details_layout.vue'

import FeatReporting from '../views/features/featReporting.vue'
import FeatVoucher from '../views/features/featVoucher.vue'
import FeatActivityManagementSystem from '../views/features/featActivityManagement.vue'
import FeatLiveSupport from '../views/features/featLiveSupport.vue'

import FeatAdvanceCalendar from '../views/features/featAdvanceCalendar.vue'
import FeatBatchInvoicing from '../views/features/featBatchInvoicing.vue'
import FeatCommunicationFunnel from '../views/features/featCommuncationFunnel.vue'
import FeatCustomerManagement from '../views/features/featCustomermanagement.vue'
import FeatDashboard from '../views/features/featDashboard.vue'
import FeatFranchiseSystem from '../views/features/featFranchiseSystem.vue'
import FeatInvoiceDetails from '../views/features/featInvoiceDetails.vue'
import FeatInvoiceManagement from '../views/features/featInvoiceManagement.vue'
import FeatJobDetails from '../views/features/featJobDetails.vue'
import FeatJobManagement from '../views/features/featJobManagement.vue'
import FeatMultiLocationManagement from '../views/features/featMultiLocationManagement.vue'
import FeatStaffManagement from '../views/features/featStaffManagement.vue'
import FeatLeadManagement from '../views/features/featLeadManagement.vue'
import FeatRouteOptimizer from '../views/features/featRouteOptimizer.vue'
import FeatTimeAttendance from '../views/features/featTimeAttendance.vue'

import PrivacyPolicy from '../views/privacyPolicy.vue'

// import FeatCalendarM from '../views/features/calendarM.vue'
// import FeatCustomerM from '../views/features/customerM.vue'
// import FeatInvoiceM from '../views/features/invoiceM.vue'
// import FeatJobM from '../views/features/jobM.vue'
// import FeatStaffM from '../views/features/staffM.vue'


import Features from '../views/features.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/industry',
    name: 'Industry',
    component: Industry
  },
  {
    path: '/features',
    name: 'Features',
    component: Features
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: Pricing
  },

  
  
  {
    path: '/try-it-now',
    name: 'OnBoardingForm',
    component: OnBoardingForm
    
  },

  {
    path:'/privacy-policy',
    name:'privacy',
    component:PrivacyPolicy
  },

  {
    path: '/industry/',
    name: 'IndustryLayout',
    component: IndustryLayout,
    children:[
      {
        path: 'appliance_repair',
        name: 'IndustryAppliance',
        component: IndustryAppliance,
      },
      {
        path: 'chimney_sweeping',
        name: 'IndustryChimney',
        component: IndustryChimney,
      },
      {
        path: 'cleaner',
        name: 'IndustryCleaner',
        component: IndustryCleaner,
      },  {
        path: 'commercial',
        name: 'IndustryCommercial',
        component: IndustryCommercial,
      },
      {
        path: 'drywall',
        name: 'IndustryDrywall',
        component: IndustryDrywall,
      },  {
        path: 'garage',
        name: 'IndustryGarage',
        component: IndustryGarage,
      },
      {
        path: 'lawncare',
        name: 'IndustryLawnCare',
        component: IndustryLawnCare,
      },  {
        path: 'residential',
        name: 'IndustryResidential',
        component: IndustryResidential,
      },
      //batch 2
      {
        path: 'roofer',
        name: 'IndustryRoofer',
        component: IndustryRoofer,
      },  
      {
        path: 'locksmith',
        name: 'IndustryLocksmith',
        component: IndustryLocksmith,
      },
      {
        path: 'landscape',
        name: 'IndustryLandScaping',
        component: IndustryLandScaping,
      },  
      {
        path: 'carpenter',
        name: 'IndustryCarpenter',
        component: IndustryCarpenter,
      },
      {
        path: 'carpet_cleaning',
        name: 'IndustryCarpetCleaning',
        component: IndustryCarpetCleaning,
      },  
      {
        path: 'construction',
        name: 'IndustryConstruction',
        component: IndustryConstruction,
      },
      {
        path: 'dog_walker',
        name: 'IndustryDogwalker',
        component: IndustryDogwalker,
      },
      //batch 3
      {
        path: 'fence',
        name: 'IndustryFence',
        component: IndustryFence,
      },
      {
        path: 'general_contractor',
        name: 'IndustryGeneralContractor',
        component: IndustryGeneralContractor,
      },
      {
        path: 'handyman',
        name: 'IndustryHandyman',
        component: IndustryHandyman,
      },
      {
        path: 'havac',
        name: 'IndustryHavac',
        component: IndustryHavac,
      },
      {
        path: 'irragation',
        name: 'IndustryIrragation',
        component: IndustryIrragation,
      },
      {
        path: 'junk_removal',
        name: 'IndustryJunkRemoval',
        component: IndustryJunkRemoval,
      },
      {
        path: 'mechanical_contractor',
        name: 'IndustryMechContractor',
        component: IndustryMechContractor,
      },
      {
        path: 'plumber',
        name: 'IndustryPlumber',
        component: IndustryPlumber,
      },
      {
        path: 'pressure_washing',
        name: 'IndustryPressureWashing',
        component: IndustryPressureWashing,
      },
      {
        path: 'snow_removal',
        name: 'IndustrySnowRemoval',
        component: IndustrySnowRemoval,
      },
      {
        path: 'tree_care',
        name: 'IndustryTreeCare',
        component: IndustryTreeCare,
      },
      {
        path: 'window',
        name: 'IndustryWindow',
        component: IndustryWindow,
      },
      {
        path: 'electrical',
        name: 'IndustryElectrical',
        component: IndustryElectrical,
      },
      {
        path: 'flooring',
        name: 'IndustryFlooring',
        component: IndustryFlooring,
      },
      {
        path: 'installation_service',
        name: 'IndustryInstallationService',
        component: IndustryInstallationService,
      },
      {
        path: 'painting_service',
        name: 'IndustryPaintingService',
        component: IndustryPaintingService,
      },
      {
        path: 'propert_maintenance',
        name: 'IndustryPropertyMaintenance',
        component: IndustryPropertyMaintenance,
      },
      {
        path: 'remodeling',
        name: 'IndustryRemodeling',
        component: IndustryRemodeling,
      },
      {
        path: 'restoration',
        name: 'IndustryRestoration',
        component: IndustryRestoration,
      },
      {
        path: 'janitorial',
        name: 'IndustryJanitorial',
        component: IndustryJanitorial,
      },
      {
        path: 'elevator',
        name: 'IndustryElevator',
        component: IndustryElevator,
      },
      


    ]
  },

  {
    path: '/features/',
    name: 'FeatLayout',
    component: FeatLayout,
    children:[
    
      {
        path: 'reporting',
        name: 'FeatReporting',
        component: FeatReporting,
      }, 
      {
        path: 'voucher',
        name: 'FeatVoucher',
        component: FeatVoucher,
      }, 
      {
        path: 'activity_management_system',
        name: 'FeatActivityManagementSystem',
        component: FeatActivityManagementSystem,
      },
      {
        path: 'live_support',
        name: 'FeatLiveSupport',
        component: FeatLiveSupport,
      },

      {
        path: 'advanced_calendar',
        name: 'FeatAdvanceCalendar',
        component: FeatAdvanceCalendar,
      },

      {
        path: 'batch_invoice',
        name: 'FeatBatchInvoicing',
        component: FeatBatchInvoicing,
      },

      {
        path: 'communication_funnel',
        name: 'FeatCommunicationFunnel',
        component: FeatCommunicationFunnel,
      },

      {
        path: 'customer_management',
        name: 'FeatCustomerManagement',
        component: FeatCustomerManagement,
      },

      {
        path: 'dashboard',
        name: 'FeatDashboard',
        component: FeatDashboard,
      },
      {
        path: 'franchise_system',
        name: 'FeatFranchiseSystem',
        component: FeatFranchiseSystem,
      },
      {
        path: 'invoice_details',
        name: 'FeatInvoiceDetails',
        component: FeatInvoiceDetails,
      },
      {
        path: 'invoice_management',
        name: 'FeatInvoiceManagement',
        component: FeatInvoiceManagement,
      },
      {
        path: 'job_details',
        name: 'FeatJobDetails',
        component: FeatJobDetails,
      },
      {
        path: 'job_management',
        name: 'FeatJobManagement',
        component: FeatJobManagement,
      },
      {
        path: 'multi_location_management',
        name: 'FeatMultiLocationManagement',
        component: FeatMultiLocationManagement,
      },
      {
        path: 'staff_management',
        name: 'FeatStaffManagement',
        component: FeatStaffManagement,
      },
      {
        path: 'lead_management',
        name: 'FeatLeadManagement',
        component: FeatLeadManagement,
      },
      {
        path: 'route_optimizer',
        name: 'FeatRouteOptimizer',
        component: FeatRouteOptimizer,
      },
      {
        path: 'time_attendance',
        name: 'FeatTimeAttendance',
        component: FeatTimeAttendance,
      },
      


      
      

    ]
  },

  
  

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
})

export default router
