<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1> Revolutionizing Appliance Repair Management with Schedeasy</h1>
                    </div>
                    <div class="text">
                        <h2>Streamline Operations and Elevate Customer Satisfaction</h2>
                        <p>Are you tired of juggling appliance repair appointments, technician assignments, and customer inquiries? Say hello to Schedeasy for Appliance Repair – your ultimate solution for seamless field service management.</p>
                    </div>
                    <figure class="image-box"><img :src="'/images/schedeasy/industry/details/'+filteredServices[0].wide_image" alt=""></figure>
                    <h1>Seamless Appliance Repair Management</h1>
                    <div class="text">
                        <p>Managing an appliance repair business is riddled with challenges, from scheduling chaos to inventory woes, leading to frustrated customers and hindered growth. Schedeasy, a comprehensive Field Service Management software, emerges as the transformative solution. With smart scheduling, real-time communication, and automated inventory control, Schedeasy tackles these issues head-on. It's not just a tool; it's the key to efficient operations and exceptional customer satisfaction. Amidst the complexities, Schedeasy shines as the ultimate answer, propelling appliance repair businesses to new heights.  </p>
                    </div>
                    
                </div>
                <div class="two-column">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div class="content-box">
                                
                                <h2 >Appointment Scheduling:</h2>
                                <div class="text"> Bid farewell to scheduling conflicts. Our smart calendar ensures every appointment is seamlessly organized.</div>
                            </div>
                            <div class="content-box">
                                <h2 >Technician Dispatch:</h2>
                                <div class="text"> Assign jobs to your technicians effortlessly. Keep your team aligned and ensure timely service delivery.</div>
                            </div>
                            <div class="content-box">
                                <h2 > Customer Communication:</h2>
                                <div class="text"> Stay connected with your clients throughout the repair process. Send real-time updates and receive instant feedback.</div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                            <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms"><img  :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt=""></figure>
                        </div>
                    </div>
                </div>
                <div class="bottom-content">
                    <h3>Embrace the Future of Appliance Repair Management</h3>
                    <div class="text">
                        <p>Join us in our mission to elevate commercial spaces to new standards of cleanliness. Our software goes beyond task management</p>
                    </div>
                 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>