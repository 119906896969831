<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Streamlined Invoicing Process</h1>
                    </div>
                    <div class="text">
                        <h2>Accurate and Hassle-Free Billing</h2>
                        <p>Invoicing is a critical aspect of any field service business, and our software's "Invoice Management" feature simplifies the process, ensuring accurate, timely, and organized invoicing.</p>
                    </div>
                    <div data-v-1e9dea97="" style="width: 100%;"><video data-v-1e9dea97="" autoplay :src="filteredFeatures[0].video_path" controls="controls" style="width: 100%;"></video></div>
                </div>
                <section class="recruitment-section elements sec-pad-two centred">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Effortless Invoice Creation</a></h3>
                                        <div class="text">With Invoice Management, you can create professional invoices effortlessly. Input job details, pricing, and any additional charges, and the system generates clear, itemized invoices.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Customizable Templates</a></h3>
                                        <div class="text">Personalize your invoices with ease. The feature offers customizable templates where you can add your company logo, contact details, and branding elements, making your invoices look polished and professional.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Automated Billing</a></h3>
                                        <div class="text">Streamline your invoicing workflow by automating recurring invoices. Set up automated billing schedules, and the system will generate and send invoices at predetermined intervals, saving you time and reducing the risk of missed payments.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Invoice Tracking</a></h3>
                                        <div class="text">Stay on top of your invoicing process with invoice tracking. Monitor which invoices are paid, pending, or overdue, ensuring you have full visibility into your financials.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Payment Integration</a></h3>
                                        <div class="text">Simplify the payment process for your customers by integrating payment gateways directly into your invoices. Customers can conveniently pay online, reducing the time it takes to receive payments.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <figure class="image-box wow fadeInRight" style="width: 455px;margin-top: 58px;" data-wow-delay="300ms" data-wow-duration="1500ms"><img src="/images/schedeasy/features/images/invoice.png" alt=""></figure>

                            </div>
                        </div>
                    </div>
                </section>
                <div class="bottom-content">
                    <div class="text">
                        <p>Invoice Management is your key to efficient financial operations. It enables you to create professional invoices, reduce administrative overhead, and improve cash flow, ultimately contributing to the financial health of your business.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.new-image{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
