<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Elevate Your Handyman Services with Schedeasy</h1>
                    </div>
                    <div class="text">
                        <h2>Simplify Operations, Enhance Customer Satisfaction</h2>
                        <p>
                            Feeling overwhelmed by the demands of scheduling handyman tasks, coordinating jobs, and addressing client inquiries? Welcome Schedeasy for Handyman Services – the ultimate solution for seamless service management.
                        </p>
                    </div>
                    <figure class="image-box"><img :src="'/images/schedeasy/industry/details/'+filteredServices[0].wide_image" alt=""></figure>
                    <div>
                        <h1>Transforming Handyman Services with Schedeasy</h1>
                        <div class="text">
                            <h2>Simplifying Operations and Elevating Customer Satisfaction</h2>
                            <p>
                                Running a handyman business involves tackling a wide range of tasks – scheduling repairs, coordinating services, and delivering reliable solutions. These complexities often lead to scheduling conflicts, communication gaps, and client concerns. Enter Schedeasy, the transformative solution for handyman service providers. With its intuitive scheduling features, real-time communication tools, and streamlined client management, Schedeasy is tailored to the handyman industry. It's more than just software; it's the foundation of efficient operations and exceptional customer experiences. Amidst the diverse demands of handyman services, Schedeasy emerges as the ultimate solution, propelling businesses to new heights of success while ensuring versatile and reliable solutions for clients.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="two-column">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div class="content-box">
                                <div>
                                    <h3>Job Management System</h3>
                                    <ul>
                                        <li>
                                            Streamline the management of your handyman tasks and projects with the Job Management System feature.
                                        </li>
                                        <li>
                                            Easily categorize tasks using custom job statuses like "Pending," "In Progress," or "Completed."
                                        </li>
                                        <li>
                                            Capture essential project details, including client information, task descriptions, and pricing, all in one place.
                                        </li>
                                        <li>
                                            Track staff clock-in/out times and receive real-time notifications for improved project management.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Vouchers/Gift Cards</h3>
                                    <ul>
                                        <li>
                                            Enhance your customer engagement and loyalty programs with the Vouchers/Gift Cards feature.
                                        </li>
                                        <li>
                                            Create redeemable vouchers with specific quantities and expiration dates to incentivize repeat business.
                                        </li>
                                        <li>
                                            Efficiently track redeemed vouchers, helping you measure the success of your promotional efforts.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Live Support</h3>
                                    <ul>
                                        <li>
                                            Offer dedicated customer support with the Live Support feature for handyman services.
                                        </li>
                                        <li>
                                            Ensure that clients and staff can access assistance whenever they need it.
                                        </li>
                                        <li>
                                            Improve communication, resolve issues promptly, and maintain a high level of customer satisfaction in the handyman industry.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                            <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms"><img  :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt=""></figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{

            }
        },
        mounted(){

        }
    }
</script>
