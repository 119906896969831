<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Revamp Your Janitorial Services with Schedeasy</h1>
                    </div>
                    <div class="text">
                        <h2>Simplify Operations, Elevate Customer Satisfaction</h2>
                        <p>
                            Are the complexities of scheduling janitorial services, managing cleaning staff, and addressing client inquiries causing challenges? Say hello to Schedeasy for Janitorial Services – your ultimate solution for effortless on-site service management.
                        </p>
                    </div>
                    <figure class="image-box"><img :src="'/images/schedeasy/industry/details/'+filteredServices[0].wide_image" alt=""></figure>
                    <div>
                        <h1>Transforming Janitorial Services with Schedeasy</h1>
                        <div class="text">
                            <h2>Streamlining Operations and Elevating Customer Satisfaction</h2>
                            <p>
                                Operating a janitorial business brings its own unique challenges – managing diverse tasks, coordinating cleaning teams, and maintaining facility standards. These complexities often lead to scheduling conflicts, communication gaps, and client discontent. That's where Schedeasy steps in as the game-changing solution. With its intuitive task scheduling, real-time communication tools, and comprehensive client management, Schedeasy is tailored to the janitorial industry. It's not just software; it's the cornerstone of efficient operations and exceptional client experiences. Amidst the intricacies of janitorial services, Schedeasy emerges as the ultimate solution, propelling businesses to new heights of success.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="two-column">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div class="content-box">
                                <div>
                                    <h3>Invoice Details Customization</h3>
                                    <ul>
                                        <li>
                                            Customize invoices to align with your janitorial business's branding and professionalism.
                                        </li>
                                        <li>
                                            Incorporate promotional images highlighting your cleaning services to attract and retain clients.
                                        </li>
                                        <li>
                                            Personalize invoice footers and include special notes or discounts to maintain client satisfaction.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Multi-Location Management</h3>
                                    <ul>
                                        <li>
                                            Effortlessly manage multiple janitorial service locations, whether you serve various businesses, offices, or institutions.
                                        </li>
                                        <li>
                                            Centralize and oversee operations from a single platform, allowing you to monitor each location's performance and access relevant data.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Staff Management</h3>
                                    <ul>
                                        <li>
                                            Simplify staff management by maintaining comprehensive staff records, including contact information, schedules, and performance records.
                                        </li>
                                        <li>
                                            Assign cleaning tasks efficiently based on staff availability, expertise, and location for streamlined operations.
                                        </li>
                                        <li>
                                            Manage staff permissions and access levels to ensure smooth coordination among your janitorial team.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                            <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms"><img  :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt=""></figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{

            }
        },
        mounted(){

        }
    }
</script>
