<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Building Stronger Customer Relationships</h1>
                    </div>
                    <div class="text">
                        <h2>Personalization and Effective Communication</h2>
                        <p>In the realm of field service management, nurturing strong customer relationships is paramount, and our software's "Customer Management" feature is your key to success.</p>
                    </div>
                    <div data-v-1e9dea97="" style="width: 100%;"><video data-v-1e9dea97="" autoplay :src="filteredFeatures[0].video_path" controls="controls" style="width: 100%;"></video></div>
                </div>
                <section class="recruitment-section elements sec-pad-two centred">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Comprehensive Customer Profiles</a></h3>
                                        <div class="text">This feature provides a centralized hub for all your customer data. From contact information to service history, you can create detailed customer profiles, allowing you to personalize interactions and offer tailored services.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Efficient Communication</a></h3>
                                        <div class="text">Keep the lines of communication open with ease. Customer Management enables you to send automated reminders, updates, and service confirmations, ensuring your customers are always in the loop.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Service History Tracking</a></h3>
                                        <div class="text">Gain valuable insights into each customer's unique needs. With this feature, you can access a customer's entire service history at a glance, making it easier to anticipate their requirements and exceed their expectations.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Customized Service</a></h3>
                                        <div class="text">Tailor your services to meet individual customer preferences. With detailed customer profiles, you can note specific instructions, preferences, and service expectations, allowing your team to deliver a personalized experience every time.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Invoice and Billing Transparency</a></h3>
                                        <div class="text">Customer Management ensures accurate billing and transparent invoicing. Easily generate invoices based on service history, and provide customers with clear, itemized bills, fostering trust and credibility.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <figure class="image-box wow fadeInRight" style="width: 455px;margin-top: 58px;" data-wow-delay="300ms" data-wow-duration="1500ms"><img src="/images/schedeasy/features/images/customer.png" alt=""></figure>
                            </div>
                            
                        </div>
                    </div>
                </section>
                <div class="bottom-content">
                    <div class="text">
                        <p>Strong customer relationships are the backbone of a successful field service business. With our Customer Management feature, you can build trust, deliver exceptional service, and create loyal customers who will keep coming back for your expertise.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.new-image{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
