|<template>
     <section class="pricing-style-three pricing-page-4" >
        <div class="container">
            <div class="tabs-box">
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                        <div class="content-box">
                            <div class="sec-title"><h2>Simple Pricing, Advanced Features, Unlimited Users.</h2></div>
                            <div class="text">Unlock unparalleled potential with Schedeasy's straightforward pricing model. Get the edge of advanced features, and invite limitless users to your journey of success. It's your field service management, simplified.</div>
                            <div class="tab-btn-box">
                                <ul class="tab-btns tab-buttons clearfix">
                                    
                                    <li
                                        v-for="(tab, index) in tabs"
                                        :key="index"
                                        :class="{ 'tab-btn': true, 'active-btn': activeTab === index }"
                                        :data-tab="tab.data"
                                        @click="changeTab(index)"
                                    >
                                        {{ tab.label }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 inner-column">
                        <div class="inner-box centred">
                            <div class="tabs-content">
                                <div :class="{ 'tab': true, 'active-tab': activeTab === 0 }" id="tab-1">
                                    <div class="pricing-block-two">
                                        <div class="pricing-table">
                                            <div class="choice-box">Popular Choice</div>
                                            <div class="table-header">
                                                <h3 class="title">Single Location</h3>
                                                <h2 class="price">{{ priceMonthNoZeroSl }}<span> Per Month</span></h2>
                                            </div>
                                            <div class="table-content">
                                                <ul> 
                                                    <li>Commitment Free Trial</li>
                                                    <li>All features included</li>
                                                    <li>Schedule and manage jobs</li>
                                                    <li>Send quotes and invoices</li>
                                                </ul>
                                            </div>
                                            <div class="table-footer">
                                                <a href="/try-it-now">Start Now</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pricing-block-two">
                                        <div class="pricing-table">
                                            <div class="choice-box">Popular Choice</div>
                                            <div class="table-header">
                                                <h3 class="title">Standard</h3>
                                                <div class="text">Single Location</div>
                                                <h2 class="price">{{ priceMonthNoZeroSl }}<span> Per Month</span></h2>
                                            </div>
                                            <div class="table-content">
                                                <ul> 
                                                    <li>Commitment Free Trial</li>
                                                    <li>All features included</li>
                                                    <li>Schedule and manage jobs</li>
                                                    <li>Send quotes and invoices</li>
                                                </ul>
                                            </div>
                                            <div class="table-footer">
                                                <!-- <a href="/try-it-now">Start Now</a> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div :class="{ 'tab': true, 'active-tab': activeTab === 1 }" id="tab-2">
                                    <div class="pricing-block-two">
                                        <div class="pricing-table">
                                            <div class="choice-box">Popular Choice</div>
                                            <div class="table-header">
                                                <h3 class="title">Single Location</h3>
                                                <h2 class="price">{{ priceYearNoZeroSl }}<span> Per Year</span></h2>
                                            </div>
                                            <div class="table-content">
                                                <ul> 
                                                    <li>Commitment Free Trial</li>
                                                    <li>All features included</li>
                                                    <li>Schedule and manage jobs</li>
                                                    <li>Send quotes and invoices</li>
                                                </ul>
                                            </div>
                                            <div class="table-footer">
                                                <a href="/try-it-now">Start Now</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pricing-block-two">
                                        <div class="pricing-table">
                                            <div class="choice-box">Popular Choice</div>
                                            <div class="table-header">
                                                <h3 class="title">Standard</h3>
                                                <h2 class="price">{{ priceYear }}<span> Per Year</span></h2>
                                            </div>
                                            <div class="table-content">
                                                <ul> 
                                                    <li>Commitment Free Trial</li>
                                                    <li>All features included</li>
                                                    <li>Schedule and manage jobs</li>
                                                    <li>Send quotes and invoices</li>

                                                </ul>
                                            </div>
                                            <div class="table-footer">
                                                <!-- <a href="#">Buy Now</a> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>           
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
        
    data() {
        return {
            activeTab: 0,
            tabs: [
                { label: 'Monthly', content: 'Monthly content goes here',data:"#tab-1" },
                { label: 'Yearly', content: 'Yearly content goes here',data:"#tab-2" },
            ],
        };
    },
    mounted(){
        if ($('.testimonial-carousel-2').length) {
            $('.testimonial-carousel-2').owlCarousel({
                loop:true,
                margin:30,
                nav:false,
                smartSpeed: 3000,
                autoplay: true,
                navText: [ '<span class="flaticon-left"></span>', '<span class="flaticon-right"></span>' ],
                responsive:{
                    0:{
                        items:1
                    },
                    480:{
                        items:1
                    },
                    600:{
                        items:1
                    },
                    800:{
                        items:1
                    },			
                    1200:{
                        items:1
                    }

                }
            });  
        }
    },
    methods: {
        isRouteActive(routeName) {
            return this.$route.name === routeName;
        },
        routeChange(route){
            this.$router.push({name:route})
        },
        changeTab(index) {
            this.activeTab = index;
        },
    }
}
</script>

<style lang="scss" scoped>
.pricing-page-4{
    padding: 26px 0px 26px 0px;

}
</style>