<template>
    <div>
      <div class="service-details-content">
        <div class="inner-box">
          <div class="top-content">
            <div class="sec-title">
              <h1>Transform Remodeling with Schedeasy</h1>
            </div>
            <div class="text">
              <h2>Simplify Renovation Projects, Amplify Customer Satisfaction</h2>
              <p>
                Remodeling projects don't have to be overwhelming. Transform your remodeling business with Schedeasy, simplifying renovation projects and amplifying customer satisfaction.
              </p>
            </div>
            <figure class="image-box">
              <img :src="'/images/schedeasy/industry/details/' + filteredServices[0].wide_image" alt="">
            </figure>
            <div>
              <h1>Transforming Remodeling Services with Schedeasy</h1>
              <div class="text">
                <h2>Simplifying Operations and Elevating Renovation Excellence</h2>
                <p>
                  Running a remodeling services business involves a multitude of challenges – scheduling projects, coordinating renovation teams, and ensuring top-notch transformations. These intricacies often lead to scheduling conflicts, communication gaps, and client concerns about the quality of their remodels. That's where Schedeasy steps in as the transformative solution for remodeling service providers. With its intelligent project scheduling for renovations, real-time communication tools for team coordination, and streamlined client management, Schedeasy is tailored to the remodeling industry. It's more than just software; it's the foundation of efficient operations and exceptional renovation excellence. Amidst the complexities of remodeling services, Schedeasy emerges as the ultimate answer, propelling businesses to new heights of success while ensuring stunning and well-executed renovations for clients to enjoy.
                </p>
              </div>
            </div>
          </div>
          <div class="two-column">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                <div class="content-box">
                  <div>
                    <h3>Customer Management</h3>
                    <ul>
                      <li>
                        Efficiently manage your remodeling service customers with the Customer Management feature.
                      </li>
                      <li>
                        Keep detailed records of customer information, including contact details, service preferences, and project history.
                      </li>
                      <li>
                        Easily add new customers and update existing records to ensure accurate and personalized service.
                      </li>
                      <li>
                        Access customer profiles to gain insights that help you provide exceptional service and build lasting relationships.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Live Support</h3>
                    <ul>
                      <li>
                        Offer dedicated customer support with the Live Support feature for remodeling services.
                      </li>
                      <li>
                        Ensure that clients can reach out for assistance or inquiries whenever they need it, enhancing customer satisfaction.
                      </li>
                      <li>
                        Resolve issues promptly, provide guidance, and maintain open lines of communication to foster strong client relationships.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Invoice Management</h3>
                    <ul>
                      <li>
                        Efficiently manage remodeling projects and invoices with the Invoice Management feature.
                      </li>
                      <li>
                        View and organize jobs based on dates, categories, and custom details to streamline billing.
                      </li>
                      <li>
                        Utilize pre-set date filters and category filters for quick access to job information.
                      </li>
                      <li>
                        Perform powerful searches, change job statuses, and perform mass updates or deletions to simplify invoice tracking.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms">
                  <img :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt="">
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {};
    },
    mounted() {}
  };
  </script>
  