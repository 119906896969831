<template>
    <div>
        <section class="page-title" style="background-image: url(/images/background/pagetitle-bg.png);">
            <div class="anim-icons">
                <div class="icon icon-1"><img src="/images/icons/anim-icon-17.png" alt=""></div>
                <div class="icon icon-2 rotate-me"><img src="/images/icons/anim-icon-18.png" alt=""></div>
                <div class="icon icon-3 rotate-me"><img src="/images/icons/anim-icon-19.png" alt=""></div>
                <div class="icon icon-4"></div>
            </div>
            <div class="container">
                <div class="content-box clearfix">
                    <div class="title-box pull-left">
                        <h1>{{ filteredServices[0].title }}</h1>
                        <p>{{ filteredServices[0].description }}</p>
                    </div>
                    <!-- <ul class="bread-crumb pull-right">
                        <li><a href="/">Home</a></li>
                        <li><a href="/industry">All Industries</a></li>
                        <li>{{ filteredServices[0].title }}</li>
                    </ul> -->
                </div>
            </div>
        </section>
    <section class="service-details">
        <div class="container">
            <div class="row">
                <!-- <div class="col-lg-4 col-md-12 col-sm-12 sidebar-side">
                    <div class="service-sidebar">
                        <div class="sidebar">
                            <h3>Industries</h3>
                            <div class="text">Excepteur sint occaecat cupidatat pro ident sunt culpa officia desernt mollit</div>
                            <ul class="list">
                                <li v-for="(item, index) in services" :key="index" :class="{ active: isRouteActive(item.route_name) }" @click="routeChange(item.route_name)"><a href="#">{{ item.title }}</a></li>
                            </ul>
                        </div>
                    </div>
                </div> -->
                <div class="col-lg-12 col-md-12 col-sm-12 content-side">
                    <router-view/>
                </div>
            </div>
        </div>
    </section>
    <Testimonial/>

    <section class="optimization-application elements sec-pad-two" >
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                <div id="content_block_17">
                    <div class="content-box">
                        <div class="sec-title"><h2>Discover the Power of Our Scheduling System: Explore Schedeasy's Feature-Packed Solutions</h2></div>
                        <h3>Your Solution for Streamlined Field Service Management Awaits</h3>
                        <div class="text">
                            <p>Welcome to Schedeasy, your key to unlocking a new era of effortless field service software. If you're eager to streamline operations, enhance customer satisfaction, and supercharge productivity, you're in the right place.
                            We get the complexities of field service management. That's why we built a feature-packed platform. Our aim? Simplify your journey and deliver exceptional results.
                            </p>
                        </div>
                        <ul class="list-item">
                            <template v-for="(feat, index) in features">
                                <li :key="index"  v-if="index < 4">{{ feat.name }}</li>
                            </template>
                        </ul>
                        <div class="btn-box"><a href="/features" class="theme-btn">View All Features<i class="fas fa-angle-right"></i></a></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                <div id="image_block_17">
                    <div class="image-box">

                        <figure class="image image-1" style="width: 369px;"><img src="/images/schedeasy/main2.png" alt=""></figure>
                        <figure class="image image-2 wow slideInLeft" style="width: 179px; " data-wow-delay="00ms" data-wow-duration="1500ms"><img src="/images/schedeasy/main.png" alt=""></figure>
                       
                        <figure class="image image-4 wow slideInRight" style="width: 180px; " data-wow-delay="00ms" data-wow-duration="1500ms"><img src="/images/schedeasy/main3.png" alt=""></figure>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>

    <Pricing/>

        <WeWork/>
    </div>
</template>

<script>
import WeWork from '@/components/wework.vue'
import Pricing from '@/components/pricing_section.vue'
import Testimonial from '@/components/testimonial.vue'
    export default {
        components:{
            Pricing,
            Testimonial,
            WeWork
        },
        data() {
            return {
                activeTab: 0,
                tabs: [
                    { label: 'Monthly', content: 'Monthly content goes here',data:"#tab-1" },
                    { label: 'Yearly', content: 'Yearly content goes here',data:"#tab-2" },
                ],
            };
        },
        mounted(){
            if ($('.testimonial-carousel-2').length) {
                $('.testimonial-carousel-2').owlCarousel({
                    loop:true,
                    margin:30,
                    nav:false,
                    smartSpeed: 3000,
                    autoplay: true,
                    navText: [ '<span class="flaticon-left"></span>', '<span class="flaticon-right"></span>' ],
                    responsive:{
                        0:{
                            items:1
                        },
                        480:{
                            items:1
                        },
                        600:{
                            items:1
                        },
                        800:{
                            items:1
                        },			
                        1200:{
                            items:1
                        }

                    }
                });  
            }
        },
        methods: {
            isRouteActive(routeName) {
                return this.$route.name === routeName;
            },
            routeChange(route){
                this.$router.push({name:route})
            },
            changeTab(index) {
                this.activeTab = index;
            },
        }
    }
</script>

<style lang="scss">
.sec-title h1 {
    position: relative;
    display: block;
    font-size: 36px;
    line-height: 48px;
    color: #222222;
    font-weight: 500;
    padding-bottom: 31px;
    margin-bottom: 31px;
}
.sec-title h1:before {
    position: absolute;
    content: '';
    background: #cccccc;
    width: 130px;
    height: 2px;
    left: 0px;
    bottom: 0px;
}

.sec-title h1:after {
    position: absolute;
    content: '';
    background: #777777;
    width: 45px;
    height: 4px;
    left: 0px;
    bottom: 0px;
    transition: all 500ms ease;
    -webkit-mask-image: linear-gradient(-75deg, rgba(244,55,55,0.6) 50%, #f43737 50%, rgba(0,0,0,1) 70%);
    -webkit-mask-size: 200%;
    animation: shine 2s infinite;
}

.service-details-content .inner-box{
    h1{
        position: relative;
        display: block;
        line-height: 32px;
        color: #000;
        font-weight: 600;
        margin-bottom: 15px;
    }
    h2 {
        position: relative;
        display: block;
        font-size: 24px;
        line-height: 32px;
        color: #4527a4;
        font-weight: 600;
        margin-bottom: 15px;
    }
    h3{
        color: #000;
        font-size: 20px;
        font-weight: 600;
    }
}
</style>

<style lang="scss" scoped>

.optimization-application{
    background-position: bottom left;
    background-repeat: no-repeat;
}
#content_block_48 .inner-box .bg-layer {
    position: absolute;
    width: 835px;
    height: 793px;
    top: 40px;
    right: -40px;
    background-repeat: no-repeat;
    background-size: cover;
}

</style>