<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Revolutionize Your HVAC Business with Schedeasy</h1>
                    </div>
                    <div class="text">
                        <h2>Streamline Operations, Elevate Customer Satisfaction</h2>
                        <p>
                            Are you struggling to manage HVAC service appointments, coordinate technicians, and address customer inquiries? Say hello to Schedeasy for HVAC – your ultimate solution for seamless service management.
                        </p>
                    </div>
                    <figure class="image-box"><img :src="'/images/schedeasy/industry/details/'+filteredServices[0].wide_image" alt=""></figure>
                    <div>
                        <h1>Revolutionizing HVAC Operations with Schedeasy</h1>
                        <div class="text">
                            <h2>Enhancing Efficiency and Elevating Climate Control Excellence</h2>
                            <p>
                                Managing an HVAC (Heating, Ventilation, and Air Conditioning) business involves a multitude of challenges – scheduling installations and repairs, coordinating technicians, and ensuring precise climate control. These intricacies often lead to scheduling conflicts, communication gaps, and customer discomfort. That's where Schedeasy steps in as the transformative solution for HVAC service providers. With its intelligent appointment scheduling, real-time communication tools, and streamlined client management, Schedeasy is custom-tailored to the HVAC industry. It's more than just software; it's the foundation of efficient operations and exceptional climate control solutions. Amidst the complexities of HVAC services, Schedeasy emerges as the ultimate answer, propelling businesses to new heights of success while ensuring optimal comfort for clients.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="two-column">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div class="content-box">
                                <div>
                                    <h3>Advanced Calendar Features</h3>
                                    <ul>
                                        <li>
                                            Enhance your scheduling capabilities with advanced calendar tools designed for HVAC service appointments.
                                        </li>
                                        <li>
                                            Utilize intuitive drag-and-drop functionality to schedule maintenance visits, installations, and repairs efficiently.
                                        </li>
                                        <li>
                                            Quickly assign jobs to specific dates and times, optimizing technician schedules and service delivery.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Invoice Management</h3>
                                    <ul>
                                        <li>
                                            Streamline your HVAC business's invoicing process with the Invoice Management feature.
                                        </li>
                                        <li>
                                            Easily view and organize service jobs based on dates, categories, and custom details.
                                        </li>
                                        <li>
                                            Utilize pre-set date filters and category filters for quick access to project information.
                                        </li>
                                        <li>
                                            Perform powerful searches, filter jobs by specific categories, and manage billing efficiently.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Multi-Location Management</h3>
                                    <ul>
                                        <li>
                                            Simplify the management of multiple HVAC service locations with the Multi-Location Management feature.
                                        </li>
                                        <li>
                                            Whether you operate branch offices, serve different regions, or handle projects across various sites, centralize project data.
                                        </li>
                                        <li>
                                            Monitor the performance of each location, access relevant data, and maintain consistency in operations.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                            <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms"><img :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt=""></figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{

            }
        },
        mounted(){

        }
    }
</script>
