<template>
    <div>
        <section class="page-title" style="background-image: url(/images/background/pagetitle-bg.png);">
            <div class="anim-icons">
                <div class="icon icon-1"><img src="/images/icons/anim-icon-17.png" alt=""></div>
                <div class="icon icon-2 rotate-me"><img src="/images/icons/anim-icon-18.png" alt=""></div>
                <div class="icon icon-3 rotate-me"><img src="/images/icons/anim-icon-19.png" alt=""></div>
                <div class="icon icon-4"></div>
            </div>
            <div class="container">
                <div class="content-box clearfix">
                    <div class="title-box pull-left">
                        <h1>Privacy Policy</h1>
                        <p>Last Updated on June 22, 2023.</p>
                    </div>
                    <ul class="bread-crumb pull-right">
                        <li><a href="/">Home</a></li>
                        <li>Pricing</li>
                    </ul>
                </div>
            </div>
        </section>
        <section class="video-style-two section-video" style="margin-top: 62px;">
            <div class="vector-image">
                <figure class="image image-1"><img src="images/icons/vactor-3.png" alt=""></figure>
                <figure class="image image-2"><img src="images/icons/vactor-4.png" alt=""></figure>
                <figure class="image image-3"><img src="images/icons/vactor-5.png" alt=""></figure>
                <figure class="image image-4"><img src="images/icons/vactor-6.png" alt=""></figure>
            </div>
            <div class="container">
                <div class="sec-title center">
                    <h2>We value your trust</h2>
                    <p>
                        This Privacy Policy governs the manner in which Schedeasy ("we," "us," or "our") collects, uses, maintains, and discloses information collected from users (referred to as "you" or "your") of the Schedeasy mobile application ("the App"). This Privacy Policy applies to the App and all services offered by Schedeasy.
                        Information we collect
                        We may collect personal identification information from you when you download, install, or use the Schedeasy mobile application. The information we collect may include, but is not limited to:
                        Your name
                        Contact information (including email address and phone number)
                        Device information (such as device type, operating system, and unique device identifiers)
                        Usage data (including interactions with the App and its features)
                        Please note that by using the App, you consent to the collection of certain information automatically, including usage data and technical information about your device.
                        How we use collected information
                        We may collect and use your personal information for the following purposes:
                        To provide and improve our services: We use the information collected to deliver the functionalities of the App, personalize your experience, and improve our services.
                        To communicate with you: We may use your contact information to respond to your inquiries, provide customer support, and send important notices or updates regarding the App.
                        To analyze usage patterns: We may analyze the data collected to understand how users interact with the App, identify usage trends, and improve its performance and features.
                        To protect our rights: We may use your information to detect, prevent, or address fraud, unauthorized access, or other potentially prohibited or illegal activities.
                        Data retention and security
                        We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. We take appropriate security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information stored within the App.
                        Third-party services and links
                        The App may contain links to third-party websites or services that are not owned or controlled by Schedeasy. We are not responsible for the privacy practices or content of such third-party services. We encourage you to review the privacy policies of those third parties before providing any personal information or using their services.
                        Children's privacy
                        Protecting the privacy of children is important to us. The App is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children under 13. If you believe that we may have inadvertently collected personal information from a child under 13, please contact us using the information provided below, and we will promptly delete that information.
                        Changes to this privacy policy
                        We reserve the right to update or modify this Privacy Policy at any time without prior notice. Any changes will be effective immediately upon posting the updated Privacy Policy within the App. Your continued use of the App after any modifications to the Privacy Policy constitutes your acceptance of such changes.
                        Contacting us
                        If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at:
                        Schedeasy Website: schedeasy.net 
                        Email:dev@schedeasy.net
                        This Privacy Policy was last updated on June 22, 2023.
                    </p>
                    <div class="btn-box"><a href="/try-it-now" class="theme-btn-two" style="margin-top: 20px;">Start Your Trial Now</a></div>

                </div>
               
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.page-title {
    position: relative;
    padding: 325px 0px 343px 0px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}
</style>