<template>
    <div>
      <div class="service-details-content">
        <div class="inner-box">
          <div class="top-content">
            <div class="sec-title">
              <h1>Revolutionize Lawn Care with Schedeasy</h1>
            </div>
            <div class="text">
              <h2>Simplify Operations, Elevate Customer Satisfaction</h2>
              <p>
                Are you finding it challenging to maintain lawns effectively? Revolutionize your lawn care business with Schedeasy, simplifying operations, and elevating customer satisfaction.
              </p>
            </div>
            <figure class="image-box">
              <img :src="'/images/schedeasy/industry/details/' + filteredServices[0].wide_image" alt="">
            </figure>
            <div>
              <h1>Transforming Lawn Care Services with Schedeasy</h1>
              <div class="text">
                <h2>Simplifying Operations and Elevating Lawn Beauty</h2>
                <p>
                  Running a lawn care services business involves a myriad of challenges – managing busy schedules, coordinating lawn maintenance tasks, and ensuring lush, healthy lawns. These intricacies often lead to scheduling conflicts, communication gaps, and client concerns about the appearance of their outdoor spaces. That's where Schedeasy steps in as the transformative solution for lawn care service providers. With its intelligent scheduling for lawn maintenance, real-time communication tools for team coordination, and streamlined client management, Schedeasy is tailored to the lawn care industry. It's more than just software; it's the foundation of efficient operations and exceptional lawn beauty. Amidst the complexities of lawn care services, Schedeasy emerges as the ultimate answer, propelling businesses to new heights of success while ensuring verdant and thriving lawns for clients to enjoy.
                </p>
              </div>
            </div>
          </div>
          <div class="two-column">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                <div class="content-box">
                  <div>
                    <h3>Job Management System</h3>
                    <ul>
                      <li>
                        Simplify the management of lawn care projects with the Job Management System feature.
                      </li>
                      <li>
                        View and organize jobs based on dates, categories, and custom details for efficient project tracking.
                      </li>
                      <li>
                        Utilize pre-set date filters and category filters to quickly access project information.
                      </li>
                      <li>
                        Perform powerful searches, change job statuses, and perform mass updates or deletions for streamlined job management.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Invoice Details</h3>
                    <ul>
                      <li>
                        Take control of your lawn care invoicing with the Invoice Details feature.
                      </li>
                      <li>
                        Customize payment types, add promotional images to highlight your services, and personalize invoice footers and notes.
                      </li>
                      <li>
                        Create standardized email bodies for efficient communication with clients.
                      </li>
                      <li>
                        Generate professional PDF invoices, manage multiple payments, and apply discount vouchers for a seamless invoicing process.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Reporting</h3>
                    <ul>
                      <li>
                        Gain valuable insights into your lawn care business with the Reporting feature.
                      </li>
                      <li>
                        Choose from pre-set or custom reports to access data on project performance, revenue, and customer satisfaction.
                      </li>
                      <li>
                        Use these insights to make informed decisions, optimize your services, and achieve greater efficiency and profitability.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms">
                  <img :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt="">
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {};
    },
    mounted() {}
  };
  </script>
  