<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Revolutionize Your General Contracting Business with Schedeasy</h1>
                    </div>
                    <div class="text">
                        <h2>Streamline Operations, Elevate Customer Satisfaction</h2>
                        <p>
                            Are you finding it difficult to manage the intricacies of general contracting projects, coordinate various tasks, and handle client inquiries? Say hello to Schedeasy for General Contracting – your ultimate solution for seamless project management.
                        </p>
                    </div>
                    <figure class="image-box"><img :src="'/images/schedeasy/industry/details/'+filteredServices[0].wide_image" alt=""></figure>
                    <div>
                        <h1>Revolutionizing General Contracting Operations with Schedeasy</h1>
                        <div class="text">
                            <h2>Enhancing Efficiency and Elevating Project Excellence</h2>
                            <p>
                                Managing a general contracting business comes with a diverse range of challenges – overseeing complex projects, coordinating various trades, and ensuring seamless execution. These intricacies often lead to project delays, communication gaps, and client dissatisfaction. That's where Schedeasy enters as the transformative solution. With its intelligent project scheduling, real-time communication tools, and streamlined client management, Schedeasy is designed for the general contracting industry. It's more than just software; it's the cornerstone of efficient operations and exceptional project outcomes. Amidst the multifaceted nature of general contracting, Schedeasy stands as the ultimate answer, propelling businesses to new heights of success.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="two-column">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div class="content-box">
                                <div>
                                    <h3>Invoice Management</h3>
                                    <ul>
                                        <li>
                                            Efficiently manage your general contracting projects with the Invoice Management feature.
                                        </li>
                                        <li>
                                            Easily view and organize jobs based on dates, categories, and custom details.
                                        </li>
                                        <li>
                                            Utilize pre-set date filters and category filters for quick access.
                                        </li>
                                        <li>
                                            Perform powerful searches, filter jobs by specific categories, and make mass updates or deletions as needed.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Advanced Calendar Features</h3>
                                    <ul>
                                        <li>
                                            Enhance your scheduling capabilities with advanced calendar tools.
                                        </li>
                                        <li>
                                            Utilize intuitive drag-and-drop functionality to schedule construction projects, tasks, and appointments.
                                        </li>
                                        <li>
                                            Quickly assign jobs to specific dates and times, making adjustments as needed.
                                        </li>
                                        <li>
                                            Visualize your calendar to stay organized and optimize your workflow.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Multi-Location Management</h3>
                                    <ul>
                                        <li>
                                            Simplify the management of multiple general contracting project locations with the Multi-Location Management feature.
                                        </li>
                                        <li>
                                            Whether you have branch offices, handle projects in different regions, or maintain various business sites, centralize your project data.
                                        </li>
                                        <li>
                                            Monitor performance across all locations, access relevant data, and ensure consistency in operations.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                            <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms"><img  :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt=""></figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{

            }
        },
        mounted(){

        }
    }
</script>
