<template>
    <div>
      <div class="service-details-content">
        <div class="inner-box">
          <div class="top-content">
            <div class="sec-title">
              <h1>Transform Snow Removal with Schedeasy</h1>
            </div>
            <div class="text">
              <h2>Simplify Snow Clearing, Amplify Customer Satisfaction</h2>
              <p>
                Snow and ice shouldn't disrupt your operations. Transform your snow removal with Schedeasy, simplifying snow clearing and amplifying customer satisfaction.
              </p>
            </div>
            <figure class="image-box">
              <img :src="'/images/schedeasy/industry/details/' + filteredServices[0].wide_image" alt="">
            </figure>
            <div>
              <h1>Transforming Snow Removal Services with Schedeasy</h1>
              <div class="text">
                <h2>Simplifying Operations and Elevating Winter Safety</h2>
                <p>
                  Running a snow removal services business involves a range of challenges – managing unpredictable weather, coordinating snow removal teams, and ensuring safe and efficient snow clearance. These intricacies often lead to scheduling conflicts, communication gaps, and client concerns about winter safety. That's where Schedeasy steps in as the transformative solution for snow removal service providers. With its intelligent scheduling for snow clearance assignments, real-time communication tools for team coordination, and streamlined client management, Schedeasy is tailored to the snow removal industry. It's more than just software; it's the foundation of efficient operations and exceptional winter safety. Amidst the complexities of snow removal services, Schedeasy emerges as the ultimate answer, propelling businesses to new heights of success while ensuring clear and safe pathways for clients during the winter months.
                </p>
              </div>
            </div>
          </div>
          <div class="two-column">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                <div class="content-box">
                  <div>
                    <h3>Route Optimization</h3>
                    <ul>
                      <li>
                        Ensure efficient snow removal operations with the Route Optimization feature.
                      </li>
                      <li>
                        Plan and assign snow removal tasks based on job locations, crew availability, and priority.
                      </li>
                      <li>
                        Minimize travel time, reduce fuel costs, and improve overall productivity by ensuring that your crews follow optimized routes.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Job Management System</h3>
                    <ul>
                      <li>
                        Simplify the management of snow removal tasks with the Job Management System feature.
                      </li>
                      <li>
                        Customize job statuses to categorize tasks effectively, such as "Scheduled," "In Progress," or "Completed."
                      </li>
                      <li>
                        Send timely reminders to clients with timestamps to ensure smooth task execution.
                      </li>
                      <li>
                        Capture client signatures within the system, eliminating paperwork and enhancing document management.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Multi-Location Management</h3>
                    <ul>
                      <li>
                        Effortlessly manage multiple locations for your snow removal service business with the Multi-Location Management feature.
                      </li>
                      <li>
                        Whether you have branch offices, serve different regions, or operate across various sites, centralize and oversee operations from a single platform.
                      </li>
                      <li>
                        Monitor each location's performance, access relevant data, and maintain consistency in service quality across all locations.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms">
                  <img :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt="">
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {};
    },
    mounted() {}
  };
  </script>
  