<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Transform Your Carpentry Business with Schedeasy</h1>
                    </div>
                    <div class="text">
                        <h2>Effortlessly Manage Operations, Amplify Customer Satisfaction</h2>
                        <p>
                            Are you finding it challenging to balance carpentry appointments, team scheduling, and client inquiries? Embrace Schedeasy for Carpentry – the ultimate solution for seamless on-site service coordination.
                        </p>
                    </div>
                    <figure class="image-box"><img :src="'/images/schedeasy/industry/details/'+filteredServices[0].wide_image" alt=""></figure>
                    <div>
                        <h1>Revolutionizing Carpenter Operations with Schedeasy</h1>
                        <div class="text">
                            <h2>Streamlining Projects and Elevating Client Satisfaction</h2>
                            <p>
                                Running a carpentry business brings its unique set of challenges – managing project timelines, coordinating tasks, and delivering top-notch craftsmanship. These intricacies often lead to project delays, communication gaps, and client dissatisfaction. Enter Schedeasy, the transformative solution for carpenters. With its intelligent project scheduling, real-time communication tools, and streamlined client management, Schedeasy is custom-built for the carpentry trade. It's not just software; it's the foundation of efficient operations and unparalleled client experiences. Amidst the intricacies of the industry, Schedeasy stands as the definitive solution, propelling carpentry businesses to new pinnacles of success.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="two-column">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div class="content-box">
                                <div>
                                    <h3>Batch Invoicing</h3>
                                    <ul>
                                        <li>
                                            Streamline your carpentry business's invoicing process by consolidating multiple jobs into one invoice.
                                        </li>
                                        <li>
                                            Access detailed job information directly from invoices for efficient management.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Vouchers/Gift Cards</h3>
                                    <ul>
                                        <li>
                                            Create and manage redeemable vouchers with specific quantities and expiration dates, allowing for promotional offers and tracking.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Time and Attendance Tracking</h3>
                                    <ul>
                                        <li>
                                            Efficiently track the time and attendance of your carpentry team to ensure accurate payroll management and work hour monitoring.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                            <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms"><img  :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt=""></figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{

            }
        },
        mounted(){

        }
    }
</script>
