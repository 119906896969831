<template>
    <div>
        <section class="page-title" style="background-image: url(/images/background/pagetitle-bg.png);">
            <div class="anim-icons">
                <div class="icon icon-1"><img src="/images/icons/anim-icon-17.png" alt=""></div>
                <div class="icon icon-2 rotate-me"><img src="/images/icons/anim-icon-18.png" alt=""></div>
                <div class="icon icon-3 rotate-me"><img src="/images/icons/anim-icon-19.png" alt=""></div>
                <div class="icon icon-4"></div>
            </div>
            <div class="container">
                <div class="content-box clearfix">
                    <div class="title-box pull-left">
                        <h1>{{ filteredFeatures[0].name }}</h1>
                        <p>{{ filteredFeatures[0].description }}</p>
                    </div>
                    <!-- <ul class="bread-crumb pull-right">
                        <li><a href="/">Home</a></li>
                        <li><a href="/features">All Features</a></li>
                        <li>{{ filteredFeatures[0].name }}</li>
                    </ul> -->
                </div>
            </div>
        </section>
     
    <section class="service-details">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 content-side">
                    <router-view/>

                </div>
            </div>
        </div>
    </section>

    <Testimonial/>
    <Industry/>
    <Pricing/>

    <WeWork/>

    <!-- service-details end -->
    </div>
</template>

<script>
import Industry from '@/components/industries_com.vue'
import WeWork from '@/components/wework.vue'
import Pricing from '@/components/pricing_section.vue'
import Testimonial from '@/components/testimonial.vue'

    export default {
        components:{
            Pricing,
            Testimonial,
            WeWork,
            Industry
        },
        mounted(){
          
        },
        methods: {
            isRouteActive(routeName) {
                return this.$route.name === routeName;
            },
            routeChange(route){
                this.$router.push({name:route})
            },
            
        }
    }
</script>

<style lang="scss" scoped>



</style>