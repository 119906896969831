<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Revamp Your Fence Services with Schedeasy</h1>
                    </div>
                    <div class="text">
                        <h2>Simplify Operations, Elevate Customer Satisfaction</h2>
                        <p>
                            Struggling to keep up with fence installation appointments, coordinating work crews, and addressing client inquiries? Say hello to Schedeasy for Fence Services – your ultimate solution for seamless on-site service coordination.
                        </p>
                    </div>
                    <figure class="image-box"><img :src="'/images/schedeasy/industry/details/'+filteredServices[0].wide_image" alt=""></figure>
                    <div>
                        <h1>Transforming Fence Service Operations with Schedeasy</h1>
                        <div class="text">
                            <h2>Simplifying Projects and Elevating Property Protection</h2>
                            <p>
                                Running a fence service business comes with its unique set of challenges – managing diverse projects, coordinating skilled teams, and delivering secure solutions. These complexities often lead to project delays, communication gaps, and client concerns. Enter Schedeasy, the transformative solution for fence service providers. With its intuitive project scheduling, real-time communication tools, and streamlined client management, Schedeasy is designed for the fence industry. It's more than just software; it's the foundation of efficient operations and exceptional property protection. Amidst the demands of fence services, Schedeasy emerges as the ultimate solution, propelling businesses to new heights of success while ensuring durable and secure fencing solutions.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="two-column">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div class="content-box">
                                <div>
                                    <h3>Multi-Location Management</h3>
                                    <ul>
                                        <li>
                                            Effortlessly manage multiple fence service locations, whether you have branch offices, serve various regions, or maintain different project sites.
                                        </li>
                                        <li>
                                            Centralize your project data and streamline operations to ensure consistency and efficiency across all locations.
                                        </li>
                                        <li>
                                            Access relevant data for each location, monitor performance, and maintain operational standards.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Staff Management</h3>
                                    <ul>
                                        <li>
                                            Simplify the management of your fence service team with the Staff Management feature.
                                        </li>
                                        <li>
                                            Keep track of staff details, including contact information and schedules.
                                        </li>
                                        <li>
                                            Assign fence installation or repair tasks to staff members, and track their availability efficiently.
                                        </li>
                                        <li>
                                            Manage staff permissions and access levels to ensure seamless coordination within your team.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Batch Invoicing</h3>
                                    <ul>
                                        <li>
                                            Streamline your invoicing process for fence installation and repair projects with Batch Invoicing.
                                        </li>
                                        <li>
                                            Consolidate multiple projects into a single, comprehensive invoice.
                                        </li>
                                        <li>
                                            Access detailed project information directly from invoices, making it easy to review and manage billing for multiple fence services.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                            <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms"><img  :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt=""></figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{

            }
        },
        mounted(){

        }
    }
</script>
