<template>
        <section class="feature-style-three section-4">
        <div class="container">
            <div class="inner-container">
                <div class="inner-box">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                            <div id="iamge_block_03">
                                <div class="image-box">
                                    <div class="bg-layer j-fig-div" style="background-image: url(/images/icons/image-shap-2.png);">
                                        <div class="fig-holders">
                                            <figure class="image image-2 wow slideInLeft schedeasy-feature1" data-wow-delay="300ms" data-wow-duration="1500ms"><img src="/images/schedeasy/commercial.png" alt=""></figure>
                                            <figure class="image image-2 wow slideInLeft schedeasy-feature2" data-wow-delay="300ms" data-wow-duration="1500ms"><img src="/images/schedeasy/resedential.png" alt=""></figure>
                                            <figure class="image image-2 wow slideInLeft schedeasy-feature3" data-wow-delay="300ms" data-wow-duration="1500ms"><img src="/images/schedeasy/lawn_care.png" alt=""></figure>
                                        </div>
                                    </div>
                                   
                                   
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div id="content_block_03">
                                <div class="content-box wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
                                    <div class="sec-title"><h2>Unleash Your Industry's Potential with Schedasy!</h2></div>
                                    <div class="text">
                                        <p>
                                            Discover the unmatched power of Schedasy's field service solution, meticulously designed for your industry. Whether you're in <span style="color:#4527a4;font-weight: bold;">plumbing, landscaping, HVAC, or electrical services,</span> our platform is tailored to your unique needs. Schedasy simplifies <span style="color:#4527a4;font-weight: bold;">scheduling, dispatching, and management,</span> guaranteeing customized efficiency, no matter your field. Elevate your business today with Schedasy.
                                        </p>
                                    </div>
                                    <div class="btn-box"><a href="/industry" class="theme-btn">View All Industries<i class="fas fa-angle-right"></i></a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.feature-style-three {
    position: relative;
    background: #f9f9f9;
    padding: 150px 0px 0px 0px;
}
.schedeasy-feature1{
    width: 327px;
    top: 264px !important;
    left: 22px !important;
    box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1)!important;
    border-radius: 18px;
    img{
        border-radius: 18px;
    }

}

.schedeasy-feature2{
    width: 327px;
    top: 67px !important;
    left: 264px !important;
    box-shadow: 0 5px 20px 0px rgba(66, 66, 66, 0.1)!important;
    border-radius: 18px;
    img{
        border-radius: 18px;
    }
}

.schedeasy-feature3{
    width: 327px;
    top: 422px !important;
    left: 299px !important;
    box-shadow: 0 5px 20px 0px rgba(66, 66, 66, 0.1)!important;
    border-radius: 18px;
    img{
        border-radius: 18px;
    }
}

@media only screen and (max-width: 1350px){
    .section-4{
            .bg-layer{
                width: 579px;
                height: 689px;
                background-size: contain;
                left: 43px;
                top: -75px;
                .fig-holders{
                    .schedeasy-feature1{
                        width:280px;
                    }
                    .schedeasy-feature2{
                        width:280px;
                        top: 94px !important;

                    }
                    .schedeasy-feature3{
                        width: 280px;
                        top: 389px!important;
                        left: 243px !important;
                    }
                }
            }
        }
}

@media only screen and (max-width: 1199px){
    .section-4{
            .bg-layer{
                left: -8px !important;
                top: 122px!important;
            }
        }
}

@media only screen and (max-width: 992px){
        .section-4{
            .bg-layer{
                left: -8px !important;
                top: 122px!important;
            }

            #content_block_03 {
                .content-box{
                     margin-left: 0px!important;

                }
            }

        }
        .subscribe-section{
            .bg-layer{
                left: -40px!important;
            }
        }
}
</style>