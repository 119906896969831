<template>
    <div>
      <div class="service-details-content">
        <div class="inner-box">
          <div class="top-content">
            <div class="sec-title">
              <h1>Elevate Your Locksmith Business with Schedeasy</h1>
            </div>
            <div class="text">
              <h2>Effortlessly Handle Locksmith Jobs, Enhance Customer Satisfaction</h2>
              <p>
                Locked out or dealing with security concerns? Elevate your locksmith business with Schedeasy, which makes handling locksmith jobs effortless, enhancing overall customer satisfaction.
              </p>
            </div>
            <figure class="image-box">
              <img :src="'/images/schedeasy/industry/details/' + filteredServices[0].wide_image" alt="">
            </figure>
            <div>
              <h1>Revolutionizing Locksmith Services with Schedeasy</h1>
              <div class="text">
                <h2>Enhancing Efficiency and Elevating Security</h2>
                <p>
                  Running a locksmith services business comes with a host of challenges – managing emergency calls, coordinating locksmith teams, and ensuring top-notch security solutions. These intricacies often lead to scheduling conflicts, communication gaps, and client concerns about their safety. That's where Schedeasy steps in as the transformative solution for locksmith service providers. With its intelligent scheduling for locksmith assignments, real-time communication tools for team coordination, and streamlined client management, Schedeasy is custom-tailored to the locksmith industry. It's more than just software; it's the cornerstone of efficient operations and exceptional security solutions. Amidst the complexities of locksmith services, Schedeasy emerges as the ultimate answer, propelling businesses to new heights of success while ensuring peace of mind and security for clients.
                </p>
              </div>
            </div>
          </div>
          <div class="two-column">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                <div class="content-box">
                  <div>
                    <h3>Multi-Location Management</h3>
                    <ul>
                      <li>
                        Streamline the management of multiple locksmith service locations with the Multi-Location Management feature.
                      </li>
                      <li>
                        Whether you operate branch offices, serve different regions, or manage locksmith services across various sites, centralize project data.
                      </li>
                      <li>
                        Monitor the performance of each location, access relevant data, and maintain consistency in operations to ensure efficient locksmith services.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Staff Management</h3>
                    <ul>
                      <li>
                        Simplify staff management with the Staff Management feature.
                      </li>
                      <li>
                        Keep track of staff details, including contact information, schedules, and job assignments.
                      </li>
                      <li>
                        Efficiently assign jobs to staff members, track their availability, and manage staff permissions and access levels.
                      </li>
                      <li>
                        Streamline communication with staff, ensuring efficient coordination across your locksmith team.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Live Support</h3>
                    <ul>
                      <li>
                        Offer dedicated customer support with the Live Support feature for locksmith services.
                      </li>
                      <li>
                        Ensure that clients can reach out for assistance or inquiries whenever they need it.
                      </li>
                      <li>
                        Enhance communication, resolve issues promptly, and maintain high customer satisfaction in the locksmith industry.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms">
                  <img :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt="">
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {};
    },
    mounted() {}
  };
  </script>
  