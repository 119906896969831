<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Revolutionize Your Electrical Contractor Business with Schedeasy</h1>
                    </div>
                    <div class="text">
                        <h2>Simplify Operations, Elevate Customer Satisfaction</h2>
                        <p>
                            Feeling the strain of managing electrical projects, scheduling technicians, and addressing client questions? Welcome Schedeasy for Electrical Contractors – the ultimate solution for seamless project management.
                        </p>
                    </div>
                    <figure class="image-box"><img :src="'/images/schedeasy/industry/details/'+filteredServices[0].wide_image" alt=""></figure>
                    <div>
                        <h1>Transforming Electrical Contractor Operations with Schedeasy</h1>
                        <div class="text">
                            <h2>Simplifying Projects and Elevating Service Excellence</h2>
                            <p>
                                Running an electrical contractor business comes with its unique set of challenges – managing intricate projects, coordinating specialized teams, and delivering reliable solutions. These complexities often lead to project delays, communication breakdowns, and client dissatisfaction. Enter Schedeasy, the transformative solution for electrical contractors. With its intelligent project scheduling, real-time communication tools, and streamlined client management, Schedeasy is tailored to the electrical industry. It's more than just software; it's the foundation of efficient operations and exceptional service delivery. Amidst the demands of electrical contracting, Schedeasy emerges as the ultimate solution, propelling businesses to new heights of success while ensuring reliable and safe electrical solutions.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="two-column">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div class="content-box">
                                <div>
                                    <h3>Invoice Management</h3>
                                    <ul>
                                        <li>
                                            Efficiently manage your electrical projects with the Invoice Management feature.
                                        </li>
                                        <li>
                                            Easily view and organize jobs based on dates, categories, and custom details.
                                        </li>
                                        <li>
                                            Utilize pre-set date filters and category filters for quick access.
                                        </li>
                                        <li>
                                            Perform powerful searches, filter jobs by specific categories, and make mass updates or deletions as needed.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Multi-Location Management</h3>
                                    <ul>
                                        <li>
                                            Simplify the management of multiple electrical project locations, whether you serve different regions or have branch offices.
                                        </li>
                                        <li>
                                            Centralize your project data to monitor performance consistently across all locations.
                                        </li>
                                        <li>
                                            Access relevant data and maintain operational consistency with ease.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Job Management System</h3>
                                    <ul>
                                        <li>
                                            Streamline your electrical project tracking with the Job Management System.
                                        </li>
                                        <li>
                                            Use quick view pills to get an overview of your projects based on dates or amounts.
                                        </li>
                                        <li>
                                            Perform powerful searches and efficiently manage job statuses to ensure smooth project execution.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                            <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms"><img  :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt=""></figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{

            }
        },
        mounted(){

        }
    }
</script>
