<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Transform Your Dog Walking Business with Schedeasy</h1>
                    </div>
                    <div class="text">
                        <h2>Simplify Operations, Amplify Customer Satisfaction</h2>
                        <p>
                            Are you struggling to manage dog walking schedules, coordinate walkers, and handle client requests? Embrace Schedeasy for Dog Walking – the ultimate solution for seamless service management.
                        </p>
                    </div>
                    <figure class="image-box"><img :src="'/images/schedeasy/industry/details/'+filteredServices[0].wide_image" alt=""></figure>
                    <div>
                        <h1>Transforming Dog Walking Services with Schedeasy</h1>
                        <div class="text">
                            <h2>Simplifying Operations and Elevating Pet Care Experiences</h2>
                            <p>
                                Running a dog walking business presents its own set of challenges – managing busy schedules, coordinating pet care, and ensuring furry friends' happiness. These complexities often lead to scheduling conflicts, communication gaps, and client concerns. Enter Schedeasy, the revolutionary solution for dog walkers. With its intuitive scheduling features, real-time communication tools, and comprehensive client management, Schedeasy is custom-tailored for the pet care industry. It's more than just software; it's the core of efficient operations and exceptional pet care experiences. Amidst the demands of dog walking, Schedeasy emerges as the ultimate solution, propelling businesses to new levels of success while ensuring the well-being of beloved companions.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="two-column">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div class="content-box">
                                <div>
                                    <h3>Recurring Appointment Scheduling</h3>
                                    <ul>
                                        <li>
                                            Simplify the scheduling of recurring dog walking services for regular clients.
                                        </li>
                                        <li>
                                            Set up automated reminders and notifications for both clients and dog walkers to ensure consistency.
                                        </li>
                                        <li>
                                            Easily manage and adjust recurring service frequencies to accommodate various client preferences.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Client and Pet Profiles</h3>
                                    <ul>
                                        <li>
                                            Maintain detailed profiles for each client and their furry companions.
                                        </li>
                                        <li>
                                            Keep records of client contact information, pet names, breeds, and specific care instructions.
                                        </li>
                                        <li>
                                            Access client and pet profiles to provide personalized and attentive dog walking services.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Real-Time GPS Tracking</h3>
                                    <ul>
                                        <li>
                                            Utilize real-time GPS tracking for dog walking routes.
                                        </li>
                                        <li>
                                            Ensure dog walkers follow designated paths and reach clients' homes promptly.
                                        </li>
                                        <li>
                                            Provide clients with peace of mind by sharing live updates and route information during each dog walking session.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                            <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms"><img  :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt=""></figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{

            }
        },
        mounted(){

        }
    }
</script>
