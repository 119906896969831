<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Empowering Your Field Service Team</h1>
                    </div>
                    <div class="text">
                        <h2>Efficient Management and Accountability</h2>
                        <p>Efficiently managing your field service team is essential for success, and our software's "Staff Management" feature streamlines the process, ensuring your workforce is organized and productive.</p>
                    </div>
                    <div data-v-1e9dea97="" style="width: 100%;"><video data-v-1e9dea97="" autoplay :src="filteredFeatures[0].video_path" controls="controls" style="width: 100%;"></video></div>
                </div>
                <section class="recruitment-section elements sec-pad-two centred">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Employee Profiles</a></h3>
                                        <div class="text">Staff Management allows you to create comprehensive profiles for each team member. Store essential information such as contact details, qualifications, certifications, and work history in one central location.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Workforce Scheduling</a></h3>
                                        <div class="text">Easily schedule and assign jobs to your team members based on their skills, availability, and proximity to job locations. The feature ensures that the right technician is assigned to the right job at the right time.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Time and Attendance Tracking</a></h3>
                                        <div class="text">Accurately track employee work hours and attendance. With this feature, you can monitor when your team members start and finish jobs, helping you maintain accountability.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Certification Tracking</a></h3>
                                        <div class="text">Ensure that your team is up to date with the necessary certifications and training. Staff Management lets you track expiration dates and send automatic reminders for renewals.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Performance Analytics</a></h3>
                                        <div class="text">Access performance metrics for your team members. Evaluate key indicators such as job completion times, customer satisfaction ratings, and productivity, helping you make informed decisions regarding training and resource allocation.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <figure class="image-box wow fadeInRight" style="width: 455px;margin-top: 58px;" data-wow-delay="300ms" data-wow-duration="1500ms"><img src="/images/schedeasy/features/images/calendar.png" alt=""></figure>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="bottom-content">
                    <div class="text">
                        <p>Staff Management simplifies the complexities of workforce management, enabling you to optimize productivity, improve customer service, and ensure that your team is always qualified and ready for the job.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.new-image{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
