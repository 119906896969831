<template>
    <div>
      <div class="service-details-content">
        <div class="inner-box">
          <div class="top-content">
            <div class="sec-title">
              <h1>Transform Your Landscaping Services with Schedeasy</h1>
            </div>
            <div class="text">
              <h2>Streamline Operations, Enhance Customer Satisfaction</h2>
              <p>
                Struggling to keep up with the demands of landscaping services? Streamline your operations and enhance customer satisfaction with Schedeasy for Landscaping – your ultimate solution for seamless service management.
              </p>
            </div>
            <figure class="image-box">
              <img :src="'/images/schedeasy/industry/details/' + filteredServices[0].wide_image" alt="">
            </figure>
            <div>
              <h1>Revolutionizing Landscaping Services with Schedeasy</h1>
              <div class="text">
                <h2>Enhancing Efficiency and Elevating Outdoor Excellence</h2>
                <p>
                  Managing a landscaping services business involves a plethora of challenges – scheduling projects, coordinating landscaping crews, and ensuring picturesque outdoor spaces. These intricacies often lead to scheduling conflicts, communication gaps, and client concerns about the aesthetic quality of their landscapes. That's where Schedeasy steps in as the transformative solution for landscaping service providers. With its intelligent project scheduling for landscaping projects, real-time communication tools for crew coordination, and streamlined client management, Schedeasy is custom-tailored to the landscaping industry. It's more than just software; it's the cornerstone of efficient operations and exceptional outdoor excellence. Amidst the complexities of landscaping services, Schedeasy emerges as the ultimate answer, propelling businesses to new heights of success while ensuring breathtaking outdoor spaces for clients to enjoy.
                </p>
              </div>
            </div>
          </div>
          <div class="two-column">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                <div class="content-box">
                  <div>
                    <h3>Staff Management</h3>
                    <ul>
                      <li>
                        Simplify staff management with the Staff Management feature.
                      </li>
                      <li>
                        Maintain detailed records of your landscaping crew, including contact information, schedules, and skill sets.
                      </li>
                      <li>
                        Assign landscaping jobs to staff members based on their availability and expertise.
                      </li>
                      <li>
                        Streamline communication with your team and ensure efficient coordination across projects.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Job Details</h3>
                    <ul>
                      <li>
                        Efficiently manage and track your landscaping projects with the Job Details feature.
                      </li>
                      <li>
                        Customize job statuses to categorize tasks effectively, such as "Design," "Planting," or "Maintenance."
                      </li>
                      <li>
                        Send timely reminders to clients and staff with timestamps to ensure tasks are completed on schedule.
                      </li>
                      <li>
                        Access all job details in one place, including client information, pricing, and staff assignments.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Multi-Location Management</h3>
                    <ul>
                      <li>
                        Effortlessly manage multiple locations for your landscaping service business with the Multi-Location Management feature.
                      </li>
                      <li>
                        Whether you have branch offices, serve different regions, or operate across various sites, centralize and oversee operations from a single platform.
                      </li>
                      <li>
                        Monitor each location's performance, access relevant data, and maintain consistency in service quality across all locations.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms">
                  <img :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt="">
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {};
    },
    mounted() {}
  };
  </script>
  