<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Elevate Your Drywall Contractor Business with Schedeasy</h1>
                    </div>
                    <div class="text">
                        <h2>Streamline Operations, Enhance Customer Satisfaction</h2>
                        <p>
                            Are the challenges of scheduling drywall projects, coordinating contractors, and addressing client inquiries getting overwhelming? Say hello to Schedeasy for Drywall Contractors – your ultimate solution for seamless project management.
                        </p>
                    </div>
                    <figure class="image-box"><img :src="'/images/schedeasy/industry/details/'+filteredServices[0].wide_image" alt=""></figure>
                    <div>
                        <h1>Revolutionizing Drywall Contractor Operations with Schedeasy</h1>
                        <div class="text">
                            <h2>Enhancing Efficiency and Elevating Project Excellence</h2>
                            <p>
                                Running a drywall contractor business comes with its distinct challenges – managing intricate projects, coordinating skilled teams, and delivering impeccable craftsmanship. These complexities often lead to project delays, communication gaps, and client dissatisfaction. That's where Schedeasy steps in as the transformative solution. With its intelligent project scheduling, real-time communication tools, and streamlined client management, Schedeasy is designed for the drywall industry. It's more than just software; it's the cornerstone of efficient operations and exceptional project outcomes. Amidst the intricacies of drywall contracting, Schedeasy emerges as the ultimate answer, propelling businesses to new heights of success.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="two-column">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div class="content-box">
                                <div>
                                    <h3>Job Details</h3>
                                    <ul>
                                        <li>
                                            Efficiently manage and track all your drywall projects with customizable job details.
                                        </li>
                                        <li>
                                            Categorize tasks effectively with custom job statuses, such as "Preparation," "Installation," or "Finishing."
                                        </li>
                                        <li>
                                            Capture client signatures within the system to eliminate paperwork and enhance project documentation.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Customer Management</h3>
                                    <ul>
                                        <li>
                                            Streamline customer management by maintaining comprehensive customer profiles.
                                        </li>
                                        <li>
                                            Store contact details, preferences, and past project history for personalized service.
                                        </li>
                                        <li>
                                            Easily add new customers and update existing records to enhance customer satisfaction.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Communication Funnel</h3>
                                    <ul>
                                        <li>
                                            Centralize all communication within your team through a dedicated communication hub.
                                        </li>
                                        <li>
                                            Convert inquiries into leads or customers efficiently.
                                        </li>
                                        <li>
                                            Assign emails and messages for follow-up, ensuring seamless client interactions and project coordination.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                            <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms"><img  :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt=""></figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{

            }
        },
        mounted(){

        }
    }
</script>
