<template>
    <div>
      <div class="service-details-content">
        <div class="inner-box">
          <div class="top-content">
            <div class="sec-title">
              <h1>Revamp Pressure Washing with Schedeasy</h1>
            </div>
            <div class="text">
              <h2>Simplify Pressure Cleaning, Elevate Customer Satisfaction</h2>
              <p>
                Dirt and grime are no match for your pressure washing services. Revamp your pressure washing with Schedeasy, simplifying pressure cleaning and elevating customer satisfaction.
              </p>
            </div>
            <figure class="image-box">
              <img :src="'/images/schedeasy/industry/details/' + filteredServices[0].wide_image" alt="">
            </figure>
            <div>
              <h1>Transforming Pressure Washing Services with Schedeasy</h1>
              <div class="text">
                <h2>Simplifying Operations and Elevating Cleanliness Standards</h2>
                <p>
                  Running a pressure washing services business involves a range of challenges – scheduling projects, coordinating cleaning teams, and ensuring spotless exteriors. These intricacies often lead to scheduling conflicts, communication gaps, and client concerns about the cleanliness of their properties. That's where Schedeasy steps in as the transformative solution for pressure washing service providers. With its intelligent project scheduling for cleaning assignments, real-time communication tools for team coordination, and streamlined client management, Schedeasy is tailored to the pressure washing industry. It's more than just software; it's the foundation of efficient operations and exceptional cleanliness standards. Amidst the complexities of pressure washing services, Schedeasy emerges as the ultimate answer, propelling businesses to new heights of success while ensuring pristine and well-maintained exteriors for clients to enjoy.
                </p>
              </div>
            </div>
          </div>
          <div class="two-column">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                <div class="content-box">
                  <div>
                    <h3>Multi-Location Management</h3>
                    <ul>
                      <li>
                        Effortlessly manage multiple locations for your pressure washing service business with the Multi-Location Management feature.
                      </li>
                      <li>
                        Whether you have branch offices, serve different regions, or operate across various sites, centralize and oversee operations from a single platform.
                      </li>
                      <li>
                        Monitor each location's performance, access relevant data, and maintain consistency in service quality across all locations.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Live Support</h3>
                    <ul>
                      <li>
                        Offer dedicated customer support with the Live Support feature for pressure washing services.
                      </li>
                      <li>
                        Ensure that clients can reach out for assistance or inquiries whenever they need it, enhancing customer satisfaction.
                      </li>
                      <li>
                        Resolve issues promptly, provide guidance, and maintain open lines of communication to foster strong client relationships.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Invoice Details</h3>
                    <ul>
                      <li>
                        Take control of your invoicing for pressure washing services with the Invoice Details feature.
                      </li>
                      <li>
                        Customize payment types, add promotional images to highlight your services, and personalize invoice footers and notes.
                      </li>
                      <li>
                        Create standardized email bodies for efficient communication with clients, preview and send emails to multiple recipients, and manage multiple payments with ease.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms">
                  <img :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt="">
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {};
    },
    mounted() {}
  };
  </script>
  