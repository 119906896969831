<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Elevate Your Business Growth with Lead Management</h1>
                    </div>
                    <div class="text">
                        <h2>Efficiently Capture, Nurture, and Convert Leads</h2>
                        <p>Effective lead management is essential for growing your field service business, and our software's "Lead Management" feature provides a systematic and organized approach to capture, nurture, and convert leads into satisfied customers.</p>
                    </div>
                    <div data-v-1e9dea97="" style="width: 100%;"><video data-v-1e9dea97="" autoplay :src="filteredFeatures[0].video_path" controls="controls" style="width: 100%;"></video></div>
                </div>
                <section class="recruitment-section elements sec-pad-two centred">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Centralized Lead Repository</a></h3>
                                        <div class="text">The Lead Management feature acts as a centralized hub for storing all lead information. Easily capture and categorize leads, recording details such as contact information, lead source, and lead status for efficient follow-up.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Automated Lead Capture</a></h3>
                                        <div class="text">Streamline the lead capture process with automated tools. Integrate lead capture forms on your website or landing pages, and incoming leads are automatically added to your system, reducing manual data entry.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Lead Scoring and Prioritization</a></h3>
                                        <div class="text">Assign scores to leads based on criteria like lead source, engagement level, and potential value. Prioritize high-quality leads, ensuring your team focuses their efforts on the most promising opportunities.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Lead Nurturing</a></h3>
                                        <div class="text">Cultivate relationships with leads through automated nurturing campaigns. Send personalized emails, relevant content, and timely follow-ups to keep leads engaged and move them closer to conversion.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Conversion Tracking</a></h3>
                                        <div class="text">Monitor the progress of leads through the sales funnel. Track conversions, record interactions, and gain insights into the effectiveness of your lead management strategies.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <figure class="image-box wow fadeInRight" style="width: 455px;margin-top: 58px;" data-wow-delay="300ms" data-wow-duration="1500ms"><img src="/images/schedeasy/features/images/lead.png" alt=""></figure>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="bottom-content">
                    <div class="text">
                        <p>The Lead Management feature is your key to effective lead generation and conversion. It simplifies lead capture, automates lead nurturing, and provides the tools needed to turn prospects into satisfied customers, ultimately boosting your business's growth.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.new-image{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
