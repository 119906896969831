<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Unleash Insights with Powerful Reporting</h1>
                    </div>
                    <div class="text">
                        <h2>Transform Data into Informed Decisions</h2>
                        <p>Data is a valuable asset in field service management, and our software's "Reporting" feature empowers you with robust reporting capabilities to transform data into actionable insights.</p>
                    </div>
                    <div data-v-1e9dea97="" style="width: 100%;"><video data-v-1e9dea97="" autoplay :src="filteredFeatures[0].video_path" controls="controls" style="width: 100%;"></video></div>
                </div>
                <section class="recruitment-section elements sec-pad-two centred">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Customizable Reports</a></h3>
                                        <div class="text">Create reports tailored to your specific needs. The Reporting feature offers customization options, allowing you to select data fields, date ranges, and metrics that matter most to your business.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Visual Analytics</a></h3>
                                        <div class="text">Visualize data through charts, graphs, and dashboards. These visual representations make it easier to understand complex data sets, identify trends, and make informed decisions.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Real-Time Data</a></h3>
                                        <div class="text">Access real-time data for up-to-the-minute insights. Stay informed about job statuses, team performance, and financial metrics as they happen.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Export and Share</a></h3>
                                        <div class="text">Export reports in various formats, including PDF and Excel, for easy sharing and collaboration. Distribute reports to team members, stakeholders, or clients effortlessly.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Historical Insights</a></h3>
                                        <div class="text">Analyze historical data to identify patterns and areas for improvement. Historical reporting allows you to make informed decisions based on past performance.</div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </section>
                <div class="bottom-content">
                    <div class="text">
                        <p>The Reporting feature is your gateway to data-driven decision-making. It empowers you to track performance, optimize operations, and enhance the overall efficiency and effectiveness of your field service business.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.new-image{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
