<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1> Leveraging Vouchers and Gift Cards</h1>
                    </div>
                    <div class="text">
                        <h2>Boost Customer Engagement and Loyalty</h2>
                        <p>Incorporating vouchers and gift cards into your field service business can be a powerful marketing and customer retention strategy. Our software's "Vouchers/Gift Cards" feature simplifies the management and utilization of these valuable assets.</p>
                    </div>
                    <div data-v-1e9dea97="" style="width: 100%;"><video data-v-1e9dea97="" autoplay :src="filteredFeatures[0].video_path" controls="controls" style="width: 100%;"></video></div>                </div>
                <section class="recruitment-section elements sec-pad-two centred">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Easy Creation and Customization</a></h3>
                                        <div class="text">Generate vouchers and gift cards with ease. This feature allows you to create custom designs, set specific denominations, and personalize messages, aligning them with your branding.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Flexible Redemption</a></h3>
                                        <div class="text">Customers can easily redeem vouchers and gift cards for your services. The system seamlessly integrates voucher redemption into your billing process, ensuring a hassle-free experience.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Promotional Campaigns</a></h3>
                                        <div class="text">Utilize vouchers and gift cards as part of your promotional campaigns. You can offer them as rewards, incentives, or as part of special offers to attract and retain customers.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Tracking and Reporting</a></h3>
                                        <div class="text">Monitor the usage and effectiveness of vouchers and gift cards. Track redemptions, expiration dates, and campaign performance to refine your marketing strategies.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Customer Loyalty</a></h3>
                                        <div class="text">Encourage customer loyalty by offering vouchers or gift cards for repeat business. This feature fosters a sense of appreciation among your clients, increasing their likelihood to return.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms"><img src="/images/schedeasy/features/images/voucher.png" alt=""></figure>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="bottom-content">
                    <div class="text">
                        <p>The Vouchers/Gift Cards feature enhances your marketing efforts, strengthens customer relationships, and provides a versatile tool for promoting your field service business.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.new-image{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
