<template>
    <div>
      <div class="service-details-content">
        <div class="inner-box">
          <div class="top-content">
            <div class="sec-title">
              <h1>Elevate Roofing Services with Schedeasy</h1>
            </div>
            <div class="text">
              <h2>Streamline Roofing Solutions, Enhance Customer Satisfaction</h2>
              <p>
                Roofing issues can be a hassle. Elevate your roofing services with Schedeasy, streamlining roofing solutions and enhancing overall customer satisfaction.
              </p>
            </div>
            <figure class="image-box">
              <img :src="'/images/schedeasy/industry/details/' + filteredServices[0].wide_image" alt="">
            </figure>
            <div>
              <h1>Revolutionizing Roofing Services with Schedeasy</h1>
              <div class="text">
                <h2>Enhancing Efficiency and Elevating Roofing Excellence</h2>
                <p>
                  Managing a roofing services business comes with a host of challenges – scheduling complex projects, coordinating skilled roofing teams, and ensuring top-tier roofing solutions. These intricacies often lead to project delays, communication gaps, and client concerns about the integrity of their roofs. That's where Schedeasy steps in as the transformative solution for roofing service providers. With its intelligent project scheduling for roofing assignments, real-time communication tools for team coordination, and streamlined client management, Schedeasy is custom-tailored to the roofing industry. It's more than just software; it's the cornerstone of efficient operations and exceptional roofing excellence. Amidst the complexities of roofing services, Schedeasy emerges as the ultimate answer, propelling businesses to new heights of success while ensuring reliable and durable roofing solutions for clients.
                </p>
              </div>
            </div>
          </div>
          <div class="two-column">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                <div class="content-box">
                  <div>
                    <h3>Multi-Location Management</h3>
                    <ul>
                      <li>
                        Effortlessly manage multiple locations for your roofing service business with the Multi-Location Management feature.
                      </li>
                      <li>
                        Whether you have branch offices, serve different regions, or operate across various sites, centralize and oversee operations from a single platform.
                      </li>
                      <li>
                        Monitor each location's performance, access relevant data, and maintain consistency in service quality across all locations.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Invoice Details</h3>
                    <ul>
                      <li>
                        Take control of your roofing service invoicing with the Invoice Details feature.
                      </li>
                      <li>
                        Customize payment types, add promotional images to highlight your services, and personalize invoice footers and notes.
                      </li>
                      <li>
                        Create standardized email bodies for efficient communication with clients, preview and send emails to multiple recipients, and manage multiple payments with ease.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Job Management System</h3>
                    <ul>
                      <li>
                        Simplify the management of roofing projects with the Job Management System feature.
                      </li>
                      <li>
                        Customize job statuses to categorize tasks effectively, such as "Scheduled," "In Progress," or "Completed."
                      </li>
                      <li>
                        Send timely reminders to clients with timestamps to ensure smooth project execution.
                      </li>
                      <li>
                        Capture client signatures within the system, eliminating paperwork and enhancing document management.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms">
                  <img :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt="">
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {};
    },
    mounted() {}
  };
  </script>
  