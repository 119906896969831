<template>
    <div>
      <div class="service-details-content">
        <div class="inner-box">
          <div class="top-content">
            <div class="sec-title">
              <h1>Revolutionize Your Installation Services with Schedeasy</h1>
            </div>
            <div class="text">
              <h2>Simplify Operations, Elevate Customer Satisfaction</h2>
              <p>
                Are you finding it challenging to manage installation appointments, coordinate installation teams, and address client inquiries? Embrace Schedeasy for Installation Services – your ultimate solution for seamless on-site service coordination.
              </p>
            </div>
            <figure class="image-box">
              <img :src="'/images/schedeasy/industry/details/' + filteredServices[0].wide_image" alt="">
            </figure>
            <div>
              <h1>Revolutionizing Installation Services with Schedeasy</h1>
              <div class="text">
                <h2>Enhancing Efficiency and Elevating Installation Excellence</h2>
                <p>
                  Managing an installation services business encompasses a myriad of challenges – scheduling projects, coordinating installation crews, and ensuring precise execution. These intricacies often lead to scheduling conflicts, communication gaps, and client concerns. That's where Schedeasy comes in as the transformative solution for installation service providers. With its intelligent project scheduling, real-time communication tools, and streamlined client management, Schedeasy is designed for the installation industry. It's more than just software; it's the foundation of efficient operations and exceptional installation outcomes. Amidst the complexities of installation services, Schedeasy emerges as the ultimate answer, propelling businesses to new heights of success while ensuring top-quality installation solutions for clients.
                </p>
              </div>
            </div>
          </div>
          <div class="two-column">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                <div class="content-box">
                  <div>
                    <h3>Vouchers/Gift Cards</h3>
                    <ul>
                      <li>
                        Enhance your customer engagement and loyalty programs with the Vouchers/Gift Cards feature for installation services.
                      </li>
                      <li>
                        Create redeemable vouchers with specific quantities and expiration dates to incentivize repeat business.
                      </li>
                      <li>
                        Efficiently track redeemed vouchers, helping you measure the success of your promotional efforts and enhance customer retention.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Batch Invoicing</h3>
                    <ul>
                      <li>
                        Streamline your invoicing process for installation projects with the Batch Invoicing feature.
                      </li>
                      <li>
                        Consolidate multiple installation jobs into a single, comprehensive invoice for clients' convenience.
                      </li>
                      <li>
                        Access detailed project information directly from invoices, making it easy to review and manage billing for multiple installation services.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Reporting</h3>
                    <ul>
                      <li>
                        Gain valuable insights into your installation services business with the Reporting feature.
                      </li>
                      <li>
                        Choose from pre-set or custom reports to access data on project performance, revenue, and client satisfaction.
                      </li>
                      <li>
                        Use these insights to make informed decisions, optimize your services, and achieve greater efficiency and profitability.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms">
                  <img :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt="">
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {};
    },
    mounted() {}
  };
  </script>
  