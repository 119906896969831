<template>
    <div>
        <section class="banner-section">
            <div class="bg-layer" style="background-image: url(/images/schedeasy/newbanner3.png);"></div>
            <div class="pattern-bg" style="background-image: url(/images/icons/vactor-1.png);"></div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                        <div class="content-box">
                            <h1>Field Service Management for Unlimited Users at One Low Price with No Feature Restrictions</h1>
                            <div class="text">Empower Your Operations, Delight Your Customers, and Supercharge Efficiency.</div>
                            <div class="btn-box"><a href="/try-it-now">Give It A Try</a></div>
                        </div>
                    </div>
                    <!-- <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                        <div class="image-box float-bob-y clearfix">
                            <figure class="image image-1 wow fadeInUp" data-wow-delay="900ms" data-wow-duration="1500ms"><img src="/images/resource/phone-1.png" alt=""></figure>
                            <figure class="image image-2 wow fadeInUp" data-wow-delay="1500ms" data-wow-duration="1500ms"><img src="/images/resource/phone-2.png" alt=""></figure>
                        </div>
                    </div> -->
                </div>
            </div>
        </section>
         <!-- feature-section -->
        <section class="feature-section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-5 col-md-12 col-sm-12 image-column">
                        <div id="iamge_block_01">
                            <div class="image-box float-bob-y">
                                <figure class="image wow slideInLeft" data-wow-delay="00ms" data-wow-duration="1500ms"><img src="/images/schedeasy/main2.png" alt=""></figure>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-12 col-sm-12 content-column">
                        <div id="content_block_01">
                            <div class="content-box">
                                <div class="sec-title">
                                    <h2>Amazing Features</h2>
                                    <p>Streamline operations, maximize efficiency, and elevate customer experiences with our cutting-edge field service management software – empowering your business to thrive in the digital age.</p>
                                </div>
                                <div class="inner-box wow fadeInUp" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-12 single-column">
                                            <div class="single-item">
                                                <div class="bg-layer" style="background-image: url(/images/icons/feature-icon-1.png);"></div>
                                                <div class="icon-box"><i class="flaticon-app-1"></i></div>
                                                <h5><a href="/features/invoice_management">Invoice Management</a></h5>
                                                <div class="text">Take control of your invoicing with Invoice Details. Customize payment types. Add Promotional images to Highlight your services. </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 single-column">
                                            <div class="single-item wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
                                                <div class="bg-layer"></div>
                                                <div class="icon-box"><i class="flaticon-target"></i></div>
                                                <h5><a href="/features/job_management">Job Management</a></h5>
                                                <div class="text">Seamlessly organize, track, and conquer every task with precision, ensuring your field service operations run like a well-oiled machine.</div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 single-column">
                                            <div class="single-item wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                                                <div class="bg-layer"></div>
                                                <div class="icon-box"><i class="flaticon-shipping"></i></div>
                                                <h5><a href="/features/staff_management">Staff Management</a></h5>
                                                <div class="text"> Empower your team and streamline communication effortlessly with tools designed to maximize efficiency and collaboration </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 single-column">
                                            <div class="single-item wow fadeInUp" data-wow-delay="900ms" data-wow-duration="1500ms">
                                                <div class="bg-layer"></div>
                                                <div class="icon-box"><i class="flaticon-dashboard"></i></div>
                                                <h5><a href="/features/customer_management">Customer Management</a></h5>
                                                <div class="text"> Unlock the power of personalized service by effortlessly organizing and accessing customer data, preferences, and histories, allowing you to elevate your customer relationships to new heights</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="btn-box"><a href="/features" class="theme-btn">View More Features<i class="fas fa-angle-right"></i></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- feature-section -->

         <!-- feature-style-two -->
        <section class="feature-style-two centred">
            <div class="container">
                <div class="row gutter-20">
                    <div class="col-lg-4 col-md-6 col-sm-12 feature-block">
                        <div class="feature-block-one wow flipInY animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                            <div class="inner-box js-tilt">
                                <div class="hover-content"></div>
                                <div class="icon-box">
                                    <div class="bg-layer" style="background-image: url(/images/icons/feature-icon-1.png);"></div>
                                    <i class="flaticon-smartphone"></i>
                                </div>
                                <h5><a href="#">Staff Mobile APP</a></h5>
                                <div class="text">Revolutionize your workforce dynamics with our Staff Mobile App. From clock-ins to task management, empower your team to collaborate effortlessly while on the move, enhancing productivity like never before.</div>
                                <div class="row download-btn" >
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <a href="https://play.google.com/store/apps/details?id=com.schedeasy.app" class="g-play">
                                            <i class="fab fa-android"></i>
                                            <div class="taker">
                                                <span>Get on it</span>
                                                Google Play
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12" style="display: block;">
                                        <a href="https://apps.apple.com/app/schedeasy-staff/id6450611309" class="g-play">
                                            <i class="fab fa-apple"></i>
                                            <div class="taker">
                                                <span>Get on it</span>
                                                App Store
                                            </div>
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 feature-block">
                        <div class="feature-block-one wow flipInY animated" data-wow-delay="300ms" data-wow-duration="1500ms">
                            <div class="inner-box js-tilt">
                                <div class="hover-content"></div>
                                <div class="icon-box">
                                    <div class="bg-layer" style="background-image: url(/images/icons/feature-icon-2.png);"></div>
                                    <i class="flaticon-seo-and-web"></i>
                                </div>
                                <h5><a href="#">Admin Descktop Access</a></h5>
                                <div class="text">
                                    Make charge of your operations with our Admin Desktop Access. Harness the power of comprehensive tools and insights, ensuring streamlined oversight and strategic decision-making, all from the convenience of your desktop.
                                </div>
                                <div class="row download-btn" >
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <a href="https://app.schedeasy.net/" target="blank" class="g-play">
                                            <div class="taker">
                                                Login Now
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 feature-block">
                        <div class="feature-block-one wow flipInY animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                            <div class="inner-box js-tilt">
                                <div class="hover-content"></div>
                                <div class="icon-box">
                                    <div class="bg-layer" style="background-image: url(/images/icons/feature-icon-3.png);"></div>
                                    <i class="flaticon-app"></i>
                                </div>
                                <h5><a href="#">Customer Mobile App</a></h5>
                                <div class="text">Elevate your service offering with our Customer Mobile App. Seamlessly connect clients to your solutions, enabling them to book, track, and engage with your services on the go, delivering convenience at their fingertips.</div>
                                <div class="row download-btn" >
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <a href="javascript:void(0)" class="g-play">
                                            <i class="fab fa-android"></i>
                                            <div class="taker">
                                                <span>Launching Soon</span>
                                                Google Play
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12" style="display: block;">
                                        <a href="javascript:void(0)" class="g-play">
                                            <i class="fab fa-apple"></i>
                                            <div class="taker">
                                                <span>Launching Soon</span>
                                                App Store
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- feature-style-two end -->
         <!-- feature-style-six -->
    <section class="feature-style-six section-3">
        <div class="container">
            <div class="inner-box">
                <div class="row align-items-center">
                    <div class="col-lg-5 col-md-12 col-sm-12 content-column">
                        <div id="content_block_15">
                            <div class="content-box wow fadeInUp animated" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div class="sec-title"><h2>Unlock Your Business's Full Potential with Schedeasy!</h2></div>
                                <div class="text" style="font-weight: bold;">
                                    <p>
                                        At Schedeasy, we're not just software; we're your devoted success partner. Ready to supercharge your business operations?
                                    </p>
                                    <br>
                                    <p style="color:#4527a4">Introducing the Commitment Free System Trial – No Credit Card Needed!</p>
                                    <br>
                                    <p>
                                        During this trial, our passionate support team will be with you every step of the way, ensuring you harness the full potential of our cutting-edge system. Let our system's performance speak for itself. Sign up now for a risk-free journey and experience firsthand how Schedeasy empowers your business.<br>

                                    </p>
                                    <br>
                                    <p style="color:#4527a4">Ready to revolutionize your business with Schedeasy?</p>

                                  
                                </div>
                                
                                <div class="btn-box">
                                    <a href="/try-it-now" class="theme-btn">Sign Up Today!<i class="fas fa-angle-right"></i></a></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-12 col-sm-12 image-column">
                        <div id="image_block_14">
                            <div class="image-box">
                                <div class="bg-layer" style=" background-image: url(/images/icons/image-shap-6.png);background-position: center;background-size: cover;"></div>
                                <figure class="image image-1 clearfix wow slideInRight animated" data-wow-delay="00ms" data-wow-duration="1500ms"><img src="/images/schedeasy/responsive.png" alt=""></figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- feature-style-six -->
        <!-- feature-style-three -->
    <section class="feature-style-three section-4">
        <div class="container">
            <div class="inner-container">
                <div class="inner-box">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                            <div id="iamge_block_03">
                                <div class="image-box">
                                    <div class="bg-layer j-fig-div" style="background-image: url(/images/icons/image-shap-2.png);">
                                        <div class="fig-holders">
                                            <figure class="image image-2 wow slideInLeft schedeasy-feature1" data-wow-delay="300ms" data-wow-duration="1500ms"><img src="/images/schedeasy/commercial.png" alt=""></figure>
                                            <figure class="image image-2 wow slideInLeft schedeasy-feature2" data-wow-delay="300ms" data-wow-duration="1500ms"><img src="/images/schedeasy/resedential.png" alt=""></figure>
                                            <figure class="image image-2 wow slideInLeft schedeasy-feature3" data-wow-delay="300ms" data-wow-duration="1500ms"><img src="/images/schedeasy/lawn_care.png" alt=""></figure>
                                        </div>
                                    </div>
                                   
                                   
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div id="content_block_03">
                                <div class="content-box wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
                                    <div class="sec-title"><h2>Unleash Your Industry's Potential with Schedasy!</h2></div>
                                    <div class="text">
                                        <p>
                                            Discover the unmatched power of Schedasy's field service solution, meticulously designed for your industry. Whether you're in 
                                            <a href="/industry/plumber" style="color:#4527a4;font-weight: bold;">plumbing, </a>
                                            <a href="/industry/landscape" style="color:#4527a4;font-weight: bold;">landscaping, </a>
                                            <a href="/industry/havac" style="color:#4527a4;font-weight: bold;">HVAC, </a>
                                            <a href="/industry/electrical" style="color:#4527a4;font-weight: bold;">electrical services, </a>
                                            our platform is tailored to your unique needs. Schedasy simplifies 
                                            <a href="/features/advanced_calendar" style="color:#4527a4;font-weight: bold;">scheduling, </a>
                                            <a href="/features/route_optimizer" style="color:#4527a4;font-weight: bold;">dispatching, </a>and 
                                            <a href="/features/job_management" style="color:#4527a4;font-weight: bold;">management, </a>
                                           guaranteeing customized efficiency, no matter your field. Elevate your business today with Schedasy.
                                        </p>
                                    </div>
                                    <div class="btn-box"><a href="/industry" class="theme-btn">View All Industries<i class="fas fa-angle-right"></i></a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- feature-style-three -->

    <!-- video-style-two -->
    <section class="video-style-two section-video">
        <div class="vector-image">
            <figure class="image image-1"><img src="images/icons/vactor-3.png" alt=""></figure>
            <figure class="image image-2"><img src="images/icons/vactor-4.png" alt=""></figure>
            <figure class="image image-3"><img src="images/icons/vactor-5.png" alt=""></figure>
            <figure class="image image-4"><img src="images/icons/vactor-6.png" alt=""></figure>
        </div>
        <div class="container">
            <div class="sec-title center">
                <h2>Schedeasy's Vision of the Future</h2>
                <p>At SchedEasy, our vision is to revolutionize field service management across diverse industries. We envision becoming a pioneering force in delivering streamlined solutions that enhance operational efficiency, customer satisfaction, and industry benchmarks for businesses worldwide. Our aim is to redefine how organizations manage their services, enabling them to achieve peak performance, seamless customer interactions, and setting new standards of excellence.</p>
                <div class="btn-box"><a href="/try-it-now" class="theme-btn-two">Start Your Trial Now</a></div>

            </div>
            <div class="video-inner">
                <video ref="videoElement" src="https://dawata.s3.ap-southeast-2.amazonaws.com/schedy/SchedEasy.mp4"  style="width: 100%; height: 100%;" controls></video>
            </div>
        </div>
    </section>
    <!-- video-style-two end -->

    <!-- video-section -->
    <!-- <section class="video-section">
        <div class="bg-column" >
            <video ref="videoElement" src="https://dawata.s3.ap-southeast-2.amazonaws.com/schedy/SchedEasy.mp4"  style="width: 100%; height: 100%;" controls></video>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12 video-column">
                    <div class="video-inner">
                      
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                    <div id="content_block_04">
                        <div class="content-box">
                            <div class="sec-title"><h2>Schedeasy's Vision of the Future</h2></div>
                            <div class="text">At SchedEasy, our vision is to revolutionize field service management across diverse industries. We envision becoming a pioneering force in delivering streamlined solutions that enhance operational efficiency, customer satisfaction, and industry benchmarks for businesses worldwide. Our aim is to redefine how organizations manage their services, enabling them to achieve peak performance, seamless customer interactions, and setting new standards of excellence.</div>
                            <div class="btn-box"><a href="/try-it-now" class="theme-btn-two">Start Your Trial Now</a></div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <!-- video-section end -->


    <!-- pricing-section -->
    <PricingAddDetails/>

    <section class="pricing-section centred">
        <div class="container">
            <div class="tabs-box">
                <div class="tabs-content">
                    <div class="tab active-tab" id="tab-1">
                        <div class="row" style="justify-content: center;">
                            <div class="col-lg-4 col-md-6 col-sm-12 pricing-column">
                                <div class="pricing-block-one">
                                    <div class="pricing-table">
                                        <!-- <figure class="image"><img src="/images/icons/price-icon-2.png" alt=""></figure> -->
                                        <div class="table-header monthly-t">
                                            <h3 class="title">Single Location</h3>
                                            <h2 class="price">{{ priceMonthSl }}<span>/Mo</span></h2>
                                        </div>
                                        <div class="table-content">
                                            <ul> 
                                                <li>Commitment Free Trial</li>
                                                <li>All features included</li>
                                                <li>Unlimited Users </li>
                                                <li>Schedule and manage jobs</li>
                                                <li>Send quotes and invoices</li>
                                                <li>24/7 client self-serve hub</li>
                                                <li>Automated reminders</li>
                                                <li>Online booking</li>
                                            </ul>
                                        </div>
                                        <div class="table-footer">
                                            <a href="/try-it-now" class="theme-btn-two">Start for Free</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 pricing-column">
                                <div class="pricing-block-one">
                                    <div class="pricing-table">
                                        <!-- <figure class="image"><img src="/images/icons/price-icon-3.png" alt=""></figure> -->
                                        <div class="table-header monthly-t">
                                            <h3 class="title">MULTI LOCATION</h3>
                                            <h2 class="price">{{ priceMonthMl }}<span>/Location</span></h2>
                                        </div>
                                        <div class="table-content">
                                            <ul> 
                                                <ul> 
                                                    <li>2 Locations</li>
                                                    <li>$137/mo Per Additional Locations</li>
                                                    <li>All features included</li>
                                                    <li>Unlimited Users </li>
                                                    <li>Schedule and manage jobs</li>
                                                    <li>Send quotes and invoices</li>
                                                    <li>24/7 client self-serve hub</li>
                                                    <li>Automated reminders</li>
                                                    <li>Multi Location Management</li>
                                                    <li>Franchise system </li>
                                                </ul>
                                            </ul>
                                        </div>
                                        <div class="table-footer">
                                            <a href="https://calendly.com/schedeasy" class="theme-btn-two">Talk with us</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab" id="tab-2">
                        <div class="row" style="justify-content: center;">
                            <div class="col-lg-4 col-md-6 col-sm-12 pricing-column">
                                <div class="pricing-block-one">
                                    <div class="pricing-table">
                                        <!-- <figure class="image"><img src="/images/icons/price-icon-2.png" alt=""></figure> -->
                                        <div class="table-header annual-t">
                                            <h3 class="title">Single Location</h3>
                                            <h2 class="price">{{ priceYearSl }}<span>/Yearly</span></h2>
                                        </div>
                                        <div class="table-content">
                                            <ul> 
                                                <ul>
                                                    <li>Commitment Free Trial</li>
                                                    <li>All features included</li>
                                                    <li>Unlimited Users </li>
                                                    <li>Schedule and manage jobs</li>
                                                    <li>Send quotes and invoices</li>
                                                    <li>24/7 client self-serve hub</li>
                                                    <li>Automated reminders</li>
                                                    <li>Online booking</li>
                                                </ul>
                                            </ul>
                                        </div>
                                        <div class="table-footer">
                                            <a href="/try-it-now" class="theme-btn-two">Start for Free</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 pricing-column">
                                <div class="pricing-block-one">
                                    <div class="pricing-table">
                                        <!-- <figure class="image"><img src="/images/icons/price-icon-3.png" alt=""></figure> -->
                                        <div class="table-header annual-t">
                                            <h3 class="title">ENTERPRISE </h3>
                                            
                                            <h2 class="price">{{ priceYearMl }}<span>/ Location</span></h2>
                                        </div>
                                        <div class="table-content">
                                            <ul> 
                                                <ul> 
                                                    <li>2 Locations</li>
                                                    <li>$ {{ 137 * 10 }}/yr Per Additional Locations</li>
                                                    <li>All features included</li>
                                                    <li>Unlimited Users </li>
                                                    <li>Schedule and manage jobs</li>
                                                    <li>Send quotes and invoices</li>
                                                    <li>24/7 client self-serve hub</li>
                                                    <li>Automated reminders</li>
                                                    <li>Multi Location Management</li>
                                                    <li>Franchise system </li>
                                                </ul>
                                            </ul>
                                        </div>
                                        <div class="table-footer">
                                            <a href="https://calendly.com/schedeasy" class="theme-btn-two">Talk with us</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>           
                </div>
                <div class="tab-btn-box">
                    <ul class="tab-btns tab-buttons clearfix">
                        <li class="tab-btn active-btn" data-tab="#tab-1">Monthly</li>
                        <li class="tab-btn" data-tab="#tab-2">Yearly</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <!-- pricing-section end -->
    </div>
</template>

<script>
import PricingAddDetails from '@/components/pricing_add_detail.vue'

export default {
    components:{PricingAddDetails},

  data() {
    return {
      observer: null,
      videoShouldPlay: false,
      muted: true
    };
  },
  mounted() {
    this.observer = new IntersectionObserver(this.handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5
    });
    this.observer.observe(this.$refs.videoElement);
    	//Submenu Dropdown Toggle
	if($('.main-header li.dropdown ul').length){
		$('.main-header .navigation li.dropdown').append('<div class="dropdown-btn"><span class="fa fa-angle-right"></span></div>');
		
	}

    if($('.tabs-box').length){
		$('.tabs-box .tab-buttons .tab-btn').on('click', function(e) {
			e.preventDefault();
			var target = $($(this).attr('data-tab'));
			
			if ($(target).is(':visible')){
				return false;
			}else{
				target.parents('.tabs-box').find('.tab-buttons').find('.tab-btn').removeClass('active-btn');
				$(this).addClass('active-btn');
				target.parents('.tabs-box').find('.tabs-content').find('.tab').fadeOut(0);
				target.parents('.tabs-box').find('.tabs-content').find('.tab').removeClass('active-tab');
				$(target).fadeIn(300);
				$(target).addClass('active-tab');
			}
		});
	}
    // Listen for user interaction to play the video
    window.addEventListener('click', this.handleUserInteraction);
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
    window.removeEventListener('click', this.handleUserInteraction);
  },
  methods: {
    handleIntersection(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.videoShouldPlay = true;
        } else {
          this.videoShouldPlay = false;
          this.$refs.videoElement.pause();
        }
      });
    },
    handleUserInteraction() {
      if (this.videoShouldPlay) {
        this.muted = false;
        this.$refs.videoElement.play();
      }
    }
  }
};
</script>






<style lang="scss" scoped>
.save_more{
    background: #ef4444;
    color: #fff;
    padding: 5px;
    border-radius: 25px;
}
.monthly-t{
    .price:before {
        left: 35px!important;
    }
}

.annual-t{
    .price:before {
        left: 13%!important;
    }
}
.inner-box{
    margin-bottom: 0px!important;
    padding-bottom: 50px!important;
}
#iamge_block_02 .image-box .image-1 {
    position: absolute;
    top: 85px;
    right: -170px;
    z-index: 1;
    width: 800px;
}
#iamge_block_02 .image, #iamge_block_03 .image {
    box-shadow:unset;
}

#image_block_14 .image-box .image-1 img {
    max-width: none;
    float: left;
    width: 850px;
}
.schedeasy-feature1{
    width: 327px;
    top: 264px !important;
    left: 22px !important;
    box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1)!important;
    border-radius: 18px;
    img{
        border-radius: 18px;
    }

}

.schedeasy-feature2{
    width: 327px;
    top: 67px !important;
    left: 264px !important;
    box-shadow: 0 5px 20px 0px rgba(66, 66, 66, 0.1)!important;
    border-radius: 18px;
    img{
        border-radius: 18px;
    }
}

.schedeasy-feature3{
    width: 327px;
    top: 422px !important;
    left: 299px !important;
    box-shadow: 0 5px 20px 0px rgba(66, 66, 66, 0.1)!important;
    border-radius: 18px;
    img{
        border-radius: 18px;
    }
}

.pricing-block-one .pricing-table .table-content {
    position: relative;
    text-align: left;
    display: flex;
    justify-content: center;
    padding: 20px;
}

.pricing-block-one .pricing-table .table-footer{
    display: flex;
    justify-content: center;
    padding: 20px;
}



.video-section .bg-column:before {
    position: absolute;
    content: '';
    background: rgb(127 127 127 / 16%);
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.feature-style-three {
    position: relative;
    background: #f9f9f9;
    padding: 100px 0px 0px 0px;
}
.download-btn{
    line-height: 15px;
}
.g-play{
    position: relative;
    display: flex;
    font-size: 20px;
    font-weight: 500;
    color: #4527a4;
    background:#fff;
    border:2px solid #4527a4;
    margin:10px 0px;
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 10;
    .fab{
        font-size: 40px;
        margin-right: 10px;        
    }
    .taker{
        display: flex;
        flex-direction: column;
        span{
            font-size: 15px;
        }
    }

}
.j-fig-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .fig-holders{
        position:relative;
        width: 100%;
        height: 100%;
        padding:30px
    }

}

.section-video{
    .video-inner{
        padding: 20px!important;
    }
}

.feature-style-two{
    background-color: #f9f9f9;
}

.feature-style-two:before{
    width: unset!important;
    height: unset!important;
}

.feature-style-two{
    .feature-block{
        padding: 20px;
    }
}


@media only screen and (max-width: 991px){
    .feature-style-six .inner-box:first-child .image-box {
        z-index: -10;
    }
}


@media only screen and (max-width: 1440px){
    #app{
        .section-3{
            .bg-layer{
                width: 704px !important;
                left: 12px !important;
                top: -128px !important;
                height: 757px;
                background-size: contain!important;
            }
            img{
                width: 700px!important;
            }
        }
    }
}

@media only screen and (max-width: 1350px){
    #app{
        .section-3{
            .bg-layer{
                left: 97px!important;
                top: -183px !important;

            }
            img{
                width: 600px!important;
            }
        }

        .section-4{
            .bg-layer{
                width: 579px;
                height: 689px;
                background-size: contain;
                left: 43px;
                top: -75px;
                .fig-holders{
                    .schedeasy-feature1{
                        width:280px;
                    }
                    .schedeasy-feature2{
                        width:280px;
                        top: 94px !important;

                    }
                    .schedeasy-feature3{
                        width: 280px;
                        top: 389px!important;
                        left: 243px !important;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1199px){
    .section-4{
            .bg-layer{
                left: -8px !important;
                top: 122px!important;
            }
        }
}

@media only screen and (max-width: 992px){
        .section-4{
            .bg-layer{
                left: -8px !important;
                top: 122px!important;
            }

            #content_block_03 {
                .content-box{
                     margin-left: 0px!important;

                }
            }

        }
        .subscribe-section{
            .bg-layer{
                left: -40px!important;
            }
        }
}




</style>