<template>
    <div>
      <div class="service-details-content">
        <div class="inner-box">
          <div class="top-content">
            <div class="sec-title">
              <h1>Elevate Your Tree Care Business with Schedeasy</h1>
            </div>
            <div class="text">
              <h2>Effortlessly Care for Trees, Amplify Customer Satisfaction</h2>
              <p>
                Caring for trees can be complex. Elevate your tree care business with Schedeasy, making tree care effortless and amplifying customer satisfaction.
              </p>
            </div>
            <figure class="image-box">
              <img :src="'/images/schedeasy/industry/details/' + filteredServices[0].wide_image" alt="">
            </figure>
            <div>
              <h1>Revolutionizing Tree Care Services with Schedeasy</h1>
              <div class="text">
                <h2>Enhancing Efficiency and Elevating Arborist Excellence</h2>
                <p>
                  Managing a tree care services business presents a unique set of challenges – scheduling tree health assessments, coordinating tree maintenance teams, and ensuring the well-being of trees. These intricacies often lead to scheduling conflicts, communication gaps, and client concerns about the health and safety of their trees. That's where Schedeasy steps in as the transformative solution for tree care service providers. With its intelligent scheduling for tree care assignments, real-time communication tools for team coordination, and streamlined client management, Schedeasy is custom-tailored to the tree care industry. It's more than just software; it's the cornerstone of efficient operations and exceptional arborist excellence. Amidst the complexities of tree care services, Schedeasy emerges as the ultimate answer, propelling businesses to new heights of success while ensuring healthy, beautiful, and safe trees for clients and their properties.
                </p>
              </div>
            </div>
          </div>
          <div class="two-column">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                <div class="content-box">
                  <div>
                    <h3>Advanced Calendar Features</h3>
                    <ul>
                      <li>
                        Elevate your tree care scheduling with Advanced Calendar Features.
                      </li>
                      <li>
                        Use an intuitive drag-and-drop functionality to schedule and reschedule appointments, tasks, and events effortlessly.
                      </li>
                      <li>
                        Assign tree care jobs to specific dates and times, and make quick adjustments as needed.
                      </li>
                      <li>
                        Visualize your calendar with ease to optimize your workflow and time management.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Vouchers/Gift Cards</h3>
                    <ul>
                      <li>
                        Boost customer loyalty and attract new clients with Vouchers/Gift Cards.
                      </li>
                      <li>
                        Create redeemable vouchers with specific quantities and expiration dates to offer discounts or promotional deals.
                      </li>
                      <li>
                        Easily track redeemed vouchers to gauge their effectiveness in driving business growth.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Reporting</h3>
                    <ul>
                      <li>
                        Gain valuable insights into your tree care service business with Reporting.
                      </li>
                      <li>
                        Choose from pre-set or custom reports to access data on various aspects of your operations.
                      </li>
                      <li>
                        Analyze performance metrics, customer trends, and revenue growth to make informed business decisions and plan for the future.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms">
                  <img :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt="">
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {};
    },
    mounted() {}
  };
  </script>
  