import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toasted from 'vue-toasted'
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
Vue.use(Toasted)

Vue.config.productionTip = false
Vue.mixin({
  computed: {
    filteredServices() {
      const currentRouteName = this.$route.name;
      return this.services.filter(service => service.route_name === currentRouteName);
      },
      filteredFeatures() {
        const currentRouteName = this.$route.name;
        return this.features.filter(feature => feature.route_name === currentRouteName);
        }
  },
  data: function() {
    return {
      priceMonthSl:'337.00',
      priceYearSl:'3000.00',
      priceMonthNoZeroSl:'337',
      priceYearNoZeroSl:'3000',

      priceMonthMl:'537.00',
      priceYearMl:'5370.00',
      priceMonthNoZeroMl:'537',
      priceYearNoZeroMl:'5370',
      services: [
        {
          title: 'Appliance Repair Business Management System',
          image: 'appliance.png',
          wide_image: 'wide_appliance.png',
          description: 'Efficient Appliance Repair Management with Scheduling Software',
          route_name: 'IndustryAppliance',
        },
        {
          title: 'Chimney Sweeping Business Management System',
          image: 'chimney.png',
          description: 'Streamline Chimney Sweeping Services with Scheduling Software',
          route_name: 'IndustryChimney',
          wide_image: 'wide_chimney.png',
        },
       
        {
          title: 'Commercial Cleaning Business Management System',
          image: 'commercial.png',
          description: 'Elevate Commercial Cleaning Efficiency with Scheduling Software',
          route_name: 'IndustryCommercial',
          wide_image: 'wide_commercial.png',
        },
        {
          title: 'Drywall Business Management System',
          image: 'drywall.png',
          description: 'Effortless Drywall Project Management with Scheduling Software',
          route_name: 'IndustryDrywall',
          wide_image: 'wide_drywall.png',
        },
        {
          title: 'Garage Business Management System',
          image: 'garage.png',
          description: 'Efficient Garage Service Scheduling with Software',
          route_name: 'IndustryGarage',
          wide_image: 'wide_garage.png',
        },
        {
          title: 'Lawn Care Business Management System',
          image: 'lawncare.png',
          description: 'Revolutionize Lawn Care Services with Scheduling Software',
          route_name: 'IndustryLawnCare',
          wide_image: 'wide_lawncare.png',
        },
        {
          title: 'Residential Cleaner Business Management System',
          image: 'residential.png',
          description: 'Seamless Residential Cleaning with Scheduling Software',
          route_name: 'IndustryResidential',
          wide_image: 'wide_residential.png',
        },
        {
          title: 'Roofer Business Management System',
          image: 'roofer.png',
          description: 'Efficient Roofing Services with Scheduling Software',
          route_name: 'IndustryRoofer',
          wide_image: 'wide_roofer.png',
        },
        {
          title: 'Locksmith Business Management System',
          image: 'locksmith.png',
          description: 'Streamlined Locksmith Services with Scheduling Software',
          route_name: 'IndustryLocksmith',
          wide_image: 'wide_locksmith.png',
        },
        {
          title: 'Landscaping Business Management System',
          image: 'landscape.png',
          description: 'Optimize Landscaping Projects with Scheduling Software',
          route_name: 'IndustryLandScaping',
          wide_image: 'wide_landscape.png',
        },
        {
          title: 'Carpenter Business Management System',
          image: 'carpenter.png',
          description: 'Efficient Carpentry Management with Scheduling Software',
          route_name: 'IndustryCarpenter',
          wide_image: 'wide_carpenter.png',
        },
        {
          title: 'Carpet Cleaning Business Management System',
          image: 'carpet_cleaning.png',
          description: 'Revolutionize Carpet Cleaning with Scheduling Software',
          route_name: 'IndustryCarpetCleaning',
          wide_image: 'wide_carpet.png',
        },
        {
          title: 'Construction Business Management System',
          image: 'construction.png',
          description: 'Efficient Construction Project Management with Scheduling Software',
          route_name: 'IndustryConstruction',
          wide_image: 'wide_construction.png',
        },
        {
          title: 'Dog Walker Business Management System',
          image: 'dog_walker.png',
          description: 'Seamless Dog Walking Services with Scheduling Software',
          route_name: 'IndustryDogwalker',
          wide_image: 'wide_dogwalker.png',
        },
        {
          title: 'Fence Business Management System',
          image: 'fence.png',
          description: 'Streamline Fence Installation with Scheduling Software',
          route_name: 'IndustryFence',
          wide_image: 'wide_fence.png',
        },
        {
          title: 'General Contractor Business Management System',
          image: 'general_contractor.png',
          description: 'Optimize General Contracting with Scheduling Software',
          route_name: 'IndustryGeneralContractor',
          wide_image: 'wide_generalcontractor.png',
        },
        {
          title: 'Handyman Business Management System',
          image: 'handyman.png',
          description: 'Effortless Handyman Services with Scheduling Software',
          route_name: 'IndustryHandyman',
          wide_image: 'wide_handyman.png',
        },
        {
          title: 'HVAC Business Management System',
          image: 'havac.png',
          description: 'Efficient HVAC Services with Scheduling Software',
          route_name: 'IndustryHavac',
          wide_image: 'wide_hvac.png',
        },
        {
          title: 'Irrigation Business Management System',
          image: 'irragation.png',
          description: 'Revolutionize Irrigation Management with Scheduling Software',
          route_name: 'IndustryIrragation',
          wide_image: 'wide_irrigation.png',
        },
        {
          title: 'Junk Removal Business Management System',
          image: 'junk_removal.png',
          description: 'Efficient Junk Removal Services with Scheduling Software',
          route_name: 'IndustryJunkRemoval',
          wide_image: 'wide_junkremoval.png',
        },
        {
          title: 'Mechanical Contractor Business Management System',
          image: 'mechanical_contractor.png',
          description: 'Streamlined Mechanical Contracting with Scheduling Software',
          route_name: 'IndustryMechContractor',
          wide_image: 'wide_mechanical.png',
        },
        {
          title: 'Plumber Business Management System',
          image: 'plumber.png',
          description: 'Optimize Plumbing Services with Scheduling Software',
          route_name: 'IndustryPlumber',
          wide_image: 'wide_plumber.png',
        },
        {
          title: 'Pressure Washing Business Management System',
          image: 'pressure_washing.png',
          description: 'Efficient Pressure Washing with Scheduling Software',
          route_name: 'IndustryPressureWashing',
          wide_image: 'wide_pressure.png',
        },
        {
          title: 'Snow Removal Business Management System',
          image: 'snow_removal.png',
          description: 'Seamless Snow Removal Services with Scheduling Software',
          route_name: 'IndustrySnowRemoval',
          wide_image: 'wide_snowremoval.png',
        },
        {
          title: 'Tree Care Business Management System',
          image: 'tree_care.png',
          description: 'Revolutionize Tree Care Management with Scheduling Software',
          route_name: 'IndustryTreeCare',
          wide_image: 'wide_treecare.png',
        },
        {
          title: 'Window Cleaning Business Management System',
          image: 'window.png',
          description: 'Efficient Window Cleaning with Scheduling Software',
          route_name: 'IndustryWindow',
          wide_image: 'wide_window.png',
        },
        {
          title: 'Electrical Business Management System',
          image: 'electrical.png',
          description: 'Streamlined Electrical Services with Scheduling Software',
          route_name: 'IndustryElectrical',
          wide_image: 'wide_electrical.png',
        },
        {
          title: 'Flooring Business Management System',
          image: 'flooring.png',
          description: 'Optimize Flooring Projects with Scheduling Software',
          route_name: 'IndustryFlooring',
          wide_image: 'wide_flooring.png',
        },
        {
          title: 'Janitorial Business Management System',
          image: 'janitorial.png',
          description: 'Effortless Janitorial Services with Scheduling Software',
          route_name: 'IndustryJanitorial',
          wide_image: 'wide_janitorial.png',
        },
        {
          title: 'Elevator Service Business Management System',
          image: 'elevator.png',
          description: 'Efficient Elevator Services with Scheduling Software',
          route_name: 'IndustryElevator',
          wide_image: 'wide_elevator.png',
        },
        {
          title: 'Installation Service Business Management System',
          image: 'installation.png',
          description: 'Revolutionize Installation Services with Scheduling Software',
          route_name: 'IndustryInstallationService',
          wide_image: 'wide_installation.png',
        },
        {
          title: 'Painting Service Business Management System',
          image: 'painter.png',
          description: 'Seamless Painting Services with Scheduling Software',
          route_name: 'IndustryPaintingService',
          wide_image: 'wide_painting.png',
        },
        {
          title: 'Property Maintenance Business Management System',
          image: 'property.png',
          description: 'Efficient Property Maintenance with Scheduling Software',
          route_name: 'IndustryPropertyMaintenance',
          wide_image: 'wide_property.png',
        },
        {
          title: 'Remodeling Business Management System',
          image: 'remodeling.png',
          description: 'Optimize Remodeling Projects with Scheduling Software',
          route_name: 'IndustryRemodeling',
          wide_image: 'wide_remodeling.png',
        },
        {
          title: 'Restoration Business Management System',
          image: 'restoration.png',
          description: 'Effortless Restoration Services with Scheduling Software',
          route_name: 'IndustryRestoration',
          wide_image: 'wide_restoration.png',
        },
      ]
      ,
      features: [
        {
            name: "Invoice Details",
            description: "Effortless Invoice Management: Dive into the Details",
            route_name: "FeatInvoiceDetails",
            video_path: "https://schedeasyimages.s3.ca-central-1.amazonaws.com/v3LandingPage/videos/multi.mp4"
        },
        {
            name: "Multi-Location Management",
            description: "Master Multi-Location Management with Ease",
            route_name: "FeatMultiLocationManagement",
            video_path: "https://schedeasyimages.s3.ca-central-1.amazonaws.com/v3LandingPage/videos/multi.mp4"
        },
        {
            name: "Staff Management",
            description: "Streamline Staff Management for Efficiency",
            route_name: "FeatStaffManagement",
            video_path: "https://schedeasyimages.s3.ca-central-1.amazonaws.com/v3LandingPage/videos/Staff+Management.mp4"
        },
        {
            name: "Job Details",
            description: "Explore Job Details Like Never Before",
            route_name: "FeatJobDetails",
            video_path: "https://schedeasyimages.s3.ca-central-1.amazonaws.com/v3LandingPage/videos/Job+Details+.mp4"
        },
        {
            name: "Customer Management",
            description: "Elevate Customer Relationships with Effective Management",
            route_name: "FeatCustomerManagement",
            video_path: "https://schedeasyimages.s3.ca-central-1.amazonaws.com/v3LandingPage/videos/Customer+Management+.mp4"
        },
        {
            name: "Advanced Calendar Features",
            description: "Optimize Scheduling with Advanced Calendar Features",
            route_name: "FeatAdvanceCalendar",
            video_path: "https://schedeasyimages.s3.ca-central-1.amazonaws.com/v3LandingPage/videos/Calendar+feature.mp4"
        },
        {
            name: "Franchise System",
            description: "Efficient Franchise Management at Your Fingertips",
            route_name: "FeatFranchiseSystem",
            video_path: "https://schedeasyimages.s3.ca-central-1.amazonaws.com/v3LandingPage/videos/franchise.mp4"
        },
        {
            name: "Invoice Management",
            description: "Effortless Invoice Management for Your Business",
            route_name: "FeatInvoiceManagement",
            video_path: "https://schedeasyimages.s3.ca-central-1.amazonaws.com/v3LandingPage/videos/Invoice+Management+.mp4"
        },
        {
            name: "Batch Invoice",
            description: "Simplify Billing with Batch Invoice Management",
            route_name: "FeatBatchInvoicing",
            video_path: "https://schedeasyimages.s3.ca-central-1.amazonaws.com/v3LandingPage/videos/Batch+invoice.mp4"
        },
        {
            name: "Communication Funnel",
            description: "Enhance Customer Engagement with the Communication Funnel",
            route_name: "FeatCommunicationFunnel",
            video_path: "https://schedeasyimages.s3.ca-central-1.amazonaws.com/v3LandingPage/videos/Communication+funnel.mp4"
        },
        {
            name: "Dashboard",
            description: "Gain Insights with a Powerful Dashboard",
            route_name: "FeatDashboard",
            video_path: "https://schedeasyimages.s3.ca-central-1.amazonaws.com/v3LandingPage/videos/Dashboard.mp4"
        },
        {
            name: "Job Management System",
            description: "Streamline Job Management with Our System",
            route_name: "FeatJobManagement",
            video_path: "https://schedeasyimages.s3.ca-central-1.amazonaws.com/v3LandingPage/videos/Job+Management.mp4"
        },
        {
            name: "Vouchers/Gift Cards",
            description: "Boost Sales with Vouchers and Gift Cards",
            route_name: "FeatVoucher",
            video_path: "https://schedeasyimages.s3.ca-central-1.amazonaws.com/v3LandingPage/videos/Vouchers.mp4"
        },
        {
            name: "Reporting",
            description: "Unlock Insights with Comprehensive Reporting",
            route_name: "FeatReporting",
            video_path: ""
        },
        {
            name: "Activity Management System",
            description: "Optimize Activity Management for Efficiency",
            route_name: "FeatActivityManagementSystem",
            video_path: "https://schedeasyimages.s3.ca-central-1.amazonaws.com/v3LandingPage/videos/Activity+Management.mp4"
        },
        {
            name: "Live Support",
            description: "Get Real-Time Support with Live Assistance",
            route_name: "FeatLiveSupport",
            video_path: "https://schedeasyimages.s3.ca-central-1.amazonaws.com/v3LandingPage/videos/Live+chat.mp4"
        },
        {
            name: "Route Optimization",
            description: "Optimize Routes for Maximum Efficiency",
            route_name: "FeatRouteOptimizer",
            video_path: "https://schedeasyimages.s3.ca-central-1.amazonaws.com/v3LandingPage/videos/route+optimizer.mp4"
        },
        {
            name: "Lead Management",
            description: "Master Lead Management for Business Growth",
            route_name: "FeatLeadManagement",
            video_path: "https://schedeasyimages.s3.ca-central-1.amazonaws.com/v3LandingPage/videos/Lead+Management.mp4"
        },
        {
            name: "Time and Attendance",
            description: "Efficient Time and Attendance Tracking for Your Team",
            route_name: "FeatTimeAttendance",
            video_path: "https://schedeasyimages.s3.ca-central-1.amazonaws.com/v3LandingPage/videos/Time+and+Attendance.mp4"
        }
    ],
    
      faq:[
        {
          "q": "What makes Schedeasy's pricing unique?",
          "a": "Schedeasy's pricing stands out for its comprehensive inclusion of all features. Unlike competitors who often charge extra for specific functionalities, our pricing covers everything from advanced scheduling to powerful reporting. Moreover, it includes unlimited users, both administrative and field staff."
        },
        {
          "q": "Is there any feature that's not part of Schedeasy's pricing?",
          "a": "Not at all. Schedeasy's pricing encompasses the entire spectrum of features, ensuring you have the tools needed for effective field service Management. Whether it's job Management, customer interactions, or route optimization, everything is included."
        },
        {
          "q": "Is Schedeasy's pricing suitable for businesses of all sizes?",
          "a": "Absolutely. Schedeasy's pricing is designed to be accessible to all businesses, from startups to large enterprises. It offers flexibility and scalability, accommodating the needs of any organization, big or small."
        },
        {
          "q": "Will my pricing change as my business grows, or if I add more users, admin staff, or field staff?",
          "a": "No, you won't experience unexpected price increases with Schedeasy. Our pricing remains consistent and friendly, even as your business expands. You can add unlimited users, admin staff, and field staff without worrying about budgetary surprises."
        },
        {
          "q": "Can I explore Schedeasy's features before making a commitment?",
          "a": "Certainly! We encourage you to take advantage of our No Commitment free trial, no credit card required. This allows you to experience the full power of Schedeasy, including all features and the capability to add as many users as you need."
        },
        {
          "q": "Where can I get more information or ask additional questions about Schedeasy's pricing and features?",
          "a": "If you have further inquiries about our pricing or features, please don't hesitate to reach out to our dedicated support team. We're here to assist you and provide any information you need."
        }
      ]
    }
  },
	
})
new Vue({
  router,
  store,
  // vuetify,
  render: h => h(App)
}).$mount('#app')
