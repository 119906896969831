<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Revolutionize Residential Cleaning with Schedeasy</h1>
                    </div>
                    <div class="text">
                        <h2>Simplify Operations, Elevate Customer Satisfaction</h2>
                        <p>
                            Are the challenges of coordinating residential cleaning appointments, organizing cleaning teams, and addressing client inquiries wearing you down? Welcome Schedeasy for Residential Cleaning – the ultimate solution for effortless on-site service management.
                        </p>
                    </div>
                    <figure class="image-box"><img :src="'/images/schedeasy/industry/details/'+filteredServices[0].wide_image" alt=""></figure>
                    <div>
                        <h1>Transforming Residential Cleaning Services with Schedeasy</h1>
                        <div class="text">
                            <h2>Simplifying Operations and Elevating Customer Satisfaction</h2>
                            <p>
                                Managing a residential cleaning business comes with its array of challenges – scheduling appointments, coordinating cleaning teams, and delivering impeccable service. These complexities often lead to scheduling clashes, communication lapses, and client dissatisfaction. That's where Schedeasy steps in as the revolutionary solution. With its intuitive appointment scheduling, real-time communication tools, and streamlined client management, Schedeasy is tailored to the residential cleaning industry. It's more than just software; it's the foundation of efficient operations and unparalleled client experiences. Amid the intricacies of residential cleaning, Schedeasy emerges as the ultimate answer, propelling businesses to new heights of success.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="two-column">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div class="content-box">
                                <div>
                                    <h3>Invoice Details Customization</h3>
                                    <ul>
                                        <li>
                                            Tailor invoices to reflect the branding of your residential cleaning business.
                                        </li>
                                        <li>
                                            Enhance your professional image by incorporating promotional images highlighting your cleaning services.
                                        </li>
                                        <li>
                                            Personalize invoice footers and include special notes or discounts for a personalized touch.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Multi-Location Management</h3>
                                    <ul>
                                        <li>
                                            Streamline the management of multiple residential cleaning locations, whether you have branch offices or serve different neighborhoods.
                                        </li>
                                        <li>
                                            Centralize your operations, allowing you to monitor each location's performance and access relevant data from a single platform.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Staff Management</h3>
                                    <ul>
                                        <li>
                                            Simplify staff management by maintaining detailed records of staff information, including contact details, schedules, and performance records.
                                        </li>
                                        <li>
                                            Efficiently assign cleaning tasks to staff members based on their availability, expertise, and location.
                                        </li>
                                        <li>
                                            Manage staff permissions and access levels to ensure smooth coordination among your cleaning team.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                            <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms"><img  :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt=""></figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{

            }
        },
        mounted(){

        }
    }
</script>
