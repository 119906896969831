<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Elevate Your Flooring Installation Business with Schedeasy</h1>
                    </div>
                    <div class="text">
                        <h2>Streamline Operations, Enhance Customer Satisfaction</h2>
                        <p>
                            Feeling overwhelmed by the demands of flooring installation appointments, coordinating teams, and managing client communications? Welcome Schedeasy for Flooring Installations – the ultimate solution for seamless on-site service coordination.
                        </p>
                    </div>
                    <figure class="image-box"><img :src="'/images/schedeasy/industry/details/'+filteredServices[0].wide_image" alt=""></figure>
                    <div>
                        <h1>Revolutionizing Flooring Installer Operations with Schedeasy</h1>
                        <div class="text">
                            <h2>Enhancing Efficiency and Elevating Flooring Excellence</h2>
                            <p>
                                Running a flooring installer business presents its own set of challenges – managing intricate projects, coordinating skilled teams, and delivering impeccable craftsmanship. These complexities often lead to project delays, communication gaps, and client dissatisfaction. That's where Schedeasy steps in as the transformative solution. With its intelligent project scheduling, real-time communication tools, and streamlined client management, Schedeasy is tailor-made for the flooring industry. It's more than just software; it's the cornerstone of efficient operations and exceptional flooring solutions. Amidst the intricacies of flooring installation, Schedeasy emerges as the ultimate answer, propelling businesses to new heights of success.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="two-column">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div class="content-box">
                                <div>
                                    <h3>Job Details</h3>
                                    <ul>
                                        <li>
                                            Efficiently manage and track all your flooring installation projects with the Job Details feature.
                                        </li>
                                        <li>
                                            Customize job statuses to categorize tasks effectively, such as "Preparation," "Installation," or "Finishing."
                                        </li>
                                        <li>
                                            Capture client signatures within the system to eliminate paperwork and enhance project documentation for flooring installation assignments.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Invoice Details Customization</h3>
                                    <ul>
                                        <li>
                                            Personalize invoices to reflect your flooring installation business's branding and professionalism.
                                        </li>
                                        <li>
                                            Incorporate promotional images showcasing your flooring work or specializations.
                                        </li>
                                        <li>
                                            Customize invoice footers with essential information, notes, or discounts to enhance client communication and satisfaction.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Live Support</h3>
                                    <ul>
                                        <li>
                                            Provide dedicated customer support with the Live Support feature for flooring installation services.
                                        </li>
                                        <li>
                                            Ensure that assistance is readily available for clients, technicians, and project managers whenever they need it.
                                        </li>
                                        <li>
                                            Enhance communication, resolve issues promptly, and maintain a high level of customer satisfaction in the flooring installation industry.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                            <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms"><img  :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt=""></figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{

            }
        },
        mounted(){

        }
    }
</script>
