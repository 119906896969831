<template>
    <div>
      <div class="service-details-content">
        <div class="inner-box">
          <div class="top-content">
            <div class="sec-title">
              <h1>Elevate Property Maintenance with Schedeasy</h1>
            </div>
            <div class="text">
              <h2>Streamline Property Care, Enhance Customer Satisfaction</h2>
              <p>
                Property maintenance made easy. Elevate your property maintenance services with Schedeasy, streamlining property care and enhancing overall customer satisfaction.
              </p>
            </div>
            <figure class="image-box">
              <img :src="'/images/schedeasy/industry/details/' + filteredServices[0].wide_image" alt="">
            </figure>
            <div>
              <h1>Revolutionizing Property Maintenance with Schedeasy</h1>
              <div class="text">
                <h2>Enhancing Efficiency and Elevating Property Care</h2>
                <p>
                  Managing a property maintenance services business encompasses a range of challenges – scheduling maintenance tasks, coordinating maintenance teams, and ensuring the overall well-being of properties. These intricacies often lead to scheduling conflicts, communication gaps, and client concerns about property upkeep. That's where Schedeasy steps in as the transformative solution for property maintenance service providers. With its intelligent scheduling for maintenance assignments, real-time communication tools for team coordination, and streamlined client management, Schedeasy is custom-tailored to the property maintenance industry. It's more than just software; it's the cornerstone of efficient operations and exceptional property care. Amidst the complexities of property maintenance services, Schedeasy emerges as the ultimate answer, propelling businesses to new heights of success while ensuring well-maintained and satisfied properties for clients.
                </p>
              </div>
            </div>
          </div>
          <div class="two-column">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                <div class="content-box">
                  <div>
                    <h3>Job Management System</h3>
                    <ul>
                      <li>
                        Simplify the management of property maintenance tasks with the Job Management System feature.
                      </li>
                      <li>
                        Customize job statuses to categorize tasks effectively, such as "Scheduled," "In Progress," or "Completed."
                      </li>
                      <li>
                        Send timely reminders to clients and staff with timestamps to ensure smooth task execution.
                      </li>
                      <li>
                        Capture client signatures within the system, eliminating paperwork and enhancing document management.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Batch Invoicing</h3>
                    <ul>
                      <li>
                        Streamline your invoicing process with the Batch Invoicing feature for property maintenance services.
                      </li>
                      <li>
                        Consolidate multiple property maintenance tasks into a single, comprehensive invoice for clients' convenience.
                      </li>
                      <li>
                        Access detailed task information directly from invoices, making it easy to review and manage billing for multiple tasks simultaneously.
                      </li>
                      <li>
                        Set default payment statuses for efficient management.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Route Optimization</h3>
                    <ul>
                      <li>
                        Optimize the routes for your property maintenance service crews with the Route Optimization feature.
                      </li>
                      <li>
                        Efficiently plan and assign tasks based on job locations, crew availability, and priority.
                      </li>
                      <li>
                        Minimize travel time, reduce fuel costs, and improve overall productivity by ensuring that your crews follow optimized routes.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms">
                  <img :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt="">
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {};
    },
    mounted() {}
  };
  </script>
  