<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>  Real-Time Assistance with Live Support</h1>
                    </div>
                    <div class="text">
                        <h2>Access Expert Guidance When You Need It</h2>
                        <p>In the fast-paced world of field service management, having instant access to expert assistance can be a game-changer, and our software's "Live Support" feature provides real-time assistance when you need it most.</p>
                    </div>
                    <div data-v-1e9dea97="" style="width: 100%;"><video data-v-1e9dea97="" autoplay :src="filteredFeatures[0].video_path" controls="controls" style="width: 100%;"></video></div>
                </div>
                <section class="recruitment-section elements sec-pad-two centred">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Instant Assistance:</a></h3>
                                        <div class="text">Live Support offers a direct channel to our support team, allowing you to get instant assistance with any software-related queries, technical issues, or operational challenges.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-intersect-1"></i></div>
                                        <h3><a href="#">Remote Troubleshooting</a></h3>
                                        <div class="text">Our support team can remotely access your system (with your permission) to diagnose and resolve issues efficiently, minimizing downtime and disruptions to your operations.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInRight" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-hiring"></i></div>
                                        <h3><a href="#">Training and Guidance</a></h3>
                                        <div class="text"> Receive on-the-spot training and guidance on how to make the most of our software's features. Whether you're a new user or seeking advanced tips, our experts are here to help.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInRight" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-hiring"></i></div>
                                        <h3><a href="#">Availability</a></h3>
                                        <div class="text">Live Support is available during business hours to ensure that you have access to expert assistance precisely when you need it.</div>
                                    </div>
                                </div>
                            </div>
                           
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInRight" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-hiring"></i></div>
                                        <h3><a href="#">Knowledge Sharing</a></h3>
                                        <div class="text">Benefit from the collective knowledge and experience of our support team. They can provide best practices, insights, and recommendations to optimize your field service operations.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms"><img src="/images/schedeasy/features/images/live_support.png" alt=""></figure>
                            </div>

                        </div>
                    </div>
                </section>
              
                <div class="bottom-content">
                    <div class="text">
                        <p>Live Support is your lifeline to expert assistance, ensuring that you can maximize the benefits of our software and overcome any challenges quickly and effectively.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.new-image{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>