<template>
    <div>
      <div class="service-details-content">
        <div class="inner-box">
          <div class="top-content">
            <div class="sec-title">
              <h1>Elevate Your Junk Removal Business with Schedeasy</h1>
            </div>
            <div class="text">
              <h2>Effortlessly Manage Jobs, Amplify Customer Satisfaction</h2>
              <p>
                Is junk removal proving to be a cumbersome task? Say goodbye to the hassle of handling junk removal jobs and amplify customer satisfaction with Schedeasy for Junk Removal – simplifying your operations.
              </p>
            </div>
            <figure class="image-box">
              <img :src="'/images/schedeasy/industry/details/' + filteredServices[0].wide_image" alt="">
            </figure>
            <div>
              <h1>Transforming Junk Removal Services with Schedeasy</h1>
              <div class="text">
                <h2>Simplifying Operations and Elevating Cleanliness Standards</h2>
                <p>
                  Operating a junk removal business presents a unique set of challenges – managing cluttered schedules, coordinating removal teams, and ensuring responsible disposal. These intricacies often lead to scheduling conflicts, communication gaps, and client concerns about environmental responsibility. Enter Schedeasy, the transformative solution for junk removal service providers. With its intelligent scheduling for pickups, real-time communication tools for team coordination, and streamlined client management, Schedeasy is tailored to the junk removal industry. It's more than just software; it's the foundation of efficient operations and exceptional cleanliness standards. Amidst the complexities of junk removal services, Schedeasy emerges as the ultimate answer, propelling businesses to new heights of success while ensuring responsible disposal and satisfied clients.
                </p>
              </div>
            </div>
          </div>
          <div class="two-column">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                <div class="content-box">
                  <div>
                    <h3>Activity Management System</h3>
                    <ul>
                      <li>
                        Efficiently manage activities and tasks related to junk removal with the Activity Management System feature.
                      </li>
                      <li>
                        Schedule pickups, assign tasks to your team, and set reminders for timely collections.
                      </li>
                      <li>
                        Keep track of pending activities, ensuring that no junk removal appointments are missed.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Live Support</h3>
                    <ul>
                      <li>
                        Offer dedicated customer support with the Live Support feature for junk removal services.
                      </li>
                      <li>
                        Ensure that clients can reach out for assistance or inquiries whenever they need it.
                      </li>
                      <li>
                        Enhance communication, resolve issues promptly, and maintain high customer satisfaction in the junk removal industry.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3>Job Details</h3>
                    <ul>
                      <li>
                        Manage and track junk removal projects effectively with the Job Details feature.
                      </li>
                      <li>
                        Customize job statuses to categorize tasks, such as "Scheduled," "Completed," or "Special Handling."
                      </li>
                      <li>
                        Capture essential project details, including client information, service descriptions, and pricing, all in one place.
                      </li>
                      <li>
                        Track staff clock-in/out times, update job details, and send emails to clients directly for improved project management.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                <figure class="image-box wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms">
                  <img :src="`/images/schedeasy/industry/originals/${filteredServices[0].image}`" alt="">
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {};
    },
    mounted() {}
  };
  </script>
  