<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Scaling with Multi-Location Management</h1>
                    </div>
                    <div class="text">
                        <h2>Maintain Control and Consistency</h2>
                        <p>For businesses with multiple service locations, our software's "Multi-Location Management" feature is the key to streamlining operations and ensuring consistency across all branches.</p>
                    </div>
                    <div data-v-1e9dea97="" style="width: 100%;"><video data-v-1e9dea97="" autoplay :src="filteredFeatures[0].video_path" controls="controls" style="width: 100%;"></video></div>

                </div>
                <section class="recruitment-section elements sec-pad-two centred">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Centralized Control</a></h3>
                                        <div class="text">This feature provides a centralized dashboard where you can oversee and manage all your service locations. Monitor each branch's activities, schedules, and performance from one convenient location.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Efficient Resource Allocation</a></h3>
                                        <div class="text">Multi-Location Management allows you to allocate resources intelligently. Assign technicians, equipment, and materials to different locations based on demand, optimizing efficiency.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Consistent Service Standards</a></h3>
                                        <div class="text">Ensure uniformity in service quality and standards across all locations. Implement and enforce standardized processes, checklists, and service protocols easily with this feature.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Real-Time Insights</a></h3>
                                        <div class="text">Gain real-time insights into the performance of each location. Monitor key metrics, track customer feedback, and identify areas for improvement, enabling data-driven decision-making.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Multi-Location Reporting</a></h3>
                                        <div class="text">Generate consolidated reports or view reports by location. This feature facilitates performance analysis and helps you make informed decisions at both the individual branch and company-wide levels.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <figure class="image-box wow fadeInRight" style="width: 455px;margin-top: 58px;" data-wow-delay="300ms" data-wow-duration="1500ms"><img src="/images/schedeasy/features/images/location.png" alt=""></figure>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="bottom-content">
                    <div class="text">
                        <p>Multi-Location Management is the solution for businesses with expansion ambitions. It enables you to scale your operations while maintaining control and consistency across all service locations, ensuring your brand's reputation and quality remain intact.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.new-image{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
