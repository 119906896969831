export const firstname = [
    'Alice',
    'Bob',
    'Charlie',
    'David',
    'Eva',
    'Frank',
    'Grace',
    'Henry',
    'Ivy',
    'Jack',
    'Kate',
    'Liam',
    'Mia',
    'Noah',
    'Olivia',
    'Penny',
    'Quinn',
    'Riley',
    'Samuel',
    'Taylor',
  ];
  
  export const lastname = [
    'Anderson',
    'Baker',
    'Carter',
    'Davis',
    'Evans',
    'Fisher',
    'Garcia',
    'Harrison',
    'Irwin',
    'Johnson',
    'Kane',
    'Lewis',
    'Morgan',
    'Nelson',
    'Owens',
    'Parker',
    'Quinn',
    'Reyes',
    'Smith',
    'Thompson',
  ];
  
  export const company = [
    'Sunset Innovations',
    'Quantum Dynamics',
    'Nebula Enterprises',
    'Astral Solutions',
    'Galactic Ventures',
    'TechNova Corp',
    'Binary Nexus',
    'Cosmic Innovations',
    'Stellar Systems',
    'Orbit Innovators',
    'QuantumSpark Technologies',
    'Celestial Technologies',
    'AstroBit Solutions',
    'Infinity Innovations',
    'Nebula Nexus',
    'Starlight Technologies',
    'Lunar Labs',
    'Nova Networks',
    'CosmoCraft Solutions',
    'Aurora Dynamics',
  ];
  
  export const contactnumber = [
    '416-123-4567',
    '647-234-5678',
    '905-345-6789',
    '519-456-7890',
    '613-567-8901',
    '705-678-9012',
    '807-789-0123',
    '226-890-1234',
    '289-901-2345',
    '343-012-3456',
    '416-123-5678',
    '647-234-6789',
    '905-345-7890',
    '519-456-9012',
    '613-567-0123',
    '705-678-1234',
    '807-789-2345',
    '226-890-3456',
    '289-901-4567',
    '343-012-5678',
  ];

  export const serviceLocation1 ={
    form: [
        {
            id: 0,
            form_id: 1,
            label: "Description",
            placeholder: "Description",
            name: "Text Area",
            is_disabled: 0,
            is_hidden: 0,
            is_required: 0,
            is_searchable: 0,
            is_on_invoice: 0,
            is_editable: 0,
            options: [
                {
                    name: "Change Option",
                    id: 0
                }
            ],
            value: "",
            selected: [],
            position: 0,
            groupings: [],
            has_remove: false
        },
        {
            id: 1,
            label: "Dummy",
            placeholder: "Change placeholder",
            name: "Text Field",
            is_disabled: false,
            is_hidden: false,
            is_required: false,
            is_searchable: false,
            is_on_invoice: false,
            is_editable: false,
            has_remove: true,
            options: [
                {
                    name: "Change option",
                    id: 0
                }
            ],
            groupings: [],
            value: null,
            selected: null,
            position: 1
        }
    ],
    name: "service_location_info",
}

export const statsAndTypes = {
    type_of_jobs: [
        { name: "Standard", id: 0, show_hidden_toggle: false },
        { name: "Premium", id: 1, show_hidden_toggle: false },
        { name: "Deluxe", id: 2, show_hidden_toggle: false }
    ],
    job_statuses: [
        { name: "Pending", color: "#ffed4a", id: 0, hasDelete: false },
        { name: "In Progress", color: "#ffa45d", id: 1, hasDelete: false },
        { name: "Completed", color: "#38c172", id: 2, hasDelete: false }
    ],
    activity_type: [
        { name: "Call", id: 0 },
        { name: "Follow Up", id: 1 }
    ],
    activity_statuses: [
        { name: "New", color: "#ffed4a", id: 0, hasDelete: false },
        { name: "In Progress", color: "#ffa45d", id: 1, hasDelete: false },
        { name: "Completed", color: "#38c172", id: 2, hasDelete: false }
    ],
    lead_type: [
        { name: "Hot Leads", color: "#ffed4a", id: 0, hasDelete: false },
        { name: "Working Leads", color: "#ffa45d", id: 1, hasDelete: false },
        { name: "Qualified Leads", color: "#38c172", id: 2, hasDelete: false }
    ],
    lead_statuses: [
        { name: "New", color: "#ffed4a", id: 0, hasDelete: false },
        { name: "Contacted", color: "#ffa45d", id: 1, hasDelete: false },
        { name: "Won", color: "#38c172", id: 2, hasDelete: false },
        { name: "Lost", color: "#00aa70", id: 3, hasDelete: false }
    ],
}

export const standard = {
    form: [
        {
            id: 1,
            form_id: 7,
            label: "Change label",
            placeholder: "Change placeholder",
            name: "Text Field",
            is_disabled: 0,
            is_required: 0,
            is_searchable: 0,
            is_on_invoice: 0,
            is_editable: 0,
            is_hidden: 0,
            options: [
                {
                    name: "Change option",
                    id: 0
                }
            ],
            value: null,
            selected: null,
            groupings: [],
            has_remove: false,
            position: 0
        },
        {
            id: 2,
            form_id: 8,
            label: "Change label",
            placeholder: "Change placeholder",
            name: "Text Field",
            is_disabled: 0,
            is_required: 0,
            is_searchable: 0,
            is_on_invoice: 0,
            is_editable: 0,
            is_hidden: 0,
            options: [
                {
                    name: "Change option",
                    id: 0
                }
            ],
            value: null,
            selected: null,
            groupings: [],
            has_remove: false,
            position: 1
        },
        {
            id: 3,
            form_id: 9,
            label: "Change label",
            placeholder: "Change placeholder",
            name: "Text Field",
            is_disabled: 0,
            is_required: 0,
            is_searchable: 0,
            is_on_invoice: 0,
            is_editable: 0,
            is_hidden: 0,
            options: [
                {
                    name: "Change option",
                    id: 0
                }
            ],
            value: null,
            selected: null,
            groupings: [],
            has_remove: false,
            position: 2
        }
    ],
    name: "standard",
}

export const premium = {
    form: [
        {
            id: 1,
            form_id: 7,
            label: "Change label",
            placeholder: "Change placeholder",
            name: "Text Field",
            is_disabled: 0,
            is_required: 0,
            is_searchable: 0,
            is_on_invoice: 0,
            is_editable: 0,
            is_hidden: 0,
            options: [
                {
                    name: "Change option",
                    id: 0
                }
            ],
            value: null,
            selected: null,
            groupings: [],
            has_remove: false,
            position: 0
        },
        {
            id: 2,
            form_id: 8,
            label: "Change label",
            placeholder: "Change placeholder",
            name: "Text Field",
            is_disabled: 0,
            is_required: 0,
            is_searchable: 0,
            is_on_invoice: 0,
            is_editable: 0,
            is_hidden: 0,
            options: [
                {
                    name: "Change option",
                    id: 0
                }
            ],
            value: null,
            selected: null,
            groupings: [],
            has_remove: false,
            position: 1
        },
    ],
    name: "premium",
}

