<template>
    <div style="margin-top: 20px;">
        <section class="reasons-section">
            <div class="container">
                <div class="sec-title center">
                <h2>Why Choose Schedeasy's All-Inclusive Pricing:</h2>
                </div>
                <div class="inner-content">
                    <div class="row " style="justify-content: center;">
                        <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                            <div class="single-item wow flipInY animated" data-wow-delay="00ms" data-wow-duration="1500ms" style="visibility: visible; animation-duration: 1500ms; animation-delay: 0ms; animation-name: flipInY;">
                                <div class="inner-box">
                                    <div class="icon-box"><i class=" flaticon-credit-card"></i></div>
                                    <h3>Every Feature Included</h3>
                                    <div class="text">Our pricing is truly all-inclusive, ensuring you have access to every feature, from advanced scheduling to efficient communication tools.</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                            <div class="single-item wow flipInY animated" data-wow-delay="00ms" data-wow-duration="1500ms" style="visibility: visible; animation-duration: 1500ms; animation-delay: 0ms; animation-name: flipInY;">
                                <div class="inner-box">
                                    <div class="icon-box"><i class=" flaticon-diagram"></i></div>
                                    <h3>Unlimited Users</h3>
                                    <div class="text"> With Schedeasy, you're not limited by the number of users. Whether it's admin staff or field staff, you can empower your entire team without worrying about additional costs</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                            <div class="single-item wow flipInY animated" data-wow-delay="00ms" data-wow-duration="1500ms" style="visibility: visible; animation-duration: 1500ms; animation-delay: 0ms; animation-name: flipInY;">
                                <div class="inner-box">
                                    <div class="icon-box"><i class=" flaticon-payment"></i></div>
                                    <h3>Budget-Friendly and Predictable</h3>
                                    <div class="text"> Say goodbye to unwelcome surprises in your budget. Our pricing remains consistent, regardless of your team's size or feature usage. It's designed to be scalable as your business grows</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                            <div class="single-item wow flipInY animated" data-wow-delay="00ms" data-wow-duration="1500ms" style="visibility: visible; animation-duration: 1500ms; animation-delay: 0ms; animation-name: flipInY;">
                                <div class="inner-box">
                                    <div class="icon-box"><i class=" flaticon-payment"></i></div>
                                    <h3>Commitment Free System Trial</h3>
                                    <div class="text">We invite you to experience the power of Schedeasy for yourself. Take advantage of our 7-day free trial with no credit card required and discover how all-inclusive pricing can enhance your field service management</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>