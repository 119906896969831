<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Command Center at Your Fingertips</h1>
                    </div>
                    <div class="text">
                        <h2>Visualize Your Field Service Operations</h2>
                        <p>The "Dashboard" feature of our software serves as your command center, offering a comprehensive and visual overview of your field service operations in real time.</p>
                    </div>
                    <div data-v-1e9dea97="" style="width: 100%;"><video data-v-1e9dea97="" autoplay :src="filteredFeatures[0].video_path" controls="controls" style="width: 100%;"></video></div>

                </div>
                <section class="recruitment-section elements sec-pad-two centred">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">At-a-Glance Insights</a></h3>
                                        <div class="text">The Dashboard provides you with at-a-glance insights into critical performance metrics, including job progress, team productivity, and financial data. It consolidates complex data into clear, visual representations.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Customizable Widgets</a></h3>
                                        <div class="text">Tailor your Dashboard to meet your specific needs. You can add, remove, and rearrange widgets to display the information most relevant to your business, ensuring that you have a personalized view.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Real-Time Updates</a></h3>
                                        <div class="text">Enjoy real-time updates on job statuses, team locations, and client interactions. The Dashboard keeps you informed so you can make decisions quickly and effectively.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Performance Tracking</a></h3>
                                        <div class="text">Monitor the performance of your field service team effortlessly. Track key performance indicators, such as job completion rates, response times, and customer feedback, enabling data-driven decision-making.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Data Visualization</a></h3>
                                        <div class="text">Visualize data through charts, graphs, and tables. This feature simplifies complex data sets, making it easier to identify trends, opportunities, and areas for improvement.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <figure class="image-box wow fadeInRight" style="width: 455px;margin-top: 58px;" data-wow-delay="300ms" data-wow-duration="1500ms"><img src="/images/schedeasy/features/images/dashboard.png" alt=""></figure>

                            </div>
                        </div>
                    </div>
                </section>
                <div class="bottom-content">
                    <div class="text">
                        <p>The Dashboard feature is your window into the heart of your field service business. It empowers you with real-time insights, enhances decision-making, and keeps you in control of your operations.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.new-image{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
