<template>
    <div>
        <div class="service-details-content">
            <div class="inner-box">
                <div class="top-content">
                    <div class="sec-title">
                        <h1>Precision Workforce Management with Time and Attendance Tracking</h1>
                    </div>
                    <div class="text">
                        <h2>Simplify Time Recording and Ensure Compliance</h2>
                        <p>Efficiently monitoring the time and attendance of your field service team is crucial for accurate payroll, compliance, and overall workforce management. Our software's "Time and Attendance Tracking" feature simplifies this process, ensuring precision and accountability.</p>
                    </div>
                    <div data-v-1e9dea97="" style="width: 100%;"><video data-v-1e9dea97="" autoplay :src="filteredFeatures[0].video_path" controls="controls" style="width: 100%;"></video></div>
                </div>
                <section class="recruitment-section elements sec-pad-two centred">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Centralized Time Records</a></h3>
                                        <div class="text">The Time and Attendance Tracking feature acts as a centralized repository for all time-related data. Store employee clock-ins, clock-outs, breaks, and overtime records in one location for easy access.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Automated Time Capture</a></h3>
                                        <div class="text">Streamline time capture with automated tools. Employees can use digital time clocks, mobile apps, or web-based interfaces to record their working hours, reducing manual data entry and potential errors.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Compliance Monitoring</a></h3>
                                        <div class="text">Ensure compliance with labor laws and company policies. The feature can automatically calculate overtime, track paid time off (PTO), and enforce break and shift duration requirements, helping you avoid compliance issues.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Attendance Reporting</a></h3>
                                        <div class="text">Generate comprehensive attendance reports. Access data on employee attendance patterns, tardiness, and absenteeism to make informed decisions regarding workforce management.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><i class="flaticon-partner"></i></div>
                                        <h3><a href="#">Integration with Payroll</a></h3>
                                        <div class="text">Seamlessly integrate time and attendance data with your payroll system. This integration ensures accurate payroll processing and minimizes discrepancies.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="bottom-content">
                    <div class="text">
                        <p>The Time and Attendance Tracking feature is your essential tool for precise workforce management. It simplifies time recording, enhances compliance, and provides the data needed to optimize scheduling and payroll processes.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.new-image{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
