<template>
        <section class="testimonial-style-ten">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-xl-6 col-lg-12 col-md-12 user-column">
                    <div class="user-thumb">
                        <div class="thumb-box">
                            <div class="pattern-bg1" style="background-image: url(/images/icons/thumb-bg.png);"></div>
                            <div class="pattern-bg2" style="background-image: url(/images/icons/map-shap-3.png);"></div>
                            <figure class="thumb thumb-1"><img src="/images/schedeasy/industry/appliance.png" alt=""></figure>
                            <figure class="thumb thumb-2"><img src="/images/schedeasy/industry/chimney.png" alt=""></figure>
                            <figure class="thumb thumb-3"><img src="/images/schedeasy/industry/cleaner.png" alt=""></figure>
                            <figure class="thumb thumb-4"><img src="/images/schedeasy/industry/commercial.png" alt=""></figure>
                            <figure class="thumb thumb-5"><img src="/images/schedeasy/industry/drywall.png" alt=""></figure>
                            <figure class="thumb thumb-6"><img src="/images/schedeasy/industry/garage.png" alt=""></figure>
                            <figure class="thumb thumb-7"><img src="/images/schedeasy/industry/lawncare.png" alt=""></figure>
                            <figure class="thumb thumb-8"><img src="/images/schedeasy/industry/residential.png" alt=""></figure>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12 content-column">
                    <div class="content-box">
                        <div class="sec-title"><h2>How Clients React</h2></div>
                        <div class="testimonial-carousel-2 owl-carousel owl-theme">
                            <div class="testimonial-content" v-for="(item, index) in testimonials" :key="index">
                                <div class="inner-box">
                                    <div class="text">{{ item.message }}</div>
                                    <div class="author-info">
                                        <ul class="rating clearfix">
                                            <li><i class="fas fa-star"></i></li>
                                            <li><i class="fas fa-star"></i></li>
                                            <li><i class="fas fa-star"></i></li>
                                            <li><i class="fas fa-star"></i></li>
                                            <li><i class="fas fa-star"></i></li>
                                        </ul>
                                        <h4 class="name">{{ item.name }}</h4>
                                        <span class="designation">{{ item.rank }}</span>
                                    </div>
                                </div>
                            </div>
                         
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        data(){
            return{
                testimonials:[
                    {
                        name:"Sarah Mitchell",
                        rank:"CEO, Apex Plumbing Solutions",
                        message:"Schedeasy has transformed the way we manage our plumbing services. It's a game-changer. The all-inclusive pricing allows us to scale our team without worrying about budget constraints."
                    },
                    {
                        name:"Michael Johnson",
                        rank:"Owner, GreenScape Landscaping",
                        message:"As a landscaping business owner, I need a system that covers everything. Schedeasy delivers exactly that. Plus, their customer support is exceptional - always there when I need them."
                    },
                    {
                        name:"Emily Martinez",
                        rank:"Operations Manager, CleanTech Janitorial Services",
                        message:"Schedeasy has simplified our janitorial operations in ways we never imagined. The all-inclusive pricing means no hidden costs, and the features are tailor-made for our industry. It's a must-have for janitorial businesses."
                    },

                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
.testimonial-style-ten .user-thumb .thumb-box .thumb {
    position: absolute;
    width: 90px;
    
}
.testimonial-style-ten .user-thumb .thumb-box .thumb-2{
    width: 80px;
}


.testimonial-style-ten .user-thumb .thumb-box .thumb-1{
    width: 150px;
}

.testimonial-style-ten .user-thumb .thumb-box .thumb-4{
    width: 50px;
}

.testimonial-style-ten .user-thumb .thumb-box .thumb-5{
    width: 50px;
}

.testimonial-style-ten .user-thumb .thumb-box .thumb-7{
    width: 80px;
}
</style>